import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: LocaleMessages = {};
  const elliMessages: {
    locale: string;
    env: string;
    messages: LocaleMessages;
  }[] = [];
  locales.keys().forEach((key) => {
    const data = key.replace('/', '').split('.');
    let available: string[] = [];

    if (process.env.VUE_APP_I18N_LOCALE_AVAILABLE) {
      available = process.env.VUE_APP_I18N_LOCALE_AVAILABLE.split('|');
    }

    if (data && data.length === 3) {
      const locale = data[1];
      if (available && available.indexOf(locale) !== -1) {
        messages[locale] = locales(key);
      }
    }

    if (data && data.length === 5) {
      const locale = data[1];
      const env = data[3];
      if (available && available.indexOf(locale) !== -1) {
        if (
          process.env.VUE_APP_ENVIRONMENT?.includes('elli-thg') &&
          (env === process.env.VUE_APP_ENVIRONMENT || env === 'elli')
        ) {
          elliMessages.push({
            locale: locale,
            env: env,
            messages: locales(key)
          });
        } else {
          if (env === process.env.VUE_APP_ENVIRONMENT) {
            messages[locale] = { ...messages[locale], ...locales(key) };
          }
        }
      }
    }
  });
  if (elliMessages.length > 0) {
    elliMessages.sort(function (a, b) {
      if (b.env === 'elli') return 1;
      if (a.env === 'elli') return -1;
      return 0;
    });
    elliMessages.forEach((value) => {
      messages[value.locale] = {
        ...messages[value.locale],
        ...value.messages
      };
    });
  }
  return messages;
}

function loadLocale() {
  const locale = localStorage.getItem('wd-portal-language');
  if (locale) {
    return locale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE ? process.env.VUE_APP_I18N_LOCALE : 'de';
  }
}

export default new VueI18n({
  locale: loadLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE ? process.env.VUE_APP_I18N_FALLBACK_LOCALE : 'us',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
  silentTranslationWarn: true
});
