import ProductData from '@/interfaces/ProductData';
import moment from 'moment';
import ThgServiceElliDefault from './thg/ThgServiceElliDefault';
import VehicleType from './VehicleType';

export default class NonCommodityProductFacade implements ProductData {
  public addonsInReleasedVersion: number = 0;
  public availability: string = '';
  public bonusSavings: number = 0;
  public business: string = '';
  public cancellationPeriodDays: number = 0;
  public cancellationPeriodDaysType: string = '';
  public deposit: { brutto: number } = { brutto: 0 };
  public energy: string = '';
  public extendedData: Record<any, any> | null = null;
  public fixedPriceMonths: number = 0;
  public fixedPriceMonthsType: string = '';
  public highPrioCampaignIdentifier: string = '';
  public highestVersion: string = '';
  public highestVersionName: string = '';
  public hasAddOns: string = '';
  public initialDuration: number = 0;
  public initialDurationType: string = '';
  public kind: string = '';
  public name: string = '';
  public productCode: string = '';
  public productId: number = 0;
  public releasedAt: string = '';
  public releasedName: string = '';
  public releasedVersion: number = 0;
  public renewalDuration: number = 0;
  public renewalDurationType: string = '';
  public tariffEco: string = '';
  public type: string = '';
  public updatedAt: string = '';
  public basePrice?: number | undefined;
  public basePriceBrutto?: number | undefined;
  public elliNonCommodityProcurementPrices?: [{ classification: string; net: number; brutto: number }] | undefined;
  public cmsDescriptionExternal: string = '';
  public cmsDescriptionInternal: string = '';

  public static withData(data: Record<any, any>): NonCommodityProductFacade {
    return Object.assign(new NonCommodityProductFacade(), data);
  }

  public static isProductAvailableForBusiness(productCode: string): boolean {
    const code = productCode.toUpperCase();
    return code.includes('THG_') && code.includes('_PRAEMIE');
  }

  public getExtendedData(category: string, technicalKey: string): any {
    const extendedDataCategory = this.extendedData?.values?.[category];
    return extendedDataCategory.find((value) => value.technicalKey === technicalKey);
  }

  public getBasePriceBrutto(
    selectedVehicleTypeIdentifier: string,
    filterYears?: string[] | undefined
  ): number | undefined {
    if (!filterYears) {
      return this.basePriceBrutto;
    }
    const prices = NonCommodityProductFacade.getVehicleTypePrices(this, selectedVehicleTypeIdentifier, filterYears);
    return prices
      ? prices.reduce((accumulator, object) => {
          return accumulator + object.brutto;
        }, 0)
      : 0;
  }

  public getBasePrice(selectedVehicleTypeIdentifier: string, filterYears?: string[] | undefined): number | undefined {
    if (!filterYears) {
      return this.basePrice;
    }
    const prices = NonCommodityProductFacade.getVehicleTypePrices(this, selectedVehicleTypeIdentifier, filterYears);
    return prices
      ? prices.reduce((accumulator, object) => {
          return accumulator + object.net;
        }, 0)
      : 0;
  }

  public getBasePriceBruttoForYear(selectedVehicleTypeIdentifier: string, year: number | string): number {
    const prices = this.getPricesForYear(selectedVehicleTypeIdentifier, year);

    if (prices && prices.length == 1) {
      return prices[0].brutto;
    } else if (prices && prices.length > 1) {
      let price = 0;
      for (let i = 0; i < prices.length; ++i) {
        price += prices[i].brutto;
      }
      return price;
    }

    return 0;
  }

  public getBasePriceForYear(selectedVehicleTypeIdentifier: string, year: number | string): number {
    const prices = this.getPricesForYear(selectedVehicleTypeIdentifier, year);

    if (prices && prices.length == 1) {
      return prices[0].net;
    } else if (prices && prices.length > 1) {
      let price = 0;
      for (let i = 0; i < prices.length; ++i) {
        price += prices[i].net;
      }
      return price;
    }

    return 0;
  }

  public getPricesForYear(
    selectedVehicleTypeIdentifier: string,
    year: number | string
  ): ArrElement<ProductData['elliNonCommodityProcurementPrices']>[] | undefined {
    if (typeof year != 'string') {
      year = year + '';
    }
    const prices = NonCommodityProductFacade.getVehicleTypePrices(this, selectedVehicleTypeIdentifier, [year]);
    return prices ?? [];
  }

  public get productName(): string {
    return this.name;
  }

  public get isMostPicked(): boolean {
    return this.isEcoProduct;
  }

  public get isEcoProduct(): boolean {
    return this?.productCode.toLowerCase().includes('bonus') ?? false;
  }

  public get isVoucherProduct(): boolean {
    return this?.productCode.toLowerCase().includes('voucher') ?? false;
  }

  public get isBusinessProduct(): boolean {
    return this?.productCode.toLowerCase().includes('_b2b') ?? false;
  }

  public get productDescription(): string {
    return this.cmsDescriptionExternal;
  }

  public get hasProductDescription(): boolean {
    return this.cmsDescriptionExternal.length > 0;
  }

  public get productDescriptionShort(): string {
    return this.cmsDescriptionInternal.replace('CO2', 'CO<sub>2</sub>');
  }

  public get hasProductDescriptionShort(): boolean {
    return this.cmsDescriptionInternal.length > 0;
  }

  public get isReady(): boolean {
    return false;
  }

  public static getAvailableYears(products: ProductData[], isCustomerTypeBusiness: boolean): number[] {
    const years: number[] = [];
    const filteredProducts = this.filterBusinessProducts(products, isCustomerTypeBusiness);
    for (const product of filteredProducts) {
      if (product.elliNonCommodityProcurementPrices) {
        for (const procurementPrice of product.elliNonCommodityProcurementPrices) {
          const yearFrom = parseInt(moment(procurementPrice.dateFrom).format('YYYY'));
          const yearUntil = parseInt(moment(procurementPrice.dateUntil).format('YYYY'));

          if (years.indexOf(yearFrom) == -1) {
            years.push(yearFrom);
          }
          if (years.indexOf(yearUntil) == -1) {
            years.push(yearUntil);
          }

          for (let year = yearFrom + 1; year < yearUntil; ++year) {
            if (years.indexOf(year) == -1) {
              years.push(year);
            }
          }
        }
      }
    }
    return years.sort();
  }

  public static filterBusinessProducts(products: ProductData[], returnBusinessProducts: boolean) {
    return products.filter((product) =>
      returnBusinessProducts ? this.isProductBusiness(product) : !this.isProductBusiness(product)
    );
  }

  private static sumUpPricesForVehicleType(products: ProductData[], vehicleType: VehicleType, filterYears: string[]) {
    const productPrices = products.map((product) => ({
      productCode: product.productCode,
      prices: this.getVehicleTypePrices(product, vehicleType.identifier, filterYears)
    }));
    const accumulatedPrices: any = [];
    for (const productPrice of productPrices) {
      if (productPrice.prices) {
        accumulatedPrices.push({
          identifier: vehicleType.identifier,
          class: vehicleType.class,
          title: vehicleType.title,
          bruttoPrice: productPrice.prices.reduce((acc, curr) => acc + curr.brutto, 0),
          classification: productPrice.prices[0].classification,
          dateFrom: productPrice.prices[0].dateFrom,
          dateUntil: productPrice.prices[0].dateUntil,
          netPrice: productPrice.prices.reduce((acc, curr) => acc + curr.net, 0)
        });
      }
    }
    return accumulatedPrices.sort((a, b) => (a.netPrice < b.netPrice ? 1 : -1))[0];
  }

  private static getHighestPriceForVehicleType(
    products: ProductData[],
    vehicleType: VehicleType,
    year: string
  ): VehicleType {
    const prices = products.map((product) => this.getVehicleTypePrice(product, vehicleType.identifier, year));
    vehicleType.netPrice = 0;
    vehicleType.bruttoPrice = 0;
    if (prices.length > 0) {
      vehicleType.netPrice = Math.max(...prices.map((price) => price?.net ?? 0));
      vehicleType.bruttoPrice = Math.max(...prices.map((price) => price?.brutto ?? 0));
    }

    return vehicleType;
  }

  public static getEnabledVehicleTypes(
    products: ProductData[],
    isCustomerTypeBusiness: boolean,
    filterYears?: string[] | undefined
  ): VehicleType[] {
    const enabledVehicleTypes: any = [];
    const vehicleTypes: VehicleType[] = ThgServiceElliDefault.vehicleTypes;
    const filteredProducts: ProductData[] = this.filterBusinessProducts(products, isCustomerTypeBusiness);
    for (const vehicleTypeKey in vehicleTypes) {
      const vehicleType = vehicleTypes[vehicleTypeKey];
      const productsWithIdentifier: ProductData[] =
        this.getProductsForIdentifier(filteredProducts, vehicleType.identifier) ?? [];
      if (filterYears && filterYears.length > 1) {
        const price = this.sumUpPricesForVehicleType(productsWithIdentifier, vehicleType, filterYears);
        if (price) {
          enabledVehicleTypes.push(price);
        }
      } else {
        const year = filterYears ? filterYears[0] : new Date().setFullYear(new Date().getFullYear(), 0, 1).toString();
        enabledVehicleTypes.push(this.getHighestPriceForVehicleType(productsWithIdentifier, vehicleType, year));
      }
    }
    return enabledVehicleTypes
      .map((type) => (type.identifier === 'm1' ? { ...type, isHighlighted: true } : type))
      .filter((type) => type.netPrice || type.bruttoPrice);
  }

  public static getProductsForIdentifier(products: ProductData[] | undefined, identifier: string) {
    return products?.filter((product) =>
      product.elliNonCommodityProcurementPrices?.find((price) => price.classification === identifier)
    );
  }

  private static getVehicleTypePrice(
    product: ProductData | undefined,
    identifier: string,
    year: string
  ): ArrElement<ProductData['elliNonCommodityProcurementPrices']> | undefined {
    return product?.elliNonCommodityProcurementPrices?.find(
      (vehicleTypePrice) =>
        vehicleTypePrice.classification === identifier &&
        new Date(vehicleTypePrice.dateFrom ?? '').getFullYear() === new Date(year).getFullYear()
    );
  }

  private static getVehicleTypePrices(
    product: ProductData | undefined,
    vehicleClass: string,
    filterYears: string[],
    startInPast = false
  ): ArrElement<ProductData['elliNonCommodityProcurementPrices']>[] {
    if (product && product.elliNonCommodityProcurementPrices) {
      return product.elliNonCommodityProcurementPrices.filter(
        (price) =>
          price.classification === vehicleClass &&
          filterYears.some(
            (year) =>
              new Date(price.dateFrom ?? '').getFullYear() === new Date(year).getFullYear() ||
              (startInPast && new Date(price.dateUntil ?? '').getFullYear() > new Date(year).getFullYear())
          )
      );
    }
    return [];
  }

  private static isProductBusiness(product: ProductData): boolean {
    return product.productCode?.toUpperCase().includes('_B2B') ?? false;
  }

  public static getEnabledVehicleTypesForProduct(product: ProductData, filteredYears: any): VehicleType[] {
    return NonCommodityProductFacade.getEnabledVehicleTypes(
      [product],
      this.isProductBusiness(product),
      filteredYears.split(',')
    );
  }
}
