








































import { default as Component } from 'vue-class-component';
import AddonWidget from '@/components/widgets/AddonWidget.vue';

@Component({})
export default class PregoAddonWidget extends AddonWidget {
  public getLink(string: string): string {
    const regex = /(<([^>]+)>)/gi;
    return string.replace(regex, '');
  }

  public updateAddonsPrego(productCode: string, selected: boolean): void {
    this.updateAddons(productCode, selected);
    this.addonsSubmit();
  }
}
