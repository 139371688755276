









import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import { INotification } from '@/interfaces/Notification';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class SingleNotification extends Basic {
  @Prop() public notification: INotification = {};

  public mounted(): void {
    setTimeout(() => {
      this.store.dispatch('removeNotification', this.notification);
    }, 5000);
  }
}
