import { AxiosResponse } from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import { store } from '@/store';
import SessionState from '@/interfaces/SessionState';

declare global {
  interface Window {
    debuggerToken: string;
  }
}

@Component
export default class BaseMixin extends Vue {
  public store = store;

  get sessionState(): SessionState {
    return this.store.getters.getSession;
  }

  get currentClient(): string {
    return process.env.VUE_APP_ENVIRONMENT;
  }

  get isFleetFlow(): boolean {
    return this.store.getters.getIsFleetFlow;
  }
  public log(...args: any[]): void {
    const debuggerToken =
      process.env.VUE_APP_ENVIRONMENT_STAGE === 'test'
        ? localStorage.getItem('wd-debugger-token-test')
        : localStorage.getItem('wd-debugger-token');
    if (
      process.env.VUE_APP_ENVIRONMENT_STAGE === 'local' ||
      (debuggerToken === process.env.VUE_APP_DEBUGGER_TOKEN &&
        process.env.VUE_APP_DEBUGGER_TOKEN !== '' &&
        debuggerToken !== '')
    ) {
      console.log(...args);
    }
  }

  public $tdc(key: string, vars: any = {}) {
    // translate depending on commodity if not commodity set return normal key
    if (this.sessionState) {
      if (this.sessionState.commodity) {
        return this.$t(`${key}.${this.sessionState.commodity}`, { ...vars }) !== `${key}.${this.sessionState.commodity}`
          ? this.$t(`${key}.${this.sessionState.commodity}`, { ...vars })
          : this.$t(key, { ...vars });
      }
    }
    return this.$t(key, { ...vars });
  }

  protected getDurationType(type: string, amount: number | string): string {
    if (typeof amount === 'string') {
      amount = parseInt(amount);
    }
    if (type === 'MONTHS' && amount === 1) {
      return this.$t('month').toString();
    } else if (type === 'MONTHS' && (amount > 1 || amount === 0)) {
      return this.$t('months').toString();
    } else if (type === 'WEEKS' && amount === 1) {
      return this.$t('week').toString();
    } else if (type === 'WEEKS' && (amount > 1 || amount === 0)) {
      return this.$t('weeks').toString();
    } else if (type === 'DAYS' && amount === 1) {
      return this.$t('day').toString();
    } else if (type === 'DAYS' && (amount > 1 || amount === 0)) {
      return this.$t('days').toString();
    } else {
      return type;
    }
  }

  protected getSalutationForKey(key: string): string {
    switch (Number(key)) {
      case 1:
        return this.$t('contract.widget.salutation.mr').toLocaleString();
      case 2:
        return this.$t('contract.widget.salutation.mrs').toLocaleString();
      case 3:
        return this.$t('contract.widget.salutation.family').toLocaleString();
      case 8:
        return this.$t('contract.widget.salutation.company').toLocaleString();
      case 9:
      default:
        return '';
    }
  }

  protected getErrorMessage(response: AxiosResponse): string {
    const errorObject = response.data?.messageLocalized,
      errorMessageKeyFallbacks: string[] = [errorObject?.key, errorObject?.key?.split('.')[0]],
      errorMessageKey = errorMessageKeyFallbacks.find((key) => this.$te(key)) || 'review.widget.error';

    return this.$t(errorMessageKey) as string;
  }
}
