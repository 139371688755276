




























































import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ProductInfoWidget from './ProductInfoWidget.vue';
import Basic from '@/components/widgets/Basic.vue';
import QcellsProductInfoWidget from './qcells/QcellsProductInfoWidget.vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Api from '../../interfaces/Api';
const ApiProvider = IocContainer.get<Api>(SERVICES.API);
import Format from '@/interfaces/Format';
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
import AddOn from '@/interfaces/AddOn';
import AddOnData from '@/interfaces/AddOnData';
const addOnProvider = IocContainer.get<AddOn>(SERVICES.ADDON);

@Component({
  components: {
    'product-info': ProductInfoWidget,
    'qcells-product-info': QcellsProductInfoWidget
  },
  filters: {
    euro: formatProvider.euro()
  },
  props: {
    availableAddons: Array,
    product: Object
  }
})
export default class AddonWidget extends Basic {
  public data: {
    selectedAddons: string[];
  } = {
    selectedAddons: []
  };

  @Watch('availableAddons')
  public availableAddonsLoaded(): void {
    this.setSelected();
  }

  get getAvailableAddons(): AddOnData[] {
    return this.$props.availableAddons;
  }

  get addons(): AddOnData[] {
    return this.$props.product.addOns;
  }

  public mounted(): void {
    this.setSelected();
  }

  public setSelected(): void {
    const that = this;
    if (this.sessionState.optionalAddOns) {
      this.data.selectedAddons = JSON.parse(this.sessionState.optionalAddOns).filter((addon) => {
        return that.getAvailableAddons.map((a) => a.productCode).indexOf(addon) > -1;
      });
    }
  }

  public addonsSubmit(): void {
    const data = {
      optionalAddOns: JSON.stringify(this.data.selectedAddons)
    };
    ApiProvider.sessionUpdate(data)
      .then(() => {
        this.store.commit('SET_SESSION', data);
        this.$emit('addonSubmit', data);
        this.sessionState.optionalAddOns = JSON.stringify(this.data.selectedAddons);
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  public parseImage(image: string | null): string {
    return addOnProvider.parseImage(image);
  }

  public updateAddons(productCode: string, selected: boolean): void {
    if (selected) {
      this.data.selectedAddons.push(productCode);
    } else {
      const index = this.data.selectedAddons.indexOf(productCode);
      if (index > -1) {
        this.data.selectedAddons.splice(index, 1);
      }
    }
  }
}
