
import 'core-js/es/object';
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { store } from '@/store';
import BaseMixin from '@/mixins/base';
import setUp from './setUp';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Api from '@/interfaces/Api';
import Tracking from '@/interfaces/Tracking';
import SessionState from '@/interfaces/SessionState';
import ProductData from '@/interfaces/ProductData';
import AddOnData from '@/interfaces/AddOnData';
import IdleVue from 'idle-vue';
import { IAlertSettings, INotificationSettings, NotificationActions } from '@/interfaces/Notification';
import WidgetsList from '@/interfaces/WidgetsList';

setUp(Vue);

@Component({
  store
})
export default class Prototype extends mixins(Vue, BaseMixin) {
  protected ApiProvider = IocContainer.get<Api>(SERVICES.API);
  protected trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
  public $refs!: {
    wdSalesJourney: HTMLElement;
  };

  public currentWidget: string = 'product';

  public store = store;

  public data: {
    availableProducts: any[];
    availableAddons: any[];
    loadingProducts: boolean;
    isSessionLoaded: boolean;
    selectedProduct: any;
    availableProviders: any[];
    sessionState: any;
    discountEnabled: boolean;
    defaultAddons: string[];
    recommendingContractIdActive: boolean;
    minimumAgeRequired: number;
    recommendedProducts: any[];
    redirect: string;
    hideIbanInFrontend: boolean;
    enableEnetValidationForInvoiceAddress: boolean;
    requireCounterNumberValidation: boolean;
    sessionParamDefault: string;
    sessionParam: string;
    sessionParamOld: string;
  } = {
    availableProducts: [],
    availableAddons: [],
    loadingProducts: true,
    isSessionLoaded: false,
    selectedProduct: {},
    availableProviders: [],
    sessionState: {},
    discountEnabled: false,
    defaultAddons: [],
    recommendingContractIdActive: false,
    minimumAgeRequired: 0,
    recommendedProducts: [],
    redirect: '',
    hideIbanInFrontend: false,
    enableEnetValidationForInvoiceAddress: false,
    requireCounterNumberValidation: false,
    sessionParamDefault: 'session',
    sessionParam: 'session',
    sessionParamOld: ''
  };

  public widgetsList: WidgetsList = {
    product: true,
    addon: false,
    contract: false,
    delivery: false,
    payment: false,
    rules: false,
    review: false,
    disabled: {
      product: false,
      addon: true,
      contract: false,
      delivery: true,
      payment: true,
      rules: true,
      review: true
    }
  };

  public stepsOrder: any[] | null = null;

  public stepperEnabled = true;
  public enableFastNavigation = true;
  private spinnerActive = true;

  public created(): void {
    this.setUpSession().then(() => {
      this.loadCustomCss();
      this.loadApp();
    });
  }

  public async setUpSession(): Promise<void> {
    throw new Error("Method 'setUpSession' is not implemented!");
  }

  public loadCustomCss(): void {
    if (this.$props.customcss && this.$props.customcss.length > 0) {
      const customCss = document.createElement('link');
      customCss.setAttribute('rel', 'stylesheet');
      customCss.setAttribute('href', this.$props.customcss);
      customCss.setAttribute('id', 'wd-sales-journey-custom-css');
      const shadow = document.getElementsByTagName('wd-sales-journey')[0].shadowRoot;
      if (shadow) {
        if (!shadow.getElementById('wd-sales-journey-custom-css')) {
          shadow.appendChild(customCss);
        }
      }
    } else if (process.env.VUE_APP_CUSTOM_CSS && process.env.VUE_APP_CUSTOM_CSS.length > 0) {
      const customCss = document.createElement('link');
      customCss.setAttribute('rel', 'stylesheet');
      customCss.setAttribute('href', process.env.VUE_APP_CUSTOM_CSS);
      customCss.setAttribute('id', 'wd-sales-journey-custom-css');
      const shadow =
        document.getElementsByTagName('wd-sales-journey').length > 0
          ? document.getElementsByTagName('wd-sales-journey')[0].shadowRoot
          : false;
      if (shadow) {
        if (!shadow.getElementById('wd-sales-journey-custom-css')) {
          shadow.appendChild(customCss);
        }
      } else {
        document.head.appendChild(customCss);
      }
    }
  }

  public async readSession(): Promise<boolean> {
    const url_string = window.location.toString();
    const url = new URL(url_string);
    const param = this.$props.sessionParam || this.data.sessionParamDefault;
    const session = url.searchParams.get(param) || '';
    return await this.loadAndValidateSession(session);
  }

  public async loadAndValidateSession(session: string): Promise<boolean> {
    if (!session) {
      return false;
    }
    return await this.ApiProvider.sessionRead(session)
      .then((response) => {
        if (response.data.sessionHash) {
          localStorage.setItem(process.env.VUE_APP_SESSION_STORAGE_KEY, session);
        } else {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
        }
        return true;
      })
      .catch((error) => {
        this.log('invalid session');
        this.log(error);
        localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
        return false;
      });
  }

  public activateSpinnerActive(): void {
    this.spinnerActive = true;
  }

  public deactivateSpinnerActive(): void {
    this.spinnerActive = false;
  }

  public loadApp(): void {
    throw new Error("Method 'loadApp' is not implemented!");
  }

  public loadProducts(): void {
    throw new Error("Method 'loadProducts' is not implemented!");
  }

  public loadSettings(): void {
    this.ApiProvider.getSettings()
      .then((response) => {
        this.data.discountEnabled = parseInt(response.data.discountEnabled, 10) === 1;
        this.store.commit(
          'SET_DEFAULT_ADDONS',
          response.data.defaultAddons
            .trim()
            .split(',')
            .map((addon) => {
              return addon.trim();
            })
        );
        this.store.commit('SET_SETTINGS', response.data);
        this.data.minimumAgeRequired = parseInt(response.data.minimumAgeRequired, 10) || 0;
        this.data.recommendingContractIdActive = parseInt(response.data.recommendingContractIdActive, 10) === 1;
        this.data.recommendedProducts = response.data.recommendedProducts;
        this.data.hideIbanInFrontend = parseInt(response.data.hideIbanInFrontend, 10) === 1;
        this.data.enableEnetValidationForInvoiceAddress = response.data.enableEnetValidationForInvoiceAddress === '1';
        this.data.requireCounterNumberValidation = response.data.requireCounterNumberValidation === '1';
        this.setUpAlertAndNotificationFeature();
        const inputs = document.querySelectorAll('input');
        const selects = document.querySelectorAll('select');
        const textareas = document.querySelectorAll('textarea');
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        const radios = document.querySelectorAll('input[type="radio"]');
        const allFormFields = [...inputs, ...selects, ...textareas, ...checkboxes, ...radios];
        allFormFields.forEach((field) => {
          field.addEventListener('keyup', () => {
            // could also use keyup, keydown, etc.
            this.store.dispatch('setSessionIsSaved', false);
          });
        });
        if (response.data.enableFlexbonus === '1') {
          this.ApiProvider.getFlexBonus()
            .then((response) => {
              if (response.data.flexbonus !== null) {
                this.store.commit('SET_FLEXBONUS', response.data.flexbonus.flexbonus);
              }
            })
            .catch((error) => {
              console.error(error.response.status, error.response.data.message);
            });
        }
      })
      .catch((error) => {
        console.error(error.response.status, error.response.data.message);
      });
  }

  public setUpAlertAndNotificationFeature(): void {
    const alertSettings = this.alertSettings;
    if (alertSettings.timeoutAlertJourneyActive) {
      if (alertSettings.timeoutAlertJourneyOnIdle) {
        if (alertSettings.timeoutAlertJourneyDurationSeconds) {
          Vue.use(IdleVue, { idleTime: 1000 * alertSettings.timeoutAlertJourneyDurationSeconds, store });
        } else {
          Vue.use(IdleVue, { idleTime: 1000 * 300, store });
        }
      }
      if (alertSettings.timeoutAlertJourneyOnLeave) {
        window.addEventListener('beforeunload', (event) => {
          if (this.sessionIsSaved) {
            return;
          } else {
            this.store.dispatch('setNotificationAction', NotificationActions.LEAVE);

            event.preventDefault();
            // messages are ignored by most browsers
            event.returnValue = "You're about to leave this page. Are you sure?";
            setTimeout(() => {
              this.store.dispatch('setCustomAppIdleState', true);
            }, 1500);
            return "You're about to leave this page. Are you sure?";
          }
        });
        window.addEventListener('unload', () => {
          this.store.dispatch('setCustomAppIdleState', false);
        });
      }
    }
  }

  get sessionIsSaved(): boolean {
    return this.store.getters.getSessionIsSaved;
  }
  get alertSettings(): IAlertSettings {
    return this.store.state.settings.alertSettings;
  }

  get notificationSettings(): INotificationSettings {
    return this.store.state.settings.notificationSettings;
  }

  get availableProducts(): [] {
    return this.store.getters.getAvailableProducts;
  }

  get availableProviders(): [] {
    return this.store.getters.getAvailableProviders;
  }

  get loadingProducts(): boolean {
    return this.data.loadingProducts;
  }

  get isSessionLoaded(): boolean {
    return this.data.isSessionLoaded;
  }

  get sessionState(): SessionState {
    return this.store.getters.getSession;
  }

  get defaultAddons(): string[] {
    return this.store.getters.getDefaultAddons;
  }

  get selectedProduct(): ProductData {
    return this.store.getters.getSelectedProduct;
  }

  get availableAddons(): AddOnData[] {
    return this.store.getters.getAvailableAddons;
  }

  protected isWidgetDisabled(widgetName: string): boolean {
    return this.widgetsList.disabled[widgetName];
  }

  public switchWidget(widgetName: string): void {
    if (!this.isWidgetDisabled(widgetName)) {
      this.hideWidgets();
      const reviewWidget = localStorage.getItem('review-widget-edit-info');
      if (typeof reviewWidget !== 'undefined' && reviewWidget !== null && reviewWidget !== widgetName) {
        this.showWidget('review');
        localStorage.removeItem('review-widget-edit-info');
        this.trackingProvider.widgetStepChange('Widget review');
      } else {
        this.showWidget(widgetName);
        this.trackingProvider.widgetStepChange('Widget ' + widgetName);
      }
    }
  }

  protected hideWidgets(): void {
    for (const widgetKey in this.widgetsList) {
      if (widgetKey !== 'disabled') {
        this.widgetsList[widgetKey] = false;
      }
    }
  }

  protected showWidget(widgetName: string): void {
    this.widgetsList[widgetName] = true;
    this.currentWidget = widgetName;
  }

  public scrollToTop(): void {
    if (this.$refs.wdSalesJourney) {
      this.$refs.wdSalesJourney.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }

  // Methods
  protected async productSubmit(): Promise<void> {
    await this.loadAddons();

    if (
      this.selectedProduct &&
      this.selectedProduct.hasAddOns &&
      this.selectedProduct.hasAddOns === 'true' &&
      this.defaultAddons.length &&
      this.availableAddons.filter((addon) => {
        return this.defaultAddons.indexOf(addon.productCode) > -1;
      }).length > 0
    ) {
      // Filter defaultAddons, leave only those that are in availableAddons array
      const defaultSelectedAddons = {
        optionalAddOns: JSON.stringify(
          this.defaultAddons.filter((defaultAddon) => {
            return (
              this.availableAddons
                .map((addon) => {
                  return addon.productCode;
                })
                .indexOf(defaultAddon) > -1
            );
          })
        )
      };
      // Set default selected addons
      this.store.commit('SET_SESSION', {
        optionalAddOns: defaultSelectedAddons.optionalAddOns
      });
      // this.data.sessionState.optionalAddOns = defaultSelectedAddons.optionalAddOns;
      // If in availableAddons array only defaultSelectedAddons, update session
      if (
        this.availableAddons.filter((addon) => {
          return this.defaultAddons.indexOf(addon.productCode) === -1;
        }).length === 0
      ) {
        await this.ApiProvider.sessionUpdate(defaultSelectedAddons).catch((error) => {
          if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
            localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
            window.location.href = document.referrer || window.location.origin;
          }
        });
      }
    }

    let nextWidget = 'contract';

    if (!['elli', 'prokon'].includes(this.currentClient)) {
      nextWidget =
        this.selectedProduct &&
        this.selectedProduct.hasAddOns &&
        this.selectedProduct.hasAddOns === 'true' &&
        (!this.defaultAddons.length ||
          this.availableAddons.filter((addon) => {
            return this.defaultAddons.indexOf(addon.productCode) === -1;
          }).length > 0)
          ? 'addon'
          : 'contract';
    }

    this.widgetsList.disabled[nextWidget] = false;
    this.switchWidget(nextWidget);
    this.scrollToTop();
  }

  protected addonSubmit(): void {
    this.widgetsList.disabled.contract = false;
    this.switchWidget('contract');
    this.scrollToTop();
  }

  protected changedUsage(): void {
    this.ApiProvider.getProducts()
      .then((response) => {
        this.store.commit('SET_AVAILABLE_PRODUCTS', response.data);
        this.log(response.data);
      })
      .catch((error) => {
        this.log(error);
      });
  }

  protected discountCodeApplied(): void {
    this.ApiProvider.getProducts()
      .then((response) => {
        this.store.commit('SET_AVAILABLE_PRODUCTS', response.data);
        // this.data.availableProducts = response.data;
        // this.data.selectedProduct = this.availableProducts.filter(
        //   (product) => {
        //     return product.productCode === this.sessionState.productCode;
        //   },
        // )[0];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  protected contractSubmit(data: any): void {
    if (data.data.campaign_updated) {
      this.ApiProvider.sessionRead()
        .then((response) => {
          this.store.commit('SET_SESSION', response.data);
          this.ApiProvider.getProducts()
            .then((response2) => {
              this.store.commit('SET_AVAILABLE_PRODUCTS', response2.data);
              // this.data.availableProducts = response2.data;
              // this.data.selectedProduct = this.availableProducts.filter(
              //   (product) => {
              //     return product.productCode === this.sessionState.productCode;
              //   },
              // )[0];
              this.loadAddons();
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
            localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
            window.location.href = document.referrer || window.location.origin;
          }
        });
    }
    this.widgetsList.disabled.delivery = false;
    this.switchWidget('delivery');
    if (this.currentClient !== 'elli' && this.currentClient !== 'hsg') {
      this.scrollToTop();
    }
  }

  protected deliverySubmit(): void {
    this.widgetsList.disabled.payment = false;
    this.switchWidget('payment');
    if (this.currentClient !== 'elli' && this.currentClient !== 'hsg') {
      this.scrollToTop();
    }
  }

  protected paymentSubmit(): void {
    this.widgetsList.disabled.rules = false;
    this.switchWidget('rules');
    if (this.currentClient !== 'elli' && this.currentClient !== 'hsg') {
      this.scrollToTop();
    }
  }

  protected rulesSubmit(): void {
    this.widgetsList.disabled.review = false;
    this.switchWidget('review');
    if (this.currentClient !== 'hsg') {
      this.scrollToTop();
    }
  }

  public goToWidget(widgetName: string): void {
    this.switchWidget(widgetName);
  }

  protected orderCreated(): void {
    this.stepperEnabled = false;
    localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
  }

  public loadSessionState(): void {
    throw new Error('loadSessionState - Method not implemented.');
  }

  protected switchValidatedWidget(widget: string): void {
    const widgetUpperCase = widget.replace(/^\w/, (letter) => letter.toUpperCase());
    this.ApiProvider['validate' + widgetUpperCase]()
      .then(() => {
        this.trackingProvider.validationContractSuccessful(this.sessionState, this.data, this.selectedProduct);
        this.switchWidget(widget);
      })
      .catch(() => {
        this.trackingProvider[`validation${widgetUpperCase}Failed`]();
        this.$emit('scrollToTop');
      });
  }

  protected async loadAddons(): Promise<void> {
    if (this.selectedProduct && this.selectedProduct.hasAddOns && this.selectedProduct.hasAddOns === 'true') {
      await this.ApiProvider.getAddons()
        .then((response) => {
          this.store.commit('SET_AVAILABLE_ADDONS', response.data);
          // this.data.availableAddons = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
