import { render, staticRenderFns } from "./ContractWidget.vue?vue&type=template&id=7ccab087&"
import script from "./ContractWidget.vue?vue&type=script&lang=ts&"
export * from "./ContractWidget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports