











import Basic from '@/components/widgets/Basic.vue';
import { INotification } from '@/interfaces/Notification';
import { default as Component } from 'vue-class-component';
import SingleNotification from './SingleNotification.vue';

@Component({
  components: {
    'single-notification': SingleNotification
  }
})
export default class NotificationWidget extends Basic {
  get getNotifications(): INotification[] {
    return this.store.getters.getNotifications;
  }
}
