import { injectable } from 'inversify';
import Bonus from '@/interfaces/Bonus';
import ApiProvider from './ApiProvider';
import { AxiosResponse } from 'axios';

@injectable()
export default class BonusProvider extends ApiProvider implements Bonus {
  public async verify(data: { code: string }): Promise<AxiosResponse> {
    return await this.http.post('/bonus/verify', data);
  }
}
