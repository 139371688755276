



















































































































































import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import vSelect from 'vue-select';

import ProductInfoWidget from './ProductInfoWidget.vue';
import InternationalProductInfoWidget from './international/InternationalProductInfoWidget.vue';
import QcellsProductInfoWidget from './qcells/QcellsProductInfoWidget.vue';

import DatePicker from '../vuejs-datepicker/src/components/Datepicker.vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Api from '../../interfaces/Api';
import Tracking from '@/interfaces/Tracking';
import ProviderData from '@/interfaces/ProviderData';
import { DeliveryStartTypes } from '@/interfaces/Delivery';

const ApiProvider = IocContainer.get<Api>(SERVICES.API);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@Component({
  components: {
    'v-select': vSelect,
    'product-info': ProductInfoWidget,
    'international-info': InternationalProductInfoWidget,
    'qcells-product-info': QcellsProductInfoWidget,
    'date-picker': DatePicker
  },
  props: {
    availableProviders: {},
    product: {},
    requireCounterNumberValidation: Boolean
  }
})
export default class DeliveryWidget extends Basic {
  public deliveryData = {
    customerSpecification: { id: DeliveryStartTypes.DesiredDate as string, label: '' },
    date: null as any,
    counterNumber: null as any,
    marketLocationIdentifier: null as any,
    previousProvider: {
      id: '',
      text: '',
      codeNumber: ''
    },
    customerNumber: null as any
  };
  // public errorsBag: any = [];
  public restrictGoNext = false;
  public sessionMappedDelivery = false;
  public product = this.$props.product;

  get notEarliestPossible(): boolean {
    return (
      this.deliveryData.customerSpecification && this.deliveryData.customerSpecification.id !== 'earliest_possible_date'
    );
  }

  get noPreviousProviderNeeded(): boolean {
    return (
      this.deliveryData.customerSpecification &&
      (this.deliveryData.customerSpecification.id === 'terminated_in_person' ||
        this.deliveryData.customerSpecification.id === 'relocation_at')
    );
  }

  get datePlaceholder(): string {
    if (this.deliveryData.customerSpecification) {
      switch (this.deliveryData.customerSpecification.id) {
        case 'desired_date':
          return this.$t('delivery.widget.date.placeholder.desired.date').toLocaleString();
        case 'terminated_in_person':
          return this.$t('delivery.widget.date.placeholder.terminated.in.person').toLocaleString();
        case 'relocation_at':
          return this.$t('delivery.widget.date.placeholder.relocation.at').toLocaleString();
        default:
          return this.$t('delivery.widget.date.placeholder').toLocaleString();
      }
    }
    return this.$t('delivery.widget.date.placeholder').toLocaleString();
  }

  get customerSpecifications(): { id: string; label: string }[] {
    return [
      {
        id: 'earliest_possible_date',
        label: this.$t('delivery.widget.delivery.asap').toLocaleString()
      },
      {
        id: 'desired_date',
        label: this.$t('delivery.widget.delivery.desired.date').toLocaleString()
      },
      {
        id: 'terminated_in_person',
        label: this.$t('delivery.widget.delivery.terminated.in.person').toLocaleString()
      },
      {
        id: 'relocation_at',
        label: this.$t('delivery.widget.delivery.relocation').toLocaleString()
      }
    ];
    // {id: 'basic_supply_registration', label: 'Grundversorgung'},
  }

  get providers(): ProviderData[] {
    return this.$props.availableProviders ? this.$props.availableProviders : [];
  }

  public mounted(): void {
    if (JSON.stringify(this.store.getters.getSession) !== '{}') {
      // this.sessionStateWidget = this.store.getters.getSession;
      this.mapSessionToDeliveryData();
    }
    ApiProvider.sessionRead()
      .then((response) => {
        this.store.commit('SET_SESSION', response.data);
        // this.sessionStateWidget = this.store.getters.getSession;
        this.mapSessionToDeliveryData();
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  public getOptionKey(option: { id: string | number; text: string }): string | number {
    return option.id;
  }

  public getOptionLabel(option: { id: string | number; text: string }): string {
    if (option.text && option.text.length > 80) {
      return option.text.substring(0, 80) + '...';
    }
    return option.text;
  }

  public deliverySubmit(): Promise<void> | void {
    this.resetErrorBag();
    // this.errorsBag = [];

    let desiredDate = null;
    let terminationDate = null;
    let relocationDate = null;
    let basicSupplyDate = null;

    if (!this.deliveryData.customerSpecification) {
      const message = this.$t('delivery.widget.delivery.date.error').toString();
      this.setError('customerSpecification', 'delivery.widget.delivery.date.error', message);
      // (this.errorsBag)
      //   ? this.errorsBag.customerSpecification = [message]
      //   : this.errorsBag = { customerSpecification : [message] };
      this.$emit('scrollToTop');
    }

    switch (this.deliveryData.customerSpecification.id) {
      case 'desired_date':
        desiredDate = this.deliveryData.date;
        break;
      case 'terminated_in_person':
        terminationDate = this.deliveryData.date;
        break;
      case 'relocation_at':
        relocationDate = this.deliveryData.date;
        break;
      case 'basic_supply':
        basicSupplyDate = this.deliveryData.date;
        break;
    }

    if (!this.validateDate()) {
      this.setErrorsForRequired();
      return;
    }

    const previousProviderCodeNumber =
      this.deliveryData.previousProvider && this.deliveryData.previousProvider.codeNumber
        ? this.deliveryData.previousProvider.codeNumber
        : null;
    const counterNumber = this.deliveryData.counterNumber;
    const marketLocationIdentifier = this.deliveryData.marketLocationIdentifier;
    const customerSpecification = this.deliveryData.customerSpecification
      ? this.deliveryData.customerSpecification.id
      : null;
    const customerNumber = this.deliveryData.customerNumber;

    if (counterNumber !== null && counterNumber !== '' && !/\d/.test(counterNumber)) {
      this.setErrorsForRequired();
      const message = this.$t('delivery.widget.meter.number.error').toString();
      this.setError('counterNumber', 'delivery.widget.meter.number.error', message);
      // (this.errorsBag)
      //   ? this.errorsBag.counterNumber = [message]
      //   : this.errorsBag = { counterNumber : [message] };
      this.$emit('scrollToTop');
      return;
    }

    switch (this.deliveryData.customerSpecification.id) {
      case 'earliest_possible_date':
        if (!previousProviderCodeNumber || counterNumber === null || counterNumber === '') {
          this.setErrorsForRequired();
          return;
        }
        break;
      case 'desired_date':
        if (!previousProviderCodeNumber) {
          this.setError(
            'previousProviderCodeNumber',
            'rules.delivery.previousProvider.required',
            this.$t('rules.delivery.previousProvider.required').toLocaleString()
          );
          return;
        }
        break;
      case 'terminated_in_person':
      case 'relocation_at':
        if (counterNumber === null || counterNumber === '') {
          this.setError(
            'counterNumber',
            'rules.delivery.counterNumber.required',
            this.$t('rules.delivery.counterNumber.required').toLocaleString()
          );
          return;
        }
        break;
    }

    return ApiProvider.sessionUpdate({
      previousProviderCodeNumber,
      counterNumber,
      marketLocationIdentifier,
      customerSpecification,
      desiredDate,
      terminationDate,
      relocationDate,
      basicSupplyDate,
      customerNumber
    })
      .then(() => {
        ApiProvider.validateDelivery()
          .then(() => {
            this.store.commit('SET_SESSION', {
              previousProviderCodeNumber,
              counterNumber,
              marketLocationIdentifier,
              customerSpecification,
              desiredDate,
              terminationDate,
              relocationDate,
              basicSupplyDate
            });
            trackingProvider.validationDeliverySuccessful(
              this.sessionState,
              this.$props.product,
              this.deliveryData.previousProvider,
              this.deliveryData.customerSpecification
            );
            this.$emit('deliverySubmit', this.deliveryData);
          })
          .catch((error) => {
            trackingProvider.validationDeliveryFailed();
            this.$emit('scrollToTop');
            this.errorsBag = error.response.data.error.messages;
          });
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  get errorCustomerSpecification(): string | null {
    return this.getError('customerSpecification');
  }
  get errorDate(): string | null {
    if (this.deliveryData.date && this.deliveryData.customerSpecification) {
      switch (this.deliveryData.customerSpecification.id) {
        case 'desired_date':
          return this.getError('desiredDate');
        case 'terminated_in_person':
          return this.getError('terminationDate');
        case 'relocation_at':
          return this.getError('relocationDate');
        case 'basic_supply':
          return this.getError('basicSupplyDate');
      }
    }
    return null;
  }
  get errorCounterNumber(): string | null {
    return this.getError('counterNumber');
  }
  get errorMarketLocationIdentifier(): string | null {
    return this.getError('marketLocationIdentifier');
  }
  get errorPreviousProvider(): string | null {
    return this.getError('previousProviderCodeNumber');
  }

  get errorDesiredDate(): string | null {
    this.validateDate();
    return this.getError('desiredDate');
  }

  // public getError(fieldName: string): string | null {
  //   if (!this.errorsBag || !this.errorsBag[fieldName]) {
  //     return null;
  //   }
  //
  //   const errorsArray = this.errorsBag[fieldName];
  //   const result: string[] = [];
  //
  //   errorsArray.forEach((errorObject) => {
  //     if (typeof errorObject === 'object') {
  //       if (this.$te(errorObject.key)) {
  //         result.push(this.$t(errorObject.key).toLocaleString());
  //       } else {
  //         result.push(errorObject.message);
  //       }
  //     } else {
  //       result.push(errorObject);
  //     }
  //   });
  //
  //   return (result.length > 0) ? result.join(' ') : null;
  // }

  get disabledDatesDelivery(): { to: Date; from: Date } {
    return {
      to: new Date(this.firstValidDeliveryDate),
      from: new Date(this.lastValidDate)
    };
  }

  get openAtDate(): Date {
    return new Date(this.firstValidDeliveryDate);
  }

  get firstValidDeliveryDate(): any {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    switch (this.deliveryData.customerSpecification.id) {
      case 'desired_date':
        return date.setDate(date.getDate() + 21);
      case 'terminated_in_person':
        return date.setDate(date.getDate() + 21);
      case 'relocation_at':
        return date.setDate(date.getDate() - 6 * 7);
    }
    return date.setDate(date.getDate());
  }

  get lastValidDate(): any {
    const date = new Date();
    date.setHours(23, 59, 59, 0);
    switch (this.deliveryData.customerSpecification.id) {
      case 'desired_date':
        return date.setDate(date.getDate() + 365 / 2);
      case 'terminated_in_person':
        return date.setDate(date.getDate() + 365 / 2);
      case 'relocation_at':
        return date.setDate(date.getDate() + 365 / 2);
    }
    return date.setDate(date.getDate() + 1);
  }

  public isDateValid(dt: string): any {
    const date = new Date(dt);
    return date >= this.firstValidDeliveryDate && date < this.lastValidDate;
  }

  public saveDate(date: string): void {
    if (date) {
      this.deliveryData.date = date;
    } else {
      this.deliveryData.date = null;
    }
  }

  protected getErrorDesiredDate(): Record<any, any> {
    const errorData: Record<any, any> = {
      key: 'delivery.widget.delivery.desired.date.error',
      message: this.$t('delivery.widget.delivery.desired.date.error').toLocaleString()
    };
    return errorData;
  }

  public validateDate(): boolean {
    this.resetDateErrors();

    if (
      this.deliveryData.customerSpecification &&
      this.deliveryData.customerSpecification.id === 'earliest_possible_date'
    ) {
      this.deliveryData.date = null;
      return true;
    }
    if (
      this.deliveryData.customerSpecification &&
      this.deliveryData.customerSpecification.id !== 'earliest_possible_date' &&
      !this.isDateValid(this.deliveryData.date)
    ) {
      let message = '';
      let errorData: Record<any, any>;
      switch (this.deliveryData.customerSpecification.id) {
        case 'desired_date':
          errorData = this.getErrorDesiredDate();
          this.setError('desiredDate', errorData.key, errorData.message);
          // (this.errorsBag)
          //   ? this.errorsBag.desiredDate = [message]
          //   : this.errorsBag = { desiredDate : [message] };
          break;
        case 'terminated_in_person':
          message = this.$t('delivery.widget.delivery.terminated.in.person.error').toLocaleString();
          this.setError('terminationDate', 'delivery.widget.delivery.terminated.in.person.error', message);
          // (this.errorsBag)
          //   ? this.errorsBag.terminationDate = [message]
          //   : this.errorsBag = { terminationDate : [message] };
          break;
        case 'relocation_at':
          message = this.$t('delivery.widget.delivery.relocation.error').toLocaleString();
          this.setError('relocationDate', 'delivery.widget.delivery.relocation.error', message);
          // (this.errorsBag)
          //   ? this.errorsBag.relocationDate = [message]
          //   : this.errorsBag = { relocationDate : [message] };
          break;
      }
      this.restrictGoNext = true;
      // this.$emit('scrollToTop');
      return false;
    }
    return true;
  }

  public resetDateErrors(): void {
    if (this.errorsBag) {
      const errorBagKeys = Object.keys(this.errorsBag);
      if (errorBagKeys.length === 1) {
        this.resetErrorBag();
        // this.errorsBag = [];
      } else if (this.deliveryData.customerSpecification) {
        switch (this.deliveryData.customerSpecification.id) {
          case 'desired_date':
            delete this.errorsBag.desiredDate;
            break;
          case 'terminated_in_person':
            delete this.errorsBag.terminationDate;
            delete this.errorsBag.terminated_in_person;
            break;
          case 'relocation_at':
            delete this.errorsBag.relocationDate;
            delete this.errorsBag.relocation_at;
            break;
        }
      }
    }
    this.restrictGoNext = false;
  }

  public sanitizeCounterNumber(): void {
    if (!this.$props.requireCounterNumberValidation) {
      return;
    }
    this.deliveryData.counterNumber = this.deliveryData.counterNumber.replace(/[^A-Z0-9-/]/gi, '');
  }

  protected setErrorsForRequired(): void {
    const specificationId = this.deliveryData.customerSpecification.id;
    if (specificationId === 'earliest_possible_date' || specificationId === 'desired_date') {
      this.setError(
        'previousProviderCodeNumber',
        'rules.delivery.previousProvider.required',
        this.$t('rules.delivery.previousProvider.required').toLocaleString()
      );
      // this.errorsBag.previousProviderCodeNumber = [this.$t('rules.delivery.previousProvider.required')];
    }

    if (['sachsenenergie', 'qcells'].includes(this.currentClient) || this.currentClient.includes('dsp-')) {
      this.setError(
        'counterNumber',
        'rules.delivery.counterNumber.required',
        this.$t('rules.delivery.counterNumber.required').toLocaleString()
      );
    }
  }

  /**
   * Get session data
   * @protected
   */
  protected deliveryGetSessionData(): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiProvider.sessionRead()
        .then((response) => {
          this.store.commit('SET_SESSION', response.data);
          this.mapSessionToDeliveryData();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private mapSessionToDeliveryData() {
    this.sessionMappedDelivery = false;
    this.deliveryData.counterNumber = this.sessionState.counterNumber || null;
    this.deliveryData.marketLocationIdentifier = this.sessionState.marketLocationIdentifier || null;
    this.deliveryData.previousProvider = this.providers.filter((provider) => {
      return provider.codeNumber === this.sessionState.previousProviderCodeNumber;
    })[0];
    this.deliveryData.customerSpecification = {
      ...this.customerSpecifications.filter((spec) => {
        return (
          spec.id ===
          (this.sessionState.customerSpecification
            ? this.sessionState.customerSpecification
            : ['wdenergy', 'sachsenenergie', 'elli'].indexOf(this.currentClient) > -1
            ? DeliveryStartTypes.DesiredDate
            : DeliveryStartTypes.EarliestPossibleDate)
        );
      })[0]
    };
    switch (this.sessionState.customerSpecification) {
      case DeliveryStartTypes.DesiredDate:
        this.deliveryData.date = this.sessionState.desiredDate;
        break;
      case DeliveryStartTypes.TerminatedInPerson:
        this.deliveryData.date = this.sessionState.terminationDate;
        break;
      case DeliveryStartTypes.RelocationAt:
        this.deliveryData.date = this.sessionState.relocationDate;
        break;
      default:
        this.deliveryData.date = null;
        break;
    }
    this.deliveryData.customerNumber = this.sessionState.customerNumber || null;

    this.$nextTick(() => {
      this.sessionMappedDelivery = true;
    });
  }
}
