

































































































































































































import ContractWidget from '../ContractWidget.vue';
import { default as Component } from 'vue-class-component';

@Component({})
export default class PregoContractWidget extends ContractWidget {}
