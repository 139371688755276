const SERVICES = {
  API: Symbol('Api'),
  BONUS: Symbol('Bonus'),
  FORMAT: Symbol('Format'),
  TRACKING: Symbol('Tracking'),
  ADDON: Symbol('AddOn'),
  LANGUAGE: Symbol('Language'),
  URL: Symbol('Url')
};

export default SERVICES;
