
































import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import ProductInfoWidget from './ProductInfoWidget.vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Api from '../../interfaces/Api';
import Tracking from '@/interfaces/Tracking';
const ApiProvider = IocContainer.get<Api>(SERVICES.API);
import Format from '@/interfaces/Format';
import ProductData from '@/interfaces/ProductData';
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@Component({
  components: {
    'product-info': ProductInfoWidget
  },
  filters: {
    date: formatProvider.date(),
    euro: formatProvider.euro(),
    euroFull: formatProvider.euroFull(),
    zip: formatProvider.zip()
  },
  props: {
    availableProducts: {},
    loadingProducts: {},
    availableAddons: {}
  }
})
export default class ProductWidget extends Basic {
  public availableAddons;
  @Watch('products')
  public productsLoaded(products: []): void {
    trackingProvider.productsLoaded(this.sessionState, products);
  }

  get products(): ProductData[] {
    return this.store.getters.getAvailableProducts;
  }

  get selectedProduct(): string | null {
    if (JSON.stringify(this.store.getters.getSession) !== '{}') {
      return this.store.getters.getSession.productCode;
    } else {
      return null;
    }
  }

  public productSubmit(product: { productCode: string; productVersionId: string }): void {
    const data = {
      productCode: product.productCode,
      productVersionId: this.isInternational ? product.productVersionId : null
    };
    ApiProvider.sessionUpdate(data)
      .then(() => {
        this.store.commit('SET_SESSION', data);
        this.$emit('productSubmit', data);
        trackingProvider.productSelected(this.sessionState, product);
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }
}
