

































































































































































































































































































































































































































import RulesWidget from '@/components/widgets/RulesWidget.vue';
import ReviewWidget from '@/components/widgets/ReviewWidget.vue';
import { default as Component, mixins } from 'vue-class-component';

interface ReviewAndOrderWidget extends RulesWidget, ReviewWidget {}

@Component({})
export default class PregoReviewAndOrderWidget extends mixins<ReviewAndOrderWidget>(RulesWidget, ReviewWidget) {
  get submitDisabled(): boolean {
    return !this.rulesData.tandcs;
  }

  public reviewAndSubmitOrder(): void {
    if (!this.rulesData.tandcs) {
      return;
    }
    const rulesSubmitted = this.rulesSubmit();
    if (rulesSubmitted instanceof Promise) {
      rulesSubmitted.then(() => {
        if (
          !this.errorsBag ||
          (this.errorsBag.length === 0 && !this.errorMessages) ||
          this.errorMessages.length === 0
        ) {
          this.submitOrder();
        }
      });
    }
  }
}
