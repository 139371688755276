




















































































































































































import Basic from '@/components/widgets/Basic.vue';
// import vue from 'vue';
import { default as Component } from 'vue-class-component';

import ProductInfoWidget from './ProductInfoWidget.vue';
import InternationalProductInfoWidget from './international/InternationalProductInfoWidget.vue';
import QcellsProductInfoWidget from './qcells/QcellsProductInfoWidget.vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Api from '../../interfaces/Api';
import Tracking from '@/interfaces/Tracking';

const ApiProvider = IocContainer.get<Api>(SERVICES.API);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@Component({
  components: {
    'product-info': ProductInfoWidget,
    'international-info': InternationalProductInfoWidget,
    'qcells-product-info': QcellsProductInfoWidget
  },
  props: {
    product: {}
  }
})
export default class RulesWidget extends Basic {
  public rulesData: {
    adsPhone: boolean;
    adsMail: boolean;
    adsPost: boolean;
    adsAll: boolean;
    tandcs: boolean;
    privacy: boolean;
    conditions: boolean;
    conditionsDiscount: boolean;
    acceptTerms: boolean;
    agreeECommunication: boolean;
    permissionToTerminateContract: boolean;
    extendedData: string;
    uvpCode: string | null;
    vpCode: string | null;
  } = {
    adsPhone: false,
    adsMail: false,
    adsPost: false,
    adsAll: false,
    tandcs: false,
    privacy: false,
    conditions: false,
    conditionsDiscount: false,
    acceptTerms: false,
    agreeECommunication: false,
    permissionToTerminateContract: false,
    extendedData: '{}',
    uvpCode: null,
    vpCode: null
  };
  public errorMessages: string[] = [];
  // public errorsBag: any = null;

  public rulesValidationPassed = false;
  public extendedData: any = JSON.parse('{}');

  public created(): void {
    if (this.currentClient === 'qcells') {
      this.extendedData = {
        // Werte: {
        // Gewinnspiel: false,
        // },
        INFO_KUNDE: {
          HAUSBESITZER: false
        }
      };
    }
  }

  public mounted(): void {
    if (JSON.stringify(this.store.getters.getSession) !== '{}') {
      this.mapSessionToRulesData();
    }
    ApiProvider.sessionRead()
      .then((response) => {
        this.store.commit('SET_SESSION', response.data);
        this.mapSessionToRulesData();
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  public rulesSubmit(): Promise<void> | void {
    this.errorMessages = [];
    if (!this.rulesData.tandcs) {
      this.errorMessages.push(this.$t('rules.widget.error1').toLocaleString());
      this.setError('tandcs', 'rules.widget.error1', this.$t('rules.widget.error1').toLocaleString());
      this.$emit('scrollToTop');
    }
    if (!this.rulesData.privacy && this.currentClient !== 'wdenergy' && this.currentClient !== 'sachsenenergie') {
      if (this.currentClient === 'qcells' || this.currentClient === 'gamma-test') {
        this.setError('privacy', 'rules.widget.error2', this.$t('rules.widget.error2').toLocaleString());
        this.errorMessages.push(this.$t('rules.widget.error2').toLocaleString());
      } else {
        this.setError('privacy', 'rules.widget.error3', this.$t('rules.widget.error3').toLocaleString());
        this.errorMessages.push(this.$t('rules.widget.error3').toLocaleString());
      }
      this.$emit('scrollToTop');
    }
    if (this.currentClient === 'wdenergy') {
      if (!this.rulesData.conditions) {
        this.errorMessages.push(this.$t('rules.widget.error4').toLocaleString());
        this.$emit('scrollToTop');
      }
    }
    if (
      (this.currentClient === 'qcells' && this.rulesData.privacy && this.rulesData.tandcs) ||
      (this.currentClient === 'gamma-test' && this.rulesData.privacy && this.rulesData.tandcs) ||
      (this.currentClient === 'wdenergy' && this.rulesData.conditions && this.rulesData.tandcs) ||
      (this.currentClient === 'sachsenenergie' && this.rulesData.conditions && this.rulesData.tandcs) ||
      // Default Rule for demos
      (this.currentClient !== 'qcells' &&
        this.currentClient !== 'gamma-test' &&
        this.currentClient !== 'wdenergy' &&
        this.rulesData.privacy &&
        this.rulesData.tandcs)
    ) {
      if (this.rulesData.adsAll) {
        this.rulesData.adsPost = true;
        this.rulesData.adsPhone = true;
        this.rulesData.adsMail = true;
      }
      // Before sending to backend, the object is transformed into JSON string
      this.rulesData.extendedData =
        typeof this.extendedData === 'object' ? JSON.stringify(this.extendedData) : this.extendedData;

      return ApiProvider.sessionUpdate(this.rulesData)
        .then(() => {
          this.rulesValidationPassed = false;
          ApiProvider.validateRules()
            .then(() => {
              // Before saving to the session, JSON is transformed back into an object
              this.rulesData.extendedData =
                typeof this.rulesData.extendedData === 'string'
                  ? JSON.parse(this.rulesData.extendedData)
                  : this.rulesData.extendedData;

              this.store.commit('SET_SESSION', this.rulesData);
              trackingProvider.validationRulesSuccessful(this.sessionState, this.$props.product);
              this.$emit('rulesSubmit', this.rulesData);
              this.rulesValidationPassed = true;
            })
            .catch((error) => {
              trackingProvider.validationRulesFailed();
              this.$emit('scrollToTop');
              this.errorsBag = error.response.data.error.messages;
              this.rulesValidationPassed = false;
            });
        })
        .catch((error) => {
          if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
            localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
            window.location.href = document.referrer || window.location.origin;
          }
        });
    } else {
      this.$emit('scrollToTop');
    }
  }

  get lotteryLink(): string {
    if (this.currentClient === 'qcells') {
      return 'https://www.q-cells.de/qenergy-gewinnspiel-august-2020-teilnahmebedingungen/';
    }
    return '';
  }

  get errorAdsPhone(): string | null {
    return this.getError('adsPhone');
  }
  get errorAdsMail(): string | null {
    return this.getError('adsMail');
  }
  get errorAdsPost(): string | null {
    return this.getError('adsPost');
  }
  get errorAdsAll(): string | null {
    return this.getError('adsMail');
  }

  private mapSessionToRulesData() {
    this.sessionMapped = false;
    this.rulesData.adsMail = this.sessionState.adsMail ? this.sessionState.adsMail : false;
    this.rulesData.adsPhone = this.sessionState.adsPhone ? this.sessionState.adsPhone : false;
    this.rulesData.adsPost = this.sessionState.adsPost ? this.sessionState.adsPost : false;
    this.rulesData.adsAll = this.rulesData.adsPhone && this.rulesData.adsMail && this.rulesData.adsPost;
    this.rulesData.uvpCode = this.sessionState.uvpCode ? this.sessionState.uvpCode : null;
    this.rulesData.vpCode = this.sessionState.vpCode ? this.sessionState.vpCode : null;

    if (typeof this.sessionState.extendedData === 'string') {
      this.extendedData = { ...JSON.parse(this.sessionState.extendedData), ...this.extendedData };
    } else {
      this.extendedData = { ...this.sessionState.extendedData, ...this.extendedData };
    }
    this.sessionMapped = true;
  }
}
