























































































































import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import ReminderWidget from './ReminderWidget.vue';
import { Watch, Prop } from 'vue-property-decorator';

import Format from '@/interfaces/Format';
import Tracking from '@/interfaces/Tracking';
import AddOn from '@/interfaces/AddOn';
import { CommodityProductData } from '@/interfaces/ProductData';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
const addOnProvider = IocContainer.get<AddOn>(SERVICES.ADDON);

@Component({
  components: {
    'reminder-widget': ReminderWidget
  },
  props: {
    availableAddons: Array,
    product: Object
  },
  filters: {
    date: formatProvider.date(),
    euro: formatProvider.euro(),
    euroFull: formatProvider.euroFull()
  }
})
export default class ProductInfoWidget extends Basic {
  public getAddonImageUrl(value: string): string | null {
    if (value) {
      const regEx = /<p>(https?:\/\/[^ ]*)<\/p>/;
      const match = value.match(regEx);
      return match ? match[1] : '';
    }

    return '';
  }

  get parentIsProductWidget(): boolean {
    return this.$parent.$options.name === 'ProductWidget';
  }

  get primaryAddons(): [] {
    return this.$props.defaultAddons;
  }

  get addons(): [] | null {
    if (
      this.$props.product !== null &&
      typeof this.$props.product.addOns !== 'undefined' &&
      this.$props.product.addOns !== null &&
      this.$props.product.addOns.length > 0
    ) {
      return this.$props.product.addOns.slice(0, 1);
    }
    return null;
  }

  get flexbonus():
    | {
        id: number;
        data: {
          name: string;
          amount: number;
        };
      }[]
    | null {
    return this.store.getters.getFlexbonus;
  }

  get flexbonusName(): string {
    if (this.flexbonus) {
      return this.flexbonus[0].data.name;
    }
    return '';
  }

  get totalBasePriceBonusBrutto(): number {
    if (this.flexbonus) {
      return this.$props.product.totalBasePriceBonusBrutto - this.flexbonus[0].data.amount;
    }
    return this.$props.product.totalBasePriceBonusBrutto;
  }

  get totalBasePriceBonusBruttoMonth(): number {
    if (this.flexbonus) {
      return this.$props.product.totalBasePriceBonusBruttoMonth - this.flexbonus[0].data.amount / 12;
    }
    return this.$props.product.totalBasePriceBonusBruttoMonth;
  }

  protected parseImage(image: string | null): string {
    return addOnProvider.parseImage(image);
  }

  private productDetailsChange(product) {
    if (product.displayDetails) {
      trackingProvider.productDetailsHide();
    } else {
      trackingProvider.productDetailsShow(this.sessionState, this.$props.product);
    }
  }
}
