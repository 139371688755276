





























import vue from 'vue';
import { default as Component } from 'vue-class-component';

import { Watch } from 'vue-property-decorator';
import ProductData from '@/interfaces/ProductData';

@Component({
  props: {
    availableProducts: {},
    sessionState: {}
  }
})
export default class PregoFooterWidget extends vue {
  public showSecondInfo = false;
  public showThirdInfo = false;

  @Watch('$props.availableProducts')
  protected checkBonuses(products: ProductData[]): void {
    for (const product of products) {
      if (this.showSecondInfo && this.showThirdInfo) {
        break;
      }
      if (product.instantBonus !== 0) {
        this.showSecondInfo = true;
      }
      if (product.switchingBonus !== 0 || product.recurringBonus !== 0) {
        this.showThirdInfo = true;
      }
    }
  }
}
