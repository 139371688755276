






















































































































































































































































import { default as Component } from 'vue-class-component';
import Basic from '@/components/widgets/Basic.vue';
import SubmitButton from '@/components/widgets/SubmitButton.vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Api from '../../interfaces/Api';
import Format from '@/interfaces/Format';
import Tracking from '@/interfaces/Tracking';

const ApiProvider = IocContainer.get<Api>(SERVICES.API);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

import ProductInfoWidget from './ProductInfoWidget.vue';
import InternationalProductInfoWidget from './international/InternationalProductInfoWidget.vue';
import QcellsProductInfoWidget from './qcells/QcellsProductInfoWidget.vue';
import { AxiosResponse } from 'axios';

@Component({
  components: {
    'product-info': ProductInfoWidget,
    'international-info': InternationalProductInfoWidget,
    'qcells-product-info': QcellsProductInfoWidget,
    SubmitButton
  },
  filters: {
    zip: formatProvider.zip(),
    date: formatProvider.date(),
    euro: formatProvider.euro(),
    euroFull: formatProvider.euroFull(),
    euroThree: formatProvider.euroThree()
  }
})
export default class ReviewWidget extends Basic {
  public dataReview: any = {};
  public paymentDetails: {
    bank: string;
    bic: string;
  } = {
    bank: '',
    bic: ''
  };
  public products: any[] = [];
  public productDetails: any = {};
  public providersInReview: any[] = [];
  public errorMessages: string[] = [];
  public validated = false;
  public inCreation = false;
  public orderCreated = false;
  public orderId: any = null;
  public contractId: any = null;

  public mounted(): void {
    this.dataReview = this.store.getters.getSession;
    this.products = this.store.getters.getAvailableProducts;
    this.providersInReview = this.store.getters.getAvailableProviders;
    ApiProvider.sessionRead()
      .then((response) => {
        this.store.commit('SET_SESSION', response.data);
        this.dataReview = this.store.getters.getSession;
        if (this.dataReview.iban) {
          ApiProvider.getBicAndBank(this.dataReview.iban).then((responseIban) => {
            this.paymentDetails.bank = responseIban.data.bank;
            this.paymentDetails.bic = responseIban.data.bic;
          });
        }

        if (this.currentClient === 'sachsenenergie') {
          ApiProvider.getProductDetails(response.data.productCode).then((responseDetails) => {
            this.productDetails = responseDetails.data;
          });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
    ApiProvider.getProducts().then((response) => {
      this.products = response.data;
      this.products.map((product) => {
        product.initialDurationType = this.getDurationType(product.initialDurationType, product.initialDuration);
        product.renewalDurationType = this.getDurationType(product.renewalDurationType, product.renewalDuration);
        product.cancellationPeriodDaysType = this.getDurationType(
          product.cancellationPeriodDaysType,
          product.cancellationPeriodDays
        );
        product.fixedPriceMonthsType = this.getDurationType('MONTHS', product.fixedPriceMonths);
        return product;
      });
    });
    ApiProvider.getProviders().then((response) => {
      this.providersInReview = response.data;
    });

    ApiProvider.validate()
      .then((response) => {
        if (response.data.success) {
          this.validated = true;
        }
      })
      .catch((error) => {
        if (typeof error.response.data.error.messages === 'object') {
          const messages: string[] = [];
          Object.keys(error.response.data.error.messages)
            .map((key) => [key, error.response.data.error.messages[key]])
            .forEach((error2) => {
              messages.push(error2[1][0]);
            });

          this.errorMessages = messages;
        } else {
          this.errorMessages = error.response.data.error.messages;
        }
      });
  }

  get startOfDelivery(): string | null {
    let tmpDate;
    switch (this.reviewDataSet.customerSpecification) {
      case 'earliest_possible_date':
        return this.$t('delivery.widget.delivery.asap').toLocaleString();
      case 'desired_date':
        return this.reviewDataSet.desiredDate;
      case 'terminated_in_person':
        if (this.currentClient.includes('elli')) {
          tmpDate = new Date(this.reviewDataSet.terminationDate ?? '');
          tmpDate.setDate(tmpDate.getDate() + 1);
          return tmpDate;
        }
        return this.reviewDataSet.terminationDate ?? '';
      case 'relocation_at':
        return this.reviewDataSet.relocationDate;
    }
    return null;
  }

  get billingSalutationDisplay(): string {
    return this.getSalutationForKey(this.reviewDataSet.billingSalutation);
  }

  get salutationDisplay(): string {
    return this.getSalutationForKey(this.reviewDataSet.salutation);
  }

  get reviewDataSet(): any {
    return this.dataReview;
  }

  get bic(): string {
    return this.paymentDetails.bic;
  }

  get bank(): string {
    return this.paymentDetails.bank;
  }

  get product(): { productCode: string | null; productName: string | null } {
    const product = this.products.filter((p) => {
      return p.productCode === this.dataReview.productCode;
    })[0];

    return product
      ? product
      : {
          productName: null
        };
  }

  get provider(): { codeNumber: string; id: any; text: string } {
    const provider = this.providersInReview.filter((p) => {
      return p.codeNumber === this.dataReview.previousProviderCodeNumber;
    })[0];
    return provider
      ? provider
      : {
          id: null,
          text: ''
        };
  }

  //eslint-disable-next-line
  protected pushErrorMessage(messageString: string, errorObject: Record<any, any> = []): void {
    this.errorMessages.push(messageString);
  }

  public submitOrder(): void {
    trackingProvider.orderSent();
    this.inCreation = true;
    ApiProvider.create()
      .then((response) => {
        if (response.data.success) {
          trackingProvider.orderCreated(this.dataReview, this.product, this.provider, response.data.data.id);
          this.orderCreated = true;
          this.orderId = response.data.data.id ?? null;
          this.contractId = response.data.data.contractId ?? null;
          this.$emit('orderCreated', response.data);
        } else {
          trackingProvider.orderFailed();

          this.pushErrorMessage(this.getErrorMessage(response), response.data);

          this.inCreation = false;
        }
      })
      .catch((error) => {
        trackingProvider.orderFailed();
        if (error.response.status !== 500 && error.response.data.error && error.response.data.error.message) {
          this.pushErrorMessage(error.response.data.error.message, error.response.data);
        } else {
          this.pushErrorMessage(this.$t('review.widget.error').toString());
        }
        this.inCreation = false;
      });
  }
}
