import Vue from 'vue';
import Vuex from 'vuex';
import { INotification, IAlertSettings, INotificationSettings, NotificationActions } from '@/interfaces/Notification';
import { ISetting } from '@/interfaces/Setting';

import SessionState from '@/interfaces/SessionState';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import Api from '@/interfaces/Api';
import ProductData from '@/interfaces/ProductData';
import { INonCommodityStep } from '@/interfaces/NonCommodityStep';
import lodash from 'lodash';
import ThgServiceElliDefault from '@/models/thg/ThgServiceElliDefault';

const ApiProvider = IocContainer.get<Api>(SERVICES.API);

Vue.use(Vuex);

// Data Structure
const state: Partial<SessionState> = {
  street: null,
  city: null,
  billingZip: null,
  billingStreet: null,
  billingHouseNumber: null
};
const availableProducts: any[] = [];
const availableProviders: any[] = [];
const availableAddons: any[] = [];
const defaultAddons: any[] = [];
const notificationSettings: INotificationSettings = {};
const alertSettings: IAlertSettings = {};
const email: string = '';
const discountCode: string = '';
const discountValue: number = 0;
const customAppIdle: boolean = false;
const isWaitingForRequest: boolean = false;
const modalMessages: string = '';
const displayModal: boolean = false;
const successModal: boolean = false;
const alertWidgetLoading: boolean = false;
const notifications: INotification[] = [];
const sessionIsSaved: boolean = true;
const sessionModalDisplayALert: boolean = false;
const notificationAction: NotificationActions = NotificationActions.MANUAL_SAVE;
const nonCommoditySteps: INonCommodityStep[] = [];
const selectedProducts: ProductData[] = [];
const subSessions: SessionState[] = [];
const nonCommodityProductData: ProductData = {
  all: {},
  productId: 0,
  hasAddOns: '',
  productName: '',
  business: '',
  name: '',
  productCode: '',
  deposit: { brutto: 0 },
  bonusSavings: 0,
  initialDuration: 0,
  initialDurationType: '',
  cmsDescriptionInternal: '',
  cmsDescriptionExternal: '',
  renewalDuration: 0,
  renewalDurationType: '',
  fixedPriceMonthsType: ''
};
const settings: ISetting = {
  alertSettings: {},
  notificationSettings: {},
  country: ''
};
const errors: any = {};
const flexbonus:
  | {
      id: number;
      data: {
        name: string;
        amount: string;
      };
    }[]
  | null = null;

export const store = new Vuex.Store({
  state: {
    errors,
    journey_session: state,
    subSessions,
    availableProducts,
    availableProviders,
    availableAddons,
    defaultAddons,
    flexbonus,
    notificationSettings,
    alertSettings,
    customAppIdle,
    isWaitingForRequest,
    modalMessages,
    displayModal,
    alertWidgetLoading,
    successModal,
    email,
    discountCode,
    discountValue,
    notifications,
    sessionIsSaved,
    notificationAction,
    sessionModalDisplayALert,
    idleVue: {
      isIdle: false
    },
    selectedProducts,
    externalDiscountValue: 0,
    discountSource: '',
    settings,
    nonCommodityProductData,
    nonCommoditySteps,
    orderId: null,
    isFleetFlow: false
  },
  getters: {
    getSelectedSubSessionProducts(state, getters): ProductData[] {
      const selectedProducts: ProductData[] = [];
      state.availableProducts.forEach((product: ProductData) => {
        const selectedProduct = getters.getProductIfSelected(product.productCode);
        if (selectedProduct) {
          selectedProducts.push(selectedProduct);
        }
      });
      return selectedProducts;
    },
    getSessionStateForSubmit(state, getters) {
      const subSessions: SessionState[] = [];
      state.availableProducts.forEach((product: ProductData) => {
        const subSessionToProduct = getters.getSubSessionByProductCode(product.productCode);
        const selectedProduct = getters.getProductIfSelected(product.productCode);

        if (selectedProduct && subSessionToProduct) {
          // Update the subSession
          subSessions.push(subSessionToProduct);
        } else if (selectedProduct && !subSessionToProduct) {
          // Create new sub session
          subSessions.push({
            sessionHash: null,
            productCode: product.productCode,
            externalId: '',
            masterSession: state.journey_session.sessionHash,
            campaign: state.journey_session.campaign,
            activeSubSession: true
          });
        } else if (!selectedProduct && subSessionToProduct) {
          // "Delete" the subSession (deactivate) can be simply readded when setting back to true
          subSessions.push({
            sessionHash: subSessionToProduct.sessionHash,
            masterSession: state.journey_session.sessionHash,
            activeSubSession: false
          });
        }
      });
      return {
        ...state.journey_session,
        extendedData: JSON.stringify(state.journey_session.extendedData),
        subSessions
      };
    },
    getProductIfSelected:
      (state) =>
      (productCode: string): ProductData | null | undefined => {
        if (state.selectedProducts.length > 0) {
          return state.selectedProducts.find((product) => {
            return product.productCode === productCode;
          });
        }
        return null;
      },
    getSubSessionByProductCode:
      (state) =>
      (productCode: string): SessionState | null | undefined => {
        if (state.subSessions.length > 0) {
          return state.subSessions.find((subSession: SessionState) => {
            return subSession.productCode === productCode;
          });
        }
        return null;
      },
    getErrors: (state) => {
      state.errors;
    },
    getSession: (state) => {
      return state.journey_session;
    },
    getSessionIsSaved: (state) => {
      return state.sessionIsSaved;
    },
    getSettings: (state) => {
      return state.settings;
    },
    getAvailableProducts: (state) => {
      return state.availableProducts;
    },
    getAvailableProviders: (state) => {
      return state.availableProviders;
    },
    getAvailableAddons: (state) => {
      return state.availableAddons;
    },
    getDefaultAddons: (state) => {
      return state.defaultAddons;
    },
    getSelectedProduct: (state) => {
      return state.availableProducts.filter((product) => {
        if (product) {
          return product.productCode === state.journey_session.productCode;
        }
        return false;
      })[0];
    },
    getB2BProduct: (state) => {
      const selectedProduct = state.nonCommodityProductData;
      return state.availableProducts.find(
        (product) =>
          product.productCode.toUpperCase().includes(selectedProduct.productCode.toUpperCase()) &&
          product.productCode.toUpperCase().includes('_B2B')
      );
    },
    getFlexbonus: (state) => {
      return state.flexbonus;
    },
    getNotificationSettings: (state) => {
      return state.notificationSettings;
    },
    getALertSettings: (state) => {
      return state.alertSettings;
    },
    getCustomAppIdleState: (state) => {
      return state.customAppIdle;
    },
    getIsWaitingForRequest: (state) => {
      return state.isWaitingForRequest;
    },
    getModalMessages: (state) => {
      return state.modalMessages;
    },
    getDisplayModal: (state) => {
      return state.displayModal;
    },
    getSuccessModal: (state) => {
      return state.successModal;
    },
    getAlertWidgetLoading: (state) => {
      return state.alertWidgetLoading;
    },
    getNotifications: (state) => {
      return state.notifications;
    },
    getEmail: (state) => {
      return state.email;
    },
    getDiscountCode: (state) => {
      return state.discountCode;
    },
    getDiscountValue: (state) => {
      return state.discountValue;
    },
    getDiscountSource: (state) => {
      return state.discountSource;
    },
    getNotificationAction: (state) => {
      return state.notificationAction;
    },
    getSessionModalDisplayALert: (state) => {
      return state.sessionModalDisplayALert;
    },
    getNonCommodityProduct: (state) => {
      return state.nonCommodityProductData;
    },
    getBillingStreet: (state) => {
      return state.journey_session.billingStreet;
    },
    getBillingZip: (state) => {
      return state.journey_session.billingZip;
    },
    getDateOfBirth: (state) => {
      return state.journey_session.dateOfBirth;
    },
    getSubSessions: (state) => {
      return state.subSessions;
    },
    getSelectedVehicleType: (state) => {
      const service = new ThgServiceElliDefault();
      if (state.journey_session.extendedData[service.extendedDataParentKey]) {
        return state.journey_session.extendedData[service.extendedDataParentKey][service.extendedDataKeyVehicleClasses];
      }
      return null;
    },
    getIsFleetFlow: (state) => {
      return state.isFleetFlow;
    }
  },
  actions: {
    setSession: ({ commit }, session) => {
      let sessionData = session;
      if (session.journeySession) {
        sessionData = session.journeySession;
      }
      commit('SET_SESSION', sessionData);
    },
    setErrors: ({ commit }, payload) => {
      commit('SET_ERRORS', payload);
    },
    resetErrors: ({ commit }) => {
      commit('SET_ERRORS', {});
    },
    setFieldValue: ({ commit }, payload) => {
      commit('SET_SESSION_FIELD', payload);
    },
    setNonCommodityProduct: ({ commit }, payload) => {
      commit('SET_NON_COMMODITY_PRODUCT', payload);
    },
    setAvailableProducts: ({ commit }, payload) => {
      commit('SET_AVAILABLE_PRODUCTS', payload);
    },
    setSelectProducts({ commit }, payload) {
      commit('SET_SELECT_PRODUCTS', payload);
    },
    addProduct({ commit }, payload) {
      commit('SET_ADD_PRODUCT', payload);
    },
    removeProduct({ commit }, payload) {
      commit('SET_REMOVE_PRODUCT', payload);
    },
    setSessionExtendedData({ commit }, payload) {
      let state: any = this.state.journey_session.extendedData;
      if (typeof state === 'string') {
        state = JSON.parse(state);
      }
      if (typeof payload === 'string') {
        payload = JSON.parse(payload);
      }
      commit('SET_SESSION_EXTENDED_DATA', lodash.merge(state, payload));
    },
    setContactData({ commit }, data) {
      const contactData: any = {};
      contactData.company = data.company ? data.company : null;
      contactData.business = data.business ? data.business : false;
      contactData.salutation = data.salutation ? data.salutation : null;
      contactData.firstName = data.firstName ? data.firstName : null;
      contactData.customerAuthUserName = data.customerAuthUserName ? data.customerAuthUserName : null;
      contactData.customerAuthPassword = data.customerAuthPassword ? data.customerAuthPassword : null;
      contactData.surname = data.surname ? data.surname : null;
      contactData.dateOfBirth = data.dateOfBirth ? data.dateOfBirth : null;
      contactData.phoneMobileAreaCode = data.phoneMobileAreaCode ? data.phoneMobileAreaCode : null;
      contactData.phoneMobile = data.phoneMobile ? data.phoneMobile : null;
      contactData.phoneHomeAreaCode = data.phoneHomeAreaCode ? data.phoneHomeAreaCode : null;
      contactData.phoneHome = data.phoneHome ? data.phoneHome : null;
      contactData.phoneBusinessAreaCode = data.phoneBusinessAreaCode ? data.phoneBusinessAreaCode : null;
      contactData.phoneBusiness = data.phoneBusiness ? data.phoneBusiness : null;
      contactData.emailPrivate = data.emailPrivate ? data.emailPrivate : null;
      contactData.emailBusiness = data.emailBusiness ? data.emailBusiness : null;
      contactData.street = data.street ? data.street : null;
      contactData.houseNumber = data.houseNumber ? data.houseNumber : null;
      contactData.billingAlternativeAddress = data.billingAlternativeAddress ? data.billingAlternativeAddress : null;
      contactData.billingCompany = data.billingCompany ? data.billingCompany : null;
      contactData.billingSalutation = data.billingSalutation ? data.billingSalutation : null;
      contactData.billingFirstName = data.billingFirstName ? data.billingFirstName : null;
      contactData.billingSurname = data.billingSurname ? data.billingSurname : null;
      contactData.billingStreet = data.billingStreet ? data.billingStreet : null;
      contactData.billingHouseNumber = data.billingHouseNumber ? data.billingHouseNumber : null;
      contactData.billingZip = data.billingZip ? data.billingZip : null;
      contactData.billingCity = data.billingCity ? data.billingCity : null;
      contactData.billingPhoneAreaCode = data.billingPhoneAreaCode ? data.billingPhoneAreaCode : null;
      contactData.billingPhone = data.billingPhone ? data.billingPhone : null;
      contactData.billingFaxAreaCode = data.billingFaxAreaCode ? data.billingFaxAreaCode : null;
      contactData.billingFax = data.billingFax ? data.billingFax : null;
      contactData.billingEmail = data.billingEmail ? data.billingEmail : null;
      contactData.vpCode = data.vpCode ? data.vpCode : null;
      contactData.uvpCode = data.uvpCode ? data.uvpCode : null;
      contactData.externalId = data.externalId ? data.externalId : null;
      contactData.recommendingContractId = data.recommendingContractId || null;
      contactData.customerId = data.customerId || null;
      contactData.extendedData =
        typeof data.extendedData === 'string' ? JSON.parse(data.extendedData || '{}') : data.extendedData || {};
      contactData.additionalData = data.additionalData || null;

      commit('SET_SESSION', contactData);
      commit('SET_SESSION_IS_SAVED', false);
    },
    setSubSessions({ commit }, payload) {
      commit('SET_SUB_SESSIONS', payload);
    },
    addSubSessions({ commit }, payload) {
      commit('SET_ADD_SUB_SESSION', payload);
    },
    removeSubSessions({ commit }, payload) {
      commit('SET_REMOVE_SUB_SESSION', payload);
    },
    removeSubSessionsByProductCode({ commit }, payload) {
      commit('SET_REMOVE_SUB_SESSION_BY_PRODUCT_CODE', payload);
    },
    updateSubSessions({ commit }, payload: SessionState) {
      let subSession = this.state.subSessions.find((subSession) => subSession.sessionHash === payload.sessionHash);
      if (subSession) {
        return commit('SET_UPDATE_SUB_SESSION', payload);
      }
      subSession = this.state.subSessions.find((subSession) => subSession.productCode === payload.productCode);
      if (subSession) {
        return commit('SET_UPDATE_SUB_SESSION_BY_PRODUCT_CODE', payload);
      }
      commit('SET_ADD_SUB_SESSION', payload);
    },
    setEmail({ commit }, email) {
      commit('SET_EMAIL', email);
      commit('SET_SESSION_IS_SAVED', false);
    },
    setDiscountCode({ commit }, discountCode) {
      commit('SET_DISCOUNT_CODE', discountCode);
      commit('SET_SESSION_IS_SAVED', false);
    },
    setDiscountValue({ commit }, discountValue) {
      commit('SET_DISCOUNT_VALUE', discountValue);
      commit('SET_SESSION_IS_SAVED', false);
    },
    setPaymentData({ commit }, data) {
      commit('SET_SESSION', data);
      commit('SET_SESSION_IS_SAVED', false);
    },
    setSelectedProduct({ commit }, data) {
      commit('SET_SESSION', data);
      commit('SET_SESSION_IS_SAVED', false);
    },
    setFlexbonus({ commit }, data) {
      commit('SET_FLEXBONUS', data);
      commit('SET_SESSION_IS_SAVED', false);
    },
    saveSession({ commit }, data) {
      commit('WAIT_FOR_REQUEST');
      const session = this.state.journey_session;
      session.emailPrivate = data.email;
      ApiProvider.sessionUpdate(session)
        .then((res) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(res);
          }
          commit('SET_SESSION_MODAL_DISPLAY_ALERT', true);
          commit('SET_SESSION_IS_SAVED', true);
          ApiProvider.activateSessionReminder(data.sessionURL, data.email)
            .then((res) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log(res);
              }
              commit('SET_ALERT_WIDGET_LOADING', false);
              setTimeout(() => {
                commit('SET_CUSTOM_IDLE_STATE', false);
              }, 3000);
            })
            .catch((err) => {
              if (process.env.NODE_ENV !== 'production') {
                console.log(err);
              }
              commit('SET_ALERT_WIDGET_LOADING', false);
            });
        })
        .catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(err);
          }
          commit('SET_SESSION_MODAL_DISPLAY_ALERT', true);
          commit('SET_SESSION_IS_SAVED', false);
        });
    },
    setCustomAppIdleState({ commit }, data) {
      if (this.state.idleVue.isIdle) {
        commit('SET_NOTIFICATION_ACTION', NotificationActions.IDLE);
      }
      const email = this.state.journey_session.emailPrivate
        ? this.state.journey_session.emailPrivate
        : this.state.journey_session.emailBusiness
        ? this.state.journey_session.emailBusiness
        : '';
      commit('SET_EMAIL', email);
      commit('SET_CUSTOM_IDLE_STATE', data);
    },
    setDisplayModal({ commit }, data) {
      commit('SET_DISPLAY_MODAL', data);
    },
    setAlertWidgetLoading({ commit }, data) {
      commit('SET_ALERT_WIDGET_LOADING', data);
    },
    addNotification({ commit }, data) {
      const notifications = this.state.notifications.push(data);
      commit('SET_NOTIFICATIONS', notifications);
    },
    removeNotification({ commit }, data) {
      const notifications = this.state.notifications.filter((item) => {
        item.id !== data.id;
      });
      commit('SET_NOTIFICATIONS', notifications);
    },
    setNotificationAction({ commit }, data) {
      commit('SET_NOTIFICATION_ACTION', data);
    },
    setSessionModalDisplayAlert({ commit }, data: boolean) {
      commit('SET_SESSION_MODAL_DISPLAY_ALERT', data);
    },
    setSessionIsSaved({ commit }, data: boolean) {
      commit('SET_SESSION_IS_SAVED', data);
    },
    setOrderId({ commit }, data: string) {
      commit('SET_ORDER_ID', data);
    }
  },
  mutations: {
    SET_ORDER_ID: (state, data) => {
      state.orderId = data;
    },
    SET_ERRORS: (state, errors) => {
      state.errors = errors;
    },
    SET_SESSION_FIELD: (state, data) => {
      state.journey_session[data.field] = data.value;
    },
    SET_SESSION_IS_SAVED: (state, data: boolean) => {
      state.sessionIsSaved = data;
    },
    SET_EMAIL: (state, email) => {
      state.email = email;
    },
    SET_DISCOUNT_CODE: (state, discountCode) => {
      state.discountCode = discountCode;
    },
    SET_DISCOUNT_VALUE: (state, discountValue) => {
      state.discountValue = discountValue;
    },
    SET_SESSION: (state, session) => {
      if (session.extendedData) {
        let extendedData = session.extendedData;
        if (typeof session.extendedData === 'string') {
          extendedData = JSON.parse(session.extendedData);
        }
        session.extendedData = lodash.merge(state.journey_session.extendedData, extendedData);
      }
      Vue.set(state, 'journey_session', Object.assign({}, state.journey_session, session));
    },
    SET_SETTINGS: (state, settings) => {
      Vue.set(state, 'settings', Object.assign({}, state.settings, settings));
    },
    SET_AVAILABLE_PRODUCTS: (state, products) => {
      state.availableProducts = products;
    },
    SET_AVAILABLE_PROVIDERS: (state, providers) => {
      Vue.set(state, 'availableProviders', providers);
    },
    SET_AVAILABLE_ADDONS: (state, addons) => {
      Vue.set(state, 'availableAddons', addons);
    },
    SET_DEFAULT_ADDONS: (state, addons) => {
      Vue.set(state, 'defaultAddons', addons);
    },
    SET_FLEXBONUS: (state, flexbonus) => {
      Vue.set(state, 'flexbonus', flexbonus);
    },
    SET_CUSTOM_IDLE_STATE: (state, data) => {
      Vue.set(state, 'customAppIdle', data);
    },
    WAIT_FOR_REQUEST: (state) => {
      Vue.set(state, 'isWaitingForRequest', true);
    },
    SET_SUCCESS_MODAL: (state, status) => {
      Vue.set(state, 'successModal', status);
    },
    DISPLAY_MODAL_MESSAGES: (state, message) => {
      Vue.set(state, 'modalMessages', message);
      Vue.set(state, 'isWaitingForRequest', false);
    },
    SET_DISPLAY_MODAL: (state, data) => {
      Vue.set(state, 'displayModal', data);
    },
    SET_ALERT_WIDGET_LOADING: (state, data) => {
      Vue.set(state, 'alertWidgetLoading', data);
    },
    SET_NOTIFICATIONS: (state, data) => {
      Vue.set(state, 'notifications', data);
    },
    SET_NOTIFICATION_ACTION: (state, data) => {
      Vue.set(state, 'notificationAction', data);
    },
    SET_SESSION_MODAL_DISPLAY_ALERT: (state, data: boolean) => {
      Vue.set(state, 'displaySessionModalAlert', data);
    },
    SET_NON_COMMODITY_PRODUCT: (state, data) => {
      Vue.set(state, 'nonCommodityProductData', data);
      Vue.set(state, 'productCode', data.productCode);
      state.journey_session.productCode = data.productCode;
    },
    SET_SESSION_EXTENDED_DATA: (state, data) => {
      state.journey_session.extendedData = data;
    },
    SET_SELECT_PRODUCTS: (state, data: ProductData[]) => {
      state.selectedProducts = data;
    },
    SET_EXTERNAL_DISCOUNT: (state, data) => {
      state.externalDiscountValue = data;
    },
    SET_DISCOUNT_SOURCE: (state, data) => {
      state.discountSource = data;
    },
    SET_REMOVE_PRODUCT: (state, data: ProductData) => {
      state.selectedProducts = state.selectedProducts.filter((item) => item.productCode !== data.productCode);
    },
    SET_ADD_PRODUCT: (state, data: ProductData) => {
      state.selectedProducts.push(data);
    },
    SET_SUB_SESSIONS: (state, data: SessionState[]) => {
      state.subSessions = data;
    },
    SET_ADD_SUB_SESSION: (state, data: SessionState) => {
      state.subSessions.push(data);
    },
    SET_REMOVE_SUB_SESSION: (state, data: SessionState) => {
      state.subSessions = state.subSessions.filter((item) => item.sessionHash !== data.sessionHash);
    },
    SET_REMOVE_SUB_SESSION_BY_PRODUCT_CODE: (state, data: SessionState) => {
      state.subSessions = state.subSessions.filter((item) => item.productCode !== data.productCode);
    },
    SET_UPDATE_SUB_SESSION: (state, data: SessionState) => {
      state.subSessions = state.subSessions.map((session) => {
        if (session.sessionHash === data.sessionHash) {
          return { ...session, ...data };
        }
        return session;
      });
    },
    SET_UPDATE_SUB_SESSION_BY_PRODUCT_CODE: (state, data: SessionState) => {
      state.subSessions = state.subSessions.map((session) => {
        if (session.productCode === data.productCode) {
          return { ...session, ...data };
        }
        return session;
      });
    },
    SET_IS_FLEET_FLOW: (state, data: boolean) => {
      state.isFleetFlow = data;
    }
  }
});
