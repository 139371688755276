export enum NotificationTypes {
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface INotification {
  id?: number;
  message?: string;
  type?: NotificationTypes;
}

export interface IAlertSettings {
  timeoutAlertJourneyActive?: boolean;
  timeoutAlertJourneyOnClose?: boolean;
  timeoutAlertJourneyOnLeave?: boolean;
  timeoutAlertJourneyOnIdle?: boolean;
  timeoutAlertJourneyDurationSeconds?: number;
  timeoutAlertJourneyReminderElement?: boolean;
}

export interface INotificationSettings {
  notificationApiEndpoint?: string;
  notificationSaveMailWithoutPermission?: boolean;
}

export enum NotificationActions {
  MANUAL_SAVE = 'MANUAL_SAVE',
  IDLE = 'IDLE',
  LEAVE = 'LEAVE'
}
