











import { NotificationActions } from '@/interfaces/Notification';

import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
@Component({})
export default class ReminderWidget extends Basic {
  public saveSession(): void {
    this.store.dispatch('setNotificationAction', NotificationActions.MANUAL_SAVE);
    this.store.dispatch('setCustomAppIdleState', true);
  }
}
