import { injectable } from 'inversify';
import i18n from '@/i18n';
import LanguageProviderInterface from '@/interfaces/Language';

@injectable()
class LanguageProvider implements LanguageProviderInterface {

    public has(key: string): boolean {
        return i18n.te(key);
    }

    public trans(key: string): string {
        return i18n.t(key).toLocaleString();
    }

}

export default LanguageProvider;
