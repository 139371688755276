


































































































































import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { NotificationActions } from '@/interfaces/Notification';
@Component({
  props: {
    sessionParam: {
      type: String,
      default: 'session'
    }
  }
})
export default class AlertWidget extends Basic {
  NotificationActions = NotificationActions;
  get customIsAppIdle(): boolean {
    return this.store.getters.getCustomAppIdleState;
  }
  get notificationAction(): string {
    return this.store.getters.getNotificationAction;
  }
  get email() {
    return this.store.getters.getEmail;
  }
  get sessionIsSaved(): boolean {
    return this.store.getters.getSessionIsSaved;
  }

  get sessionModalDisplayAlert(): boolean {
    return this.store.getters.getSessionModalDisplayAlert;
  }

  get getAlertWidgetLoading(): boolean {
    return this.store.getters.getAlertWidgetLoading;
  }
  public saveSession(): void {
    const email = this.store.getters.getEmail
      ? this.store.getters.getEmail
      : this.store.getters.getSession.emailPrivate;
    const sessionParam = this.$props.sessionParam;

    this.store.dispatch('setAlertWidgetLoading', true);
    const currentURL = new URL(window.location.toString());
    currentURL.searchParams.set(sessionParam, localStorage.getItem(process.env.VUE_APP_SESSION_STORAGE_KEY) || '');
    this.store.dispatch('saveSession', {
      email: email,
      sessionURL: currentURL.toString()
    });
  }

  public stopIdle(): void {
    this.store.dispatch('setCustomAppIdleState', false);
  }

  @Watch('isAppIdle')
  public onIsAppIdleChanged(newValue: boolean): void {
    if (newValue) {
      this.store.dispatch('setCustomAppIdleState', true);
    }
  }
}
