
import Prototype from './Prototype.vue';
import Component, { mixins } from 'vue-class-component';
import i18n from '@/i18n';
import baseProps from '@/baseProps';
import Vue from 'vue';

@Component({
  props: { ...baseProps },
  i18n
})
export default class CommodityPrototype extends mixins(Vue, Prototype) {
  public async setUpSession(): Promise<void> {
    return this.readSession().then(() => {
      if (!localStorage.getItem(process.env.VUE_APP_SESSION_STORAGE_KEY)) {
        if (this.currentClient !== 'sachsenenergie') {
          window.location.href = window.location.origin;
        }
      }
    });
  }

  public loadApp(): void {
    this.data.redirect = this.$props.redirect;
    this.loadSessionState();
    this.loadProducts();
    this.loadAPIProviders();
    this.loadSettings();
  }

  public loadAPIProviders(): void {
    this.ApiProvider.getProviders()
      .then((response) => {
        this.store.commit('SET_AVAILABLE_PROVIDERS', response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.error(error.response.status, error.response.data.message);
        } else {
          console.error(error);
        }
      });
  }

  public loadProducts(): void {
    this.ApiProvider.getProducts()
      .then((response) => {
        this.store.commit(
          'SET_AVAILABLE_PRODUCTS',
          response.data.map((product) => {
            product.initialDurationType = this.getDurationType(product.initialDurationType, product.initialDuration);
            product.renewalDurationType = this.getDurationType(product.renewalDurationType, product.renewalDuration);
            product.cancellationPeriodDaysType = this.getDurationType(
              product.cancellationPeriodDaysType,
              product.cancellationPeriodDays
            );
            product.fixedPriceMonthsType = this.getDurationType('MONTHS', product.fixedPriceMonths);
            return product;
          })
        );
        this.data.loadingProducts = false;
        this.loadAddons();
      })
      .catch((error) => {
        console.error(error.response.status, error.response.data.message);
      })
      .finally(() => {
        this.deactivateSpinnerActive();
      });
  }

  public loadSessionState(): void {
    this.ApiProvider.sessionRead()
      .then((response) => {
        this.store.commit('SET_SESSION', response.data);

        if (!this.enableFastNavigation) {
          return true;
        }

        if (this.stepsOrder) {
          this.stepsOrder.forEach((item) => {
            if (response.data[item.field]) {
              this.widgetsList.disabled[item.widget] = false;
              if (item.validate) {
                this.switchValidatedWidget(item.widget);
              } else {
                this.switchWidget(item.widget);
              }
            }
          });
        } else {
          // enable fast navigation if data already set
          if (response.data.productCode) {
            this.widgetsList.disabled.contract = false;
            // this.data.selectedProduct = this.store.getters.getAvailableProducts.filter(
            //     (product) => {
            //       return product.productCode === this.data.sessionState.productCode;
            //     },
            // )[0];
            this.switchWidget('contract');
            if (response.data.firstName) {
              this.widgetsList.disabled.delivery = false;
              this.switchValidatedWidget('delivery');
              if (response.data.customerSpecification) {
                this.widgetsList.disabled.payment = false;
                this.switchValidatedWidget('payment');
                if (typeof response.data.directDebit !== 'undefined') {
                  this.widgetsList.disabled.rules = false;
                  this.switchValidatedWidget('rules');
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        if (typeof error !== 'undefined' && typeof error.response !== 'undefined') {
          if (
            typeof error.response.data.error !== 'undefined' &&
            error.response.data.error.message === 'session outdated'
          ) {
            localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
            window.location.href = document.referrer || window.location.origin;
          }
        }
      });
  }
}
