import 'reflect-metadata';
import { Container } from 'inversify';
import SERVICES from './Services';

// Interfaces
import Api from '../interfaces/Api';
import Bonus from '../interfaces/Bonus';
import Format from '../interfaces/Format';
import Tracking from '../interfaces/Tracking';
import AddOn from '@/interfaces/AddOn';
import Language from '@/interfaces/Language';
import Url from '@/interfaces/Url';

// Providers
import ApiProvider from '../providers/ApiProvider';
import BonusProvider from '../providers/BonusProvider';
import FormatProvider from '../providers/FormatProvider';
import TrackingProvider from '../providers/TrackingProvider';
import AddOnProvider from '@/providers/AddOnProvider';
import LanguageProvider from '@/providers/LanguageProvider';
import UrlProvider from '@/providers/UrlProvider';

// Container
const iocContainer = new Container();

// Bind Providers to Interfaces
iocContainer.bind<Api>(SERVICES.API).to(ApiProvider);
iocContainer.bind<Bonus>(SERVICES.BONUS).to(BonusProvider);
iocContainer.bind<Format>(SERVICES.FORMAT).to(FormatProvider);
iocContainer.bind<Tracking>(SERVICES.TRACKING).to(TrackingProvider);
iocContainer.bind<AddOn>(SERVICES.ADDON).to(AddOnProvider);
iocContainer.bind<Language>(SERVICES.LANGUAGE).to(LanguageProvider);
iocContainer.bind<Url>(SERVICES.URL).to(UrlProvider);

export default iocContainer;
