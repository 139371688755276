import ProductData from '@/interfaces/ProductData';
import SessionState from '@/interfaces/SessionState';
import { store } from '@/store';
import _ from 'lodash';

export abstract class ThgBaseService {
  protected store = store;

  public extendedDataParentKey: string = 'THG';

  public customerType: string = '';
  public vehicleType: string = '';
  public keyCustomerTypeBusiness = 'business';
  public keyCustomerTypeNone = '';
  public keyCustomerTypePrivate = 'private';

  // single fields
  public extendedDataKeyPartnerNumber: string = 'PARTNER_NUMBER';
  public extendedDataKeyPartnerNumberMulti: string = 'PARTNER_NUMBER_MULTI';
  public extendedDataKeyLastStatusUpdates: string = 'LAST_STATUS_UPDATES';
  public extendedDataKeyDateTermsConfirmation: string = 'DATE_TERMS_CONFIRMATION';
  public extendedDataKeyDatesTermsConfirmation: string = 'DATES_TERMS_CONFIRMATION';
  public extendedDataKeyVehicleIds: string = 'VEHICLE_IDS';
  public extendedDataKeyVehicleOwners: string = 'VEHICLE_OWNERS';
  public extendedDataKeyProvisionContractMulti: string = 'PROVISIONCONTRACT_MULTI';
  public extendedDataKeyVatId: string = 'VAT_ID';
  public extendedDataKeyVatIdMultiYear: string = 'VAT_ID_MULTIYEAR';
  public extendedDataKeyTaxNumber: string = 'TAX_NUMBER';
  public extendedDataKeyTaxNumberMulti: string = 'TAX_NUMBER_MULTI';
  public extendedDataKeySkipRevocationPeriods: string = 'SKIP_REVOCATION_PERIODS';

  // Weiteres Feld, das aktuell dazu genutzt wird,
  // dass der Auftrag in der Powercloud manuell bestätigt werden muss. Muss für Dealer immer TRUE sein
  public extendedDataKeyForceManualClearing: string = 'FORCE_MANUAL_CLEARING';

  protected abstract getExtendedDataFields(): Record<any, any>;

  /**
   * Override this if needed, e.g. for multi-year fields
   */
  protected modifyExtendedDataDefaultValues(fields: Record<any, any>): Record<any, any> {
    return fields;
  }
  public get sessionState(): SessionState {
    return this.store.getters.getSession;
  }

  public setExtendedDataDefaultValues(): void {
    let fields: Record<any, any> = this.getExtendedDataFields();
    fields = this.modifyExtendedDataDefaultValues(fields);

    this.store.state.journey_session.extendedData = _.merge(fields, this.store.state.journey_session.extendedData);
    this.store.commit('SET_SESSION', this.sessionState);
  }

  public get extendedData(): Record<any, any> {
    if (this.store.state.journey_session.extendedData == null) {
      this.setExtendedDataDefaultValues();
    }
    return this.store.state.journey_session.extendedData;
  }

  public getExtendedDataFieldValue(fieldKey: string): any {
    return this.extendedData[this.extendedDataParentKey][fieldKey];
  }

  public setExtendedDataFieldValue(fieldKey: string, value: any): void {
    this.extendedData[this.extendedDataParentKey][fieldKey] = value;
    this.store.dispatch('setSessionExtendedData', {
      [this.extendedDataParentKey]: {
        [fieldKey]: value
      }
    });
  }
  public isEco(product: ProductData): boolean {
    return product.productCode.toLowerCase().includes('bonus');
  }

  public get products(): ProductData[] {
    const products = this.store.state.availableProducts;
    return products.length > 0 ? this.putEcoInFront(products) : [];
  }

  private putEcoInFront(products: ProductData[]): ProductData[] {
    const ecoIndex = products.findIndex(this.isEco);

    if (ecoIndex !== -1) {
      return products;
    }

    const ecoProduct = products[ecoIndex];
    const ecoInFront = products.slice(ecoIndex, 1);
    ecoInFront.unshift(ecoProduct);

    return products;
  }

  public get isCustomerTypePrivate(): boolean {
    return this.customerType == this.keyCustomerTypePrivate;
  }

  public get isCustomerTypeBusiness(): boolean {
    return this.customerType == this.keyCustomerTypeBusiness;
  }
  public setCustomerTypeBusiness(): void {
    this.customerType = this.keyCustomerTypeBusiness;
  }

  public setCustomerTypePrivate(): void {
    this.customerType = this.keyCustomerTypePrivate;
  }
}
