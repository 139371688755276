import { ThgBaseService } from '@/models/thg/abstract/ThgBaseService';
import moment from 'moment';
import NonCommodityProductFacade from '../NonCommodityProductFacade';
import VehicleType from '../VehicleType';
import Format from '@/interfaces/Format';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';
import _ from 'lodash';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

export default class ThgServiceElliDefault extends ThgBaseService {
  private isExtendedDataInitialized: boolean = false;
  private static singleton: ThgServiceElliDefault;
  public extendedDataParentKey: string = 'THG_MULTIYEAR';

  // Multi fields
  public extendedDataKeyCurrentStates: string = 'currentStates';
  public extendedDataKeyVoucherCodes: string = 'VOUCHER_CODES';
  public extendedDataKeyPricingProductVersions: string = 'PRICING_PRODUCT_VERSIONS';
  public extendedDataKeyThgPrices: string = 'THG_PRICES';
  public extendedDataKeyDatesUbaResponse: string = 'DATES_UBA_RESPONSE';

  // single fields
  public extendedDataKeyDateKfzDocValidation: string = 'DATES_KFZ_DOC_VALIDATION';
  public extendedDataKeyDatesVehicleCreatedGreenTrax: string = 'DATES_VEHICLE_CREATED_GT';
  public extendedDataKeyStatusInfo: string = 'STATUS_INFOS';
  public extendedDataKeyVehicleClasses: string = 'VEHICLE_CLASSES_WD';
  public extendedDataKeyLicensePlates: string = 'LICENSE_PLATES';

  public static vehicleTypes: VehicleType[] = [
    {
      identifier: 'l1e',
      title: 'noncommodity.product.vehicle.type.l1e.label',
      class: 'noncommodity.product.vehicle.type.l1e.class'
    },
    {
      identifier: 'm3',
      title: 'noncommodity.product.vehicle.type.m3.label',
      class: 'noncommodity.product.vehicle.type.m3.class'
    },
    {
      identifier: 'm1',
      title: 'noncommodity.product.vehicle.type.m1.label',
      class: 'noncommodity.product.vehicle.type.m1.class',
      isHighlighted: true
    },
    {
      identifier: 'n1',
      title: 'noncommodity.product.vehicle.type.n1.label',
      class: 'noncommodity.product.vehicle.type.n1.class'
    },
    {
      identifier: 'n2',
      title: 'noncommodity.product.vehicle.type.n2.label',
      class: 'noncommodity.product.vehicle.type.n2.class'
    },
    {
      identifier: 'n3',
      title: 'noncommodity.product.vehicle.type.n3.label',
      class: 'noncommodity.product.vehicle.type.n3.class'
    }
  ];

  protected getExtendedDataFieldsMultiDefaultValues(): Record<any, any> {
    return {
      [this.extendedDataKeyThgPrices]: 0.0,
      [this.extendedDataKeyCurrentStates]: 'pending'
    };
  }

  /**
   * This functions returns all fields which hold an array, some extended data fields are single values and not multi year values
   * @returns array
   */
  public getExtendedDataFieldsMulti(): Record<any, any> {
    return {
      [this.extendedDataParentKey]: {
        [this.extendedDataKeyCurrentStates]: [],
        [this.extendedDataKeyVoucherCodes]: [],
        [this.extendedDataKeyPricingProductVersions]: [],
        [this.extendedDataKeyThgPrices]: [],
        [this.extendedDataKeyDatesUbaResponse]: []
      }
    };
  }

  public getExtendedDataFields(): Record<any, any> {
    const singleFields: Record<any, any> = {
      [this.extendedDataParentKey]: {
        [this.extendedDataKeyDateKfzDocValidation]: '',
        [this.extendedDataKeyDatesVehicleCreatedGreenTrax]: '',
        [this.extendedDataKeyStatusInfo]: '',
        [this.extendedDataKeyVehicleClasses]: '',
        [this.extendedDataKeyLicensePlates]: '',
        [this.extendedDataKeyPartnerNumberMulti]: '',
        [this.extendedDataKeyLastStatusUpdates]: '',
        [this.extendedDataKeyVehicleIds]: '',
        [this.extendedDataKeyVehicleOwners]: '',
        [this.extendedDataKeyProvisionContractMulti]: '',
        [this.extendedDataKeyDatesTermsConfirmation]: '',
        [this.extendedDataKeyTaxNumberMulti]: '',
        [this.extendedDataKeyVatIdMultiYear]: '',
        [this.extendedDataKeySkipRevocationPeriods]: 'false'
      }
    };
    return _.merge(singleFields, this.getExtendedDataFieldsMulti());
  }

  protected modifyExtendedDataDefaultValues(fields: Record<any, any>): Record<any, any> {
    const years = this.isExtendedDataInitialized ? this.selectedYearArray : [];
    const modifiedFields = this.updateMultiYearValues(fields, years);
    return modifiedFields;
  }

  protected updateMultiYearValues(fields: Record<any, any>, years: string[]): Record<any, any> {
    const multiFields: Record<any, any> = this.getExtendedDataFieldsMulti();
    for (const multiFieldKey in multiFields[this.extendedDataParentKey]) {
      const legitValues: Record<any, any> = [];
      for (const existingYear of years) {
        for (const existingData of fields[this.extendedDataParentKey][multiFieldKey]) {
          if (existingData.year == existingYear) {
            legitValues.push(existingData);
          }
        }
        fields[this.extendedDataParentKey][multiFieldKey] = legitValues;
      }
    }

    if (years.length == 0 || years[0].length == 0) {
      return fields;
    }

    return this.applyDefaultValuesToMultiYearFields(fields, years);
  }

  protected applyDefaultValuesToMultiYearFields(fields: Record<any, any>, years: string[]): Record<any, any> {
    const multiFieldDefaultValues: Record<any, any> = this.getExtendedDataFieldsMultiDefaultValues();
    const multiFields: Record<any, any> = this.getExtendedDataFieldsMulti();

    for (const multiFieldKey in multiFields[this.extendedDataParentKey]) {
      if (fields[this.extendedDataParentKey][multiFieldKey]) {
        for (const year of years) {
          let found = false;

          for (const existingData of fields[this.extendedDataParentKey][multiFieldKey]) {
            if (existingData.year == year) {
              found = true;
              break;
            }
          }

          if (!found) {
            fields[this.extendedDataParentKey][multiFieldKey].push({
              year: year,
              value: multiFieldDefaultValues[multiFieldKey] ?? ''
            });
          }
        }
      }
    }

    return fields;
  }

  get selectedYearArray(): string[] {
    return this.availableYears.length == 1
      ? this.availableYears.map((year) => year.toString())
      : this.selectedYear.length > 0
      ? this.selectedYear.split(',')
      : [];
  }

  get selectedYear(): string {
    this.isExtendedDataInitialized = false;
    const multiFields: Record<any, any> = this.getExtendedDataFieldsMulti()[this.extendedDataParentKey];

    const selectedYears: string[] = [];
    const firstMultiField: string = Object.keys(multiFields)[0];

    if (
      this.extendedData &&
      this.extendedData[this.extendedDataParentKey] &&
      this.extendedData[this.extendedDataParentKey][firstMultiField]
    ) {
      for (const yearData of this.extendedData[this.extendedDataParentKey][firstMultiField]) {
        selectedYears.push(yearData.year);
      }
    }
    if (selectedYears.length === 0) {
      const now = new Date();
      selectedYears.push(now.getFullYear().toString());
    }
    this.isExtendedDataInitialized = true;
    return selectedYears.join(',');
  }

  set selectedYear(value: string) {
    const updatedFields: Record<any, any> = this.updateMultiYearValues(this.extendedData, ('' + value).split(','))[
      this.extendedDataParentKey
    ];

    this.extendedData[this.extendedDataParentKey] = updatedFields;
    this.store.dispatch('setSessionExtendedData', {
      [this.extendedDataParentKey]: updatedFields
    });
  }
  public get multipleYearsSelected(): boolean {
    return this.selectedYearArray.length > 1;
  }

  public get enabledVehicleTypes(): VehicleType[] {
    return NonCommodityProductFacade.getEnabledVehicleTypes(
      this.products,
      this.isCustomerTypeBusiness,
      this.selectedYearArray
    );
  }

  get availableYears(): number[] {
    const currentYear = parseInt(moment().format('YYYY'));
    const availableYears: number[] = NonCommodityProductFacade.getAvailableYears(
      this.products,
      this.isCustomerTypeBusiness
    );

    return availableYears.filter((year) => year >= currentYear - 1 && year <= currentYear);
  }

  public static get instance(): ThgServiceElliDefault {
    if (ThgServiceElliDefault.singleton == null) {
      ThgServiceElliDefault.singleton = new ThgServiceElliDefault();
    }
    return ThgServiceElliDefault.singleton;
  }

  public static get extendedDataFields(): Record<any, any> {
    return this.instance.getExtendedDataFields();
  }
  public getFormattedPriceForVehicleType(vehicleType: VehicleType): string {
    if (this.isCustomerTypeBusiness) {
      return formatProvider.euro()(vehicleType.netPrice);
    }
    return formatProvider.euroFull()(vehicleType.bruttoPrice);
  }
  public setVehicleType(identifier: string): boolean {
    if (!this.isCustomerTypePrivate && !this.isCustomerTypeBusiness) {
      return false;
    }

    this.vehicleType = identifier;
    this.setExtendedDataFieldValue(this.extendedDataKeyVehicleClasses, identifier);

    return true;
  }

  public get selectedVehicleTypeIdentifier(): string {
    return this.vehicleType;
  }
}
