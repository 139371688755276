























































































import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import IocContainer from '../../../container/IocContainer';
import SERVICES from '../../../container/Services';

import Format from '../../../interfaces/Format';
import Tracking from '@/interfaces/Tracking';
import AddOn from '@/interfaces/AddOn';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);
const addOnProvider = IocContainer.get<AddOn>(SERVICES.ADDON);

@Component({
  props: {
    product: Object,
    availableAddons: Array
  },
  filters: {
    date: formatProvider.date(),
    euro: formatProvider.euro(),
    euroFull: formatProvider.euroFull()
  }
})
export default class ProductInfoWidget extends Basic {
  get parentIsProductWidget(): boolean {
    return this.$parent.$options.name === 'ProductWidget';
  }

  protected get quantityUnits(): Record<any, any> {
    const quantityUnits = {
      day: this.$t('day'),
      month: this.$t('month'),
      quarter: this.$t('quarter'),
      year: this.$t('year'),
      yearAverage: this.$t('year.average'),
      kWh: 'kWh',
      GJ: 'GJ',
      KW: 'KW',
      kvarh: 'kvarh',
      MJ: 'MJ'
    };
    return quantityUnits;
  }

  protected getLocalizedDurationString(duration: number, durationType: string): string {
    let durationTypeFormatted = durationType;

    if (durationType === 'days') {
      if (duration === 1) {
        durationTypeFormatted = this.$t('day').toString();
      } else {
        durationTypeFormatted = this.$t('days').toString();
      }
    } else if (durationType === 'months') {
      if (duration === 1) {
        durationTypeFormatted = this.$t('month').toString();
      } else {
        durationTypeFormatted = this.$t('months').toString();
      }
    } else if (durationType === 'years') {
      if (duration === 1) {
        durationTypeFormatted = this.$t('year').toString();
      } else {
        durationTypeFormatted = this.$t('years').toString();
      }
    } else if (duration === 0) {
      durationTypeFormatted = this.$t('days').toString();
    }

    let durationString = duration + ' ' + durationTypeFormatted;

    return durationString;
  }

  protected getQuantityUnit(tariffItem: Record<any, any>): string {
    if (tariffItem && tariffItem.quantityUnit && this.quantityUnits[tariffItem.quantityUnit]) {
      return this.quantityUnits[tariffItem.quantityUnit];
    }
    return '';
  }

  protected getUnitPriceFormatted(tariffItem: Record<any, any>): any {
    let price: any = '' + tariffItem.unitPrice;

    if (tariffItem && tariffItem.unitPriceUnit === 'currencyUnit') {
      price = price.replace('.', ',') + ' ' + this.$t('currencyText');
    }

    return price;
  }

  get primaryAddons(): [] {
    return this.$props.defaultAddons;
  }

  get addons(): [] | null {
    if (
      this.$props.product !== null &&
      typeof this.$props.product.addOns !== 'undefined' &&
      this.$props.product.addOns !== null &&
      this.$props.product.addOns.length > 0
    ) {
      return this.$props.product.addOns.slice(0, 1);
    }
    return null;
  }

  get flexbonus():
    | {
        id: number;
        data: {
          name: string;
          amount: number;
        };
      }[]
    | null {
    return this.store.getters.getFlexbonus;
  }

  get flexbonusName(): string {
    if (this.flexbonus) {
      return this.flexbonus[0].data.name;
    }
    return '';
  }

  get totalBasePriceBonusBrutto(): number {
    if (this.flexbonus) {
      return this.$props.product.totalBasePriceBonusBrutto - this.flexbonus[0].data.amount;
    }
    return this.$props.product.totalBasePriceBonusBrutto;
  }

  get totalBasePriceBonusBruttoMonth(): number {
    if (this.flexbonus) {
      return this.$props.product.totalBasePriceBonusBruttoMonth - this.flexbonus[0].data.amount / 12;
    }
    return this.$props.product.totalBasePriceBonusBruttoMonth;
  }

  protected parseImage(image: string | null): string {
    return addOnProvider.parseImage(image);
  }

  private productDetailsChange(product) {
    if (product.displayDetails) {
      trackingProvider.productDetailsHide();
    } else {
      trackingProvider.productDetailsShow(this.sessionState, this.$props.product);
    }
  }
}
