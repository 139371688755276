












































































































































































































































































































































































































































































































































import { default as Component } from 'vue-class-component';
import vSelect from 'vue-select';

import Basic from '@/components/widgets/Basic.vue';

import ProductInfoWidget from './ProductInfoWidget.vue';
import InternationalProductInfoWidget from './international/InternationalProductInfoWidget.vue';
import QcellsProductInfoWidget from './qcells/QcellsProductInfoWidget.vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Api from '../../interfaces/Api';
import Format from '@/interfaces/Format';
import ContractData from '../../interfaces/ContractData';
import Tracking from '@/interfaces/Tracking';

import DatePicker from '../vuejs-datepicker/src/components/Datepicker.vue';
import { Watch } from 'vue-property-decorator';
import { AxiosResponse } from 'axios';

interface Salutation {
  id: number;
  label: string;
}

const ApiProvider = IocContainer.get<Api>(SERVICES.API);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@Component({
  components: {
    'product-info': ProductInfoWidget,
    'international-info': InternationalProductInfoWidget,
    'qcells-product-info': QcellsProductInfoWidget,
    'v-select': vSelect,
    'date-picker': DatePicker
  },
  props: {
    // product: {},
    discountEnabled: Boolean,
    minimumAgeRequired: Number
  },
  filters: {
    zip: formatProvider.zip()
  }
})
export default class ContractWidget extends Basic {
  public dataReview: any = {};
  public data: ContractData = {
    company: null,
    salutation: null,
    salutationObject: null,
    customerAuthUserName: null,
    customerAuthPassword: null,
    customerAuthPasswordConfirmation: null,
    firstName: null,
    surname: null,
    dateOfBirth: null,
    phoneMobileAreaCode: null,
    phoneMobile: null,
    phoneHomeAreaCode: null,
    phoneHome: null,
    phoneBusinessAreaCode: null,
    phoneBusiness: null,
    emailPrivate: null,
    emailBusiness: null,
    billingAlternativeAddress: null,
    billingCompany: null,
    billingSalutation: null,
    billingSalutationObject: null,
    billingFirstName: null,
    billingSurname: null,
    billingStreet: null,
    billingHouseNumber: null,
    billingZip: null,
    billingCity: null,
    billingPhoneAreaCode: null,
    billingPhone: null,
    billingFaxAreaCode: null,
    billingFax: null,
    billingEmail: null,
    discountCode: null,
    vpCode: null,
    uvpCode: null,
    externalId: null,
    recommendingContractId: null,
    extendedData: JSON.parse('{}')
  };
  public sessionStateWidget: any = {
    street: '',
    houseNumber: '',
    zip: '',
    city: '',
    streetFixed: 0
  };
  // public errorsBag: any = {customerAuthUserName: [], customerAuthPassword: []};
  public streets: any = [];
  public billingStreets: any = [];
  public streetData: {
    street: string | null | undefined;
    houseNumber: string | null | undefined;
  } = {
    street: null,
    houseNumber: null
  };
  public refCodeActive = false;
  public externalIdActive = false;
  public usernameIsAvailable = true;
  public customerId: string | null = null;
  public loginInvalid = false;
  public newCustomer = true;
  public sessionMapped = false;

  public salutations: Array<{
    id: number;
    label: string | any;
  }> = [];

  public successInformation: { login?: string } = {};
  protected cities: any = [];

  get errorCompany(): string | null {
    return this.getError('company');
  }
  get errorSalutation(): string | null {
    return this.getError('salutation');
  }
  get errorUsername(): string | null {
    return this.getError('customerAuthUserName');
  }
  get errorPassword(): string | null {
    return this.getError('customerAuthPassword');
  }
  get errorPasswordConfirmation(): string | null {
    return this.getError('customerAuthPasswordConfirmation');
  }
  get errorFirstName(): string | null {
    return this.getError('firstName');
  }
  get errorSurname(): string | null {
    return this.getError('surname');
  }
  get errorDateOfBirth(): string | null {
    return this.getError('dateOfBirth');
  }
  get errorStreet(): string | null {
    return this.getError('street');
  }
  get errorHouseNumber(): string | null {
    return this.getError('houseNumber');
  }
  get errorPhoneMobileAreaCode(): string | null {
    return this.getError('phoneMobileAreaCode');
  }
  get errorPhoneMobile(): string | null {
    return this.getError('phoneMobile');
  }
  get errorPhoneHomeAreaCode(): string | null {
    return this.getError('phoneHomeAreaCode');
  }
  get errorPhoneHome(): string | null {
    return this.getError('phoneHome');
  }
  get errorPhoneBusinessAreaCode(): string | null {
    return this.getError('phoneBusinessAreaCode');
  }
  get errorPhoneBusiness(): string | null {
    return this.getError('phoneBusiness');
  }
  get errorEmailPrivate(): string | null {
    return this.getError('emailPrivate');
  }
  get errorConfirmEmailPrivate(): string | null {
    return this.getError('confirmEmailPrivate');
  }
  get errorEmailBusiness(): string | null {
    return this.getError('emailBusiness');
  }
  get errorBillingAlternativeAddress(): string | null {
    return this.getError('billingAlternativeAddress');
  }
  get errorBillingCompany(): string | null {
    return this.getError('billingCompany');
  }
  get errorBillingSalutation(): string | null {
    return this.getError('billingSalutation');
  }
  get errorBillingFirstName(): string | null {
    return this.getError('billingFirstName');
  }
  get errorBillingSurname(): string | null {
    return this.getError('billingSurname');
  }
  get errorBillingStreet(): string | null {
    return this.getError('billingStreet');
  }
  get errorBillingHouseNumber(): string | null {
    return this.getError('billingHouseNumber');
  }
  get errorBillingZip(): string | null {
    return this.getError('billingZip');
  }
  get errorBillingCity(): string | null {
    return this.getError('billingCity');
  }
  get errorBillingPhoneAreaCode(): string | null {
    return this.getError('billingPhoneAreaCode');
  }
  get errorBillingPhone(): string | null {
    return this.getError('billingPhone');
  }
  get errorBillingFaxAreaCode(): string | null {
    return this.getError('billingFaxAreaCode');
  }
  get errorBillingFax(): string | null {
    return this.getError('billingFax');
  }
  get errorBillingEmail(): string | null {
    return this.getError('billingEmail');
  }
  get errorDiscountCode(): string | null {
    return this.getError('discountCode');
  }
  get errorVpCode(): string | null {
    return this.getError('vpCode');
  }
  get errorUvpCode(): string | null {
    return this.getError('uvpCode');
  }
  get errorExternalId(): string | null {
    return this.getError('externalId');
  }
  get errorRecommendingContractId(): string | null {
    return this.getError('recommendingContractId');
  }
  get errorMinimumAgeRequired(): string | null {
    return this.minimumAgeNotReached
      ? this.$t('contract.widget.min.age.required', {
          years: this.$props.minimumAgeRequired
        }).toLocaleString()
      : null;
  }

  get minimumAgeNotReached(): boolean {
    return this.disabledDatesOfBirth.from.getTime() < new Date(this.data.dateOfBirth || 0).getTime();
  }

  get disabledDatesOfBirth(): { from: Date } {
    const date = new Date();
    if (this.$props.minimumAgeRequired) {
      date.setFullYear(date.getFullYear() - this.$props.minimumAgeRequired);
    }
    return { from: date };
  }

  get todayMinusMinimumAgeRequired(): Date {
    const now = new Date();
    if (this.$props.minimumAgeRequired) {
      return new Date(now.setFullYear(now.getFullYear() - this.$props.minimumAgeRequired));
    } else {
      return now;
    }
  }

  @Watch('newCustomer')
  public onNewCustomerChanged(): void {
    this.loginInvalid = false;
  }

  @Watch('data.billingAlternativeAddress')
  public billingAlternativeAddressChange(): void {
    if (this.cities.length === 0) {
      ApiProvider.getCities().then((responseCities) => {
        if (responseCities.data.cities) {
          this.cities = responseCities.data.cities;
        }
      });
    }
  }

  @Watch('data.billingZip')
  public billingZipChanged(val: string): void {
    ApiProvider.getStreets(val).then((responseStreet) => {
      if (responseStreet.data.streets) {
        this.billingStreets = responseStreet.data.streets;
      }
    });
  }

  public mounted(): void {
    this.resetErrorBag();
    // this.errorsBag = {};
    this.salutations = [
      { id: 9, label: this.$t('contract.widget.salutation.without') },
      { id: 1, label: this.$t('contract.widget.salutation.mr') },
      { id: 2, label: this.$t('contract.widget.salutation.mrs') },
      { id: 3, label: this.$t('contract.widget.salutation.family') }
    ];
    // this.sessionStateWidget = this.store.getters.getSession;
    if (JSON.stringify(this.store.getters.getSession) !== '{}') {
      // this.sessionStateWidget = this.store.getters.getSession;
      this.mapSessionToData();
    }
    ApiProvider.sessionRead()
      .then((response) => {
        this.store.commit('SET_SESSION', response.data);
        // this.sessionStateWidget = this.store.getters.getSession;
        this.mapSessionToData();
        if (!this.sessionState.streetFixed && process.env.VUE_APP_JOURNEY_TYPE !== 'NON_COMMODITY') {
          ApiProvider.getStreets().then((responseStreet) => {
            if (responseStreet.data.streets) {
              this.streets = responseStreet.data.streets;
            }
          });
        }
        if (
          (this.sessionState.business && this.currentClient !== 'qcells') ||
          ((this.currentClient === 'qcells' || this.currentClient === 'prokon') && !this.sessionState.business)
        ) {
          this.salutations.push({
            id: 8,
            label: this.$t('contract.widget.salutation.company')
          });
        }
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  get billingAlternativeAddressActive(): boolean {
    if (this.data.billingAlternativeAddress) {
      return this.data.billingAlternativeAddress;
    } else {
      return false;
    }
  }

  public saveDateOfBirth(date: string): void {
    if (date) {
      this.data.dateOfBirth = date;
    } else {
      this.data.dateOfBirth = null;
    }
  }

  get streetsDisplay(): [] {
    return this.streets.map((street) => {
      return street.name;
    });
  }

  get billingStreetsDisplay(): [] {
    return this.billingStreets.map((street) => {
      return street.name;
    });
  }

  public salutationSelected(value: Salutation): void {
    this.data.salutation = value.id;
  }

  public billingSalutationSelected(value: Salutation): void {
    this.data.billingSalutation = value.id ? value.id : null;
  }

  public checkUsernameAvailability(): Promise<any> | boolean | undefined {
    this.usernameIsAvailable = true;
    if (!this.newCustomer) {
      this.loginInvalid = false;
      return true;
    }
    if (this.data.customerAuthUserName) {
      return ApiProvider.isUsernameAvailable(this.data.customerAuthUserName)
        .then((response) => {
          this.usernameIsAvailable = response.data.available;
        })
        .catch(() => {
          this.usernameIsAvailable = false;
        });
    }
  }

  public login(): Promise<any> | void {
    this.loginInvalid = false;
    this.successInformation = {};
    if (
      this.usernameIsAvailable &&
      this.data.customerAuthUserName &&
      this.data.customerAuthPassword &&
      this.customerId === null
    ) {
      return ApiProvider.login(this.data.customerAuthUserName, this.data.customerAuthPassword)
        .then((response) => {
          if (response.data.success) {
            this.customerId = response.data.customerId;
            const message = String(
              this.$t('portal.login.success', {
                customerId: response.data.customerId
              })
            );
            this.successInformation = {
              ...this.successInformation,
              login: message
            };
          } else {
            this.loginInvalid = true;
          }
        })
        .catch(() => {
          this.loginInvalid = true;
        });
    } else if (!this.data.customerAuthUserName || !this.data.customerAuthPassword) {
      // if (!this.errorsBag) {
      //   this.errorsBag = {};
      // }
      if (!this.data.customerAuthUserName) {
        this.setError(
          'customerAuthUserName',
          'contract.widget.username.required',
          this.$t('contract.widget.username.required').toLocaleString()
        );
        // this.errorsBag.customerAuthUserName = [];
        // this.errorsBag.customerAuthUserName.push(this.$t('contract.widget.username.required').toLocaleString());
      }
      if (!this.data.customerAuthPassword) {
        this.setError(
          'customerAuthPassword',
          'contract.widget.password.required',
          this.$t('contract.widget.password.required').toLocaleString()
        );
        // this.errorsBag.customerAuthPassword = [];
        // this.errorsBag.customerAuthPassword.push(this.$t('contract.widget.password.required').toLocaleString());
      }
    }
  }

  public contractSubmit(): Promise<any> | boolean {
    this.resetErrorBag();
    this.loginInvalid = false;
    // remove customerAuth Data in case customer has authenticated
    if (this.customerId) {
      this.data.customerAuthUserName = null;
      this.data.customerAuthPassword = null;
    }

    if (this.minimumAgeNotReached) {
      return false;
    }

    if (this.data.vpCode === '' && this.sessionState.vpCode?.toUpperCase() === 'AKLAMIO') {
      this.data.vpCode = this.sessionState.vpCode;
    }

    if (this.currentClient === 'wechselstrom' && this.data.emailPrivate !== this.data.confirmEmailPrivate) {
      this.setError(
        'confirmEmailPrivate',
        'contract.widget.confirmEmailPrivate.error',
        this.$t('contract.widget.confirmEmailPrivate.error').toLocaleString()
      );
      return false;
    }

    this.store.dispatch('setContactData', { ...this.data, ...this.streetData });
    return ApiProvider.sessionUpdate(this.data, this.streetData.street, this.streetData.houseNumber)
      .then((response) => {
        this.validateContract(response);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          this.errorsBag = error.response.data.errors;
        }
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  protected onContractSubmitError(): void {
    return;
  }

  public validateContract(response: AxiosResponse): void {
    ApiProvider.validateContract()
      .then(() => {
        if (typeof this.data.extendedData === 'string') {
          // Before saving to the session, JSON is transformed back into an object
          this.data.extendedData = JSON.parse(this.data.extendedData);
        }
        this.store.commit('SET_SESSION', this.data);
        trackingProvider.validationContractSuccessful(this.sessionState, this.data, this.productGetter);
        this.$emit('contractSubmit', response.data);
      })
      .catch((error) => {
        trackingProvider.validationContractFailed();
        this.errorsBag = error.response.data.error.messages;
        this.store.dispatch('setErrors', this.errorsBag);
        if (this.errorsBag.externalId) {
          this.externalIdActive = true;
        }
        this.$emit('scrollToTop');
        this.onContractValidationError();
      });
  }

  protected onContractValidationError(): void {
    return;
  }

  protected mapSessionToData(): void {
    this.sessionMapped = false;
    if (process.env.VUE_APP_JOURNEY_TYPE !== 'NON_COMMODITY') {
      Object.entries(this.sessionState).map((tuple) => {
        if (tuple[0] in this.data) {
          this.data[tuple[0]] = tuple[1];
        }
      });

      this.data.salutationObject = this.sessionState.salutation
        ? this.salutations.filter((salutation) => {
            return this.sessionState.salutation === salutation.id;
          })[0]
        : null;
      this.data.billingSalutationObject = this.sessionState.billingSalutation
        ? this.salutations.filter((salutation) => {
            return this.sessionState.billingSalutation === salutation.id;
          })[0]
        : null;
      this.data.billingPhoneAreaCode = this.sessionState.billingPhoneAreaCode
        ? this.sessionState.billingPhoneAreaCode
        : null;

      if (this.data.vpCode || this.data.uvpCode || this.data.externalId || this.data.recommendingContractId) {
        this.refCodeActive = true;
      }
      if (this.data.externalId) {
        this.externalIdActive = true;
      }
      this.streetData = {
        street: this.sessionState.street,
        houseNumber: this.sessionState.houseNumber
      };
      this.mapExtraFields();
    } else {
      this.data = { ...this.data, ...this.sessionState };
    }

    this.sessionMapped = true;
  }

  protected mapExtraFields(): void {
    return;
  }
}
