import { injectable } from 'inversify';
import Tracking from '../interfaces/Tracking';
import SessionState from '@/interfaces/SessionState';
import AddOnData from '@/interfaces/AddOnData';
import ProductData from '@/interfaces/ProductData';
interface Product {
  productName: string;
  productCode: string;
  deposit: {
    brutto: number;
  };
  bonusSavings: number;
  initialDuration: number;
}

interface TrackerProductsItem {
  name: string;
  id: string;
  price: number;
  brand: string;
  category: string;
  variant: string | number | null | undefined;
  quantity: number;
}

@injectable()
export default class TrackingProvider implements Tracking {
  public dataLayerTag = 'dataLayer';
  public async widgetStepChange(widgetName: string): Promise<void> {
    // window._paq.push(['trackEvent', 'Step Change', widgetName]);
  }

  public async productsLoaded(sessionState: SessionState, products: [Product]): Promise<void> {
    // window._paq.push(['trackEvent', 'Product', 'List Shown']);
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'qcells':
        this.productsLoadedQcells(sessionState, products);
        break;
      case 'wdenergy':
        this.productsLoadedWdenergy(sessionState, products);
        break;
      case 'sachsenenergie':
        this.productsLoadedSachsenEnergie(sessionState, products);
        break;
      case 'elli':
      case 'elli-thg-audi':
      case 'elli-thg-cupra':
      case 'elli-thg-vw':
      case 'elli-thg-vwfs':
      case 'elli-thg-skoda':
      case 'elli-thg-dealer':
        this.productsLoadedElli();
        this.productDeselectedElli(sessionState, products);
        break;
      case 'prokon':
        this.productsLoadedProkon(sessionState, products);
        break;
      default:
        break;
    }
  }

  public async productDetailsHide(): Promise<void> {
    // window._paq.push(['trackEvent', 'Product Details', 'Hide']);
  }

  public async productDetailsShow(sessionState: SessionState, product: ProductData): Promise<void> {
    // window._paq.push(['trackEvent', 'Product Details', 'Show']);
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'qcells':
        this.productDetailsShowQcells(sessionState, product);
        break;
      case 'wdenergy':
        this.productDetailsShowWdenergy(sessionState, product);
        break;
      case 'sachsenenergie':
        this.productDetailsShowSachsenEnergie(sessionState, product);
        break;
      case 'prokon':
        this.productDetailsShowProkon(sessionState, product);
        break;
      default:
        break;
    }
  }

  public async productSelected(
    sessionState: SessionState,
    product: ProductData,
    addon: AddOnData | null = null,
    discountValue: any = null
  ): Promise<void> {
    // window._paq.push(['trackEvent', 'Product', 'Selected']);
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'qcells':
        this.productSelectedQcells(sessionState, product, addon);
        break;
      case 'wdenergy':
        this.productSelectedWdenergy(sessionState, product, addon);
        break;
      case 'sachsenenergie':
        this.productSelectedSachsenEnergie(sessionState, product, addon);
        break;
      case 'elli':
      case 'elli-thg-audi':
      case 'elli-thg-cupra':
      case 'elli-thg-vw':
      case 'elli-thg-vwfs':
      case 'elli-thg-skoda':
      case 'elli-thg-dealer':
        this.productSelectedElli(sessionState, product, discountValue);
        break;
      case 'prokon':
        this.productSelectedProkon(sessionState, product, addon);
        break;
      default:
        break;
    }
  }

  public async bonusClicked(bonus: string, product: ProductData) {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'submit_naturstrom_voucher',
        naturstrom_voucherID: bonus,
        naturstrom_tariff: product.productName
      });
    }
  }

  public async validationContractSuccessful(
    sessionState: SessionState,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: any,
    product: ProductData
  ): Promise<void> {
    // window._paq.push(['trackEvent', 'Validation Contract', 'Successful']);
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'qcells':
        this.validationContractSuccessfulQcells(sessionState, data, product);
        break;
      case 'wdenergy':
        this.validationContractSuccessfulWdenergy(sessionState, data, product);
        break;
      case 'sachsenenergie':
        this.validationContractSuccessfulSachsenEnergie(sessionState, data, product);
        break;
      case 'elli':
      case 'elli-thg-audi':
      case 'elli-thg-cupra':
      case 'elli-thg-vw':
      case 'elli-thg-vwfs':
      case 'elli-thg-skoda':
      case 'elli-thg-dealer':
        this.validationContractSuccessfulElli();
        break;
      case 'prokon':
        this.validationContractSuccessfulProkon(sessionState, data, product);
        break;
      default:
        break;
    }
  }

  public async validationContractFailed(): Promise<void> {
    // window._paq.push(['trackEvent', 'Validation Contract', 'Failed']);
  }

  public async validationDeliverySuccessful(
    sessionState: SessionState,
    product: ProductData,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    previousProvider: any,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    customerSpecification: any
  ): Promise<void> {
    // window._paq.push(['trackEvent', 'Validation Delivery', 'Successful']);
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'qcells':
        this.validationDeliverySuccessfulQcells(sessionState, product, previousProvider, customerSpecification);
        break;
      case 'wdenergy':
        this.validationDeliverySuccessfulWdenergy(sessionState, product, previousProvider, customerSpecification);
        break;
      case 'sachsenenergie':
        this.validationDeliverySuccessfulSachsenEnergie(sessionState, product, previousProvider, customerSpecification);
        break;
      case 'elli':
      case 'elli-thg-audi':
      case 'elli-thg-cupra':
      case 'elli-thg-vw':
      case 'elli-thg-vwfs':
      case 'elli-thg-skoda':
      case 'elli-thg-dealer':
        this.validationDeliverySuccessfulElli();
        break;
      case 'prokon':
        this.validationDeliverySuccessfulProkon(sessionState, product, previousProvider, customerSpecification);
        break;
      default:
        break;
    }
  }

  public async validationDeliveryFailed(): Promise<void> {
    // window._paq.push(['trackEvent', 'Validation Delivery', 'Failed']);
  }

  public async validationPaymentSuccessful(
    sessionState: SessionState,
    product: ProductData,
    iban: string
  ): Promise<void> {
    // window._paq.push(['trackEvent', 'Validation Payment', 'Successful']);
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'qcells':
        this.validationPaymentSuccessfulQcells(sessionState, product, iban);
        break;
      case 'wdenergy':
        this.validationPaymentSuccessfulWdenergy(sessionState, product, iban);
        break;
      case 'sachsenenergie':
        this.validationPaymentSuccessfulSachsenEnergie(sessionState, product, iban);
        break;
      case 'elli':
      case 'elli-thg-audi':
      case 'elli-thg-cupra':
      case 'elli-thg-vw':
      case 'elli-thg-vwfs':
      case 'elli-thg-skoda':
      case 'elli-thg-dealer':
        this.validationPaymentSuccessfulElli();
        break;
      case 'prokon':
        this.validationPaymentSuccessfulProkon(sessionState, product, iban);
        break;
      default:
        break;
    }
  }

  public async validationPaymentFailed(): Promise<void> {
    // window._paq.push(['trackEvent', 'Validation Payment', 'Failed']);
  }

  public async validationRulesSuccessful(sessionState: SessionState, product: ProductData): Promise<void> {
    // window._paq.push(['trackEvent', 'Validation Rules', 'Successful']);
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'qcells':
        this.validationRulesSuccessfulQcells(sessionState, product);
        break;
      case 'wdenergy':
        this.validationRulesSuccessfulWdenergy(sessionState, product);
        break;
      case 'sachsenenergie':
        this.validationRulesSuccessfulSachsenEnergie(sessionState, product);
        break;
      case 'prokon':
        this.validationRulesSuccessfulProkon(sessionState, product);
        break;
      default:
        break;
    }
  }

  public async validationRulesFailed(): Promise<void> {
    // window._paq.push(['trackEvent', 'Validation Rules', 'Failed']);
  }

  public async orderSent(): Promise<void> {
    // window._paq.push(['trackEvent', 'Order', 'Sent']);
  }

  public async orderCreated(
    sessionState: SessionState,
    product: ProductData,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    previousProvider: any,
    orderId: number,
    discountValue: any = null
  ): Promise<void> {
    let revenue = 0;
    if (process.env.VUE_APP_JOURNEY_TYPE !== 'NON_COMMODITY') {
      revenue = product.deposit ? product.deposit.brutto * product.initialDuration - product.bonusSavings : 0;
    }
    // window._paq.push(['trackEvent', 'Order', 'Created', revenue]);
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'qcells':
        this.orderCreatedQcells(sessionState, product, previousProvider, orderId, revenue);
        break;
      case 'wdenergy':
        this.orderCreatedWdenergy(sessionState, product, previousProvider, orderId, revenue);
        break;
      case 'sachsenenergie':
        this.orderCreatedSachsenEnergie(sessionState, product, previousProvider, orderId, revenue);
        break;
      case 'elli':
      case 'elli-thg-audi':
      case 'elli-thg-cupra':
      case 'elli-thg-vw':
      case 'elli-thg-vwfs':
      case 'elli-thg-skoda':
      case 'elli-thg-dealer':
        this.orderCreatedElli(sessionState, product, previousProvider, orderId, revenue, discountValue);
        break;
      case 'prokon':
        this.orderCreatedProkon(sessionState, product, previousProvider, orderId, revenue);
        break;
      default:
        break;
    }
  }

  public async orderFailed(): Promise<void> {
    // window._paq.push(['trackEvent', 'Order', 'Failed']);
  }

  // ************************************************
  // * HELPER METHODS
  // ************************************************
  public getCommodityTitle(commodity: string): string {
    if (commodity === 'electricity') {
      return 'Stromtarif';
    } else if (commodity === 'gas') {
      return 'Gastarif';
    } else {
      return 'Stromtarif';
    }
  }

  public getAge(dateOfBirth: string | Date): number {
    dateOfBirth = new Date(dateOfBirth);
    const now = new Date();
    let diff = (now.getTime() - dateOfBirth.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    return Math.abs(Math.round(diff / 365.25));
  }

  public getFullZip(zip: number): string {
    let out: string;
    if (zip && zip.toString().length < 5) {
      out = '0' + zip.toString();
    } else {
      out = zip.toString();
    }
    return out;
  }

  // ************************************************
  // * Client Specific methods - Q-Cells
  // ************************************************
  public productsLoadedQcells(sessionState: SessionState, products: [Product]): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepTariffs',
        event: 'qcellsForm',
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage
      });

      // push available products
      const impressions: any[] = [];
      products.forEach((product) => {
        impressions.push({
          name: product.productName,
          id: product.productCode,
          price: product.deposit ? product.deposit.brutto : 0,
          brand: 'Q CELLS',
          category: this.getCommodityTitle(sessionState.commodity ?? ''),
          variant: sessionState.usage,
          list: 'Tarifübersicht',
          position: impressions.length + 1
        });
      });
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'EUR',
          impressions
        }
      });
    }
  }

  public productDetailsShowQcells(sessionState: SessionState, product: ProductData): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        ecommerce: {
          detail: {
            actionField: { list: 'Tarifübersicht' },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Q CELLS',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage
              }
            ]
          }
        }
      });
    }
  }

  public productSelectedQcells(sessionState: SessionState, product: ProductData, addon: AddOnData | null): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepPersonalData',
        event: 'qcellsForm',
        tariff: product.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage
      });

      const products: [TrackerProductsItem] = [
        {
          name: product.productName,
          id: product.productCode,
          price: product.deposit ? product.deposit.brutto : 0,
          brand: 'Q CELLS',
          category: this.getCommodityTitle(sessionState.commodity ?? ''),
          variant: sessionState.usage,
          quantity: product.initialDuration
        }
      ];

      if (addon) {
        products.push({
          name: addon.productName,
          id: addon.productCode,
          price: addon.prices[0].salesPriceBrutto,
          brand: 'Q CELLS',
          category: 'Addon',
          variant: addon.productType,
          quantity: 1
        });
      }

      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: products
          }
        }
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: null
            },
            products: products
          }
        }
      });
    }
  }

  public validationContractSuccessfulQcells(
    sessionState: SessionState,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: any,
    product: ProductData
  ): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepDelivery',
        event: 'qcellsForm',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(data.dateOfBirth)
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
              option: null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Q CELLS',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: product.initialDuration
              }
            ]
          }
        }
      });
    }
  }

  public validationDeliverySuccessfulQcells(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    customerSpecification: { id: string }
  ): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepPayment',
        event: 'qcellsForm',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        supplier: previousProvider.text,
        switch_type: customerSpecification.id === 'relocation_at' ? 'new' : 'switch'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 3,
              option: customerSpecification.id === 'relocation_at' ? 'new' : 'switch'
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Q CELLS',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: product.initialDuration
              }
            ]
          }
        }
      });
    }
  }

  public validationPaymentSuccessfulQcells(sessionState: SessionState, product: ProductData, iban: string): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepLegal',
        event: 'qcellsForm',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        payment_type: iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 4,
              option: iban ? 'directDebit' : 'transfer'
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Q CELLS',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: product.initialDuration
              }
            ]
          }
        }
      });
    }
  }

  public validationRulesSuccessfulQcells(sessionState: SessionState, product: ProductData): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'form send',
        event: 'qcellsForm',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        payment_type: sessionState.iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 5,
              option: null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Q CELLS',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: product.initialDuration
              }
            ]
          }
        }
      });
    }
  }

  public orderCreatedQcells(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    orderId: number,
    revenue: number
  ): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'success',
        event: 'mailConfirmSuccess',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        supplier: previousProvider.text,
        payment_type: sessionState.iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: orderId,
              affiliation: 'Q.ENERGY',
              revenue,
              shipping: '0',
              coupon: sessionState.uvpCode ? sessionState.uvpCode : sessionState.vpCode ? sessionState.vpCode : null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Q CELLS',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: product.initialDuration,
                coupon: sessionState.uvpCode ? sessionState.uvpCode : sessionState.vpCode ? sessionState.vpCode : null
              }
            ]
          }
        }
      });
    }
  }

  // ************************************************
  // * Client Specific methods - Wdenergy
  // ************************************************
  public productsLoadedWdenergy(sessionState: SessionState, products: [Product]): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'ViewContent', {
        content_category: this.getCommodityTitle(sessionState.commodity ?? '')
      });
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepTariffs',
        event: 'form',
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage
      });

      // push available products
      const impressions: any[] = [];
      products.forEach((product) => {
        impressions.push({
          name: product.productName,
          id: product.productCode,
          price: product.deposit ? product.deposit.brutto : 0,
          brand: 'Wdenergy',
          category: this.getCommodityTitle(sessionState.commodity ?? ''),
          variant: sessionState.usage,
          list: 'Tarifübersicht',
          position: impressions.length + 1
        });
      });
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'EUR',
          impressions
        }
      });
    }
  }

  public productDetailsShowWdenergy(sessionState: SessionState, product: ProductData): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        ecommerce: {
          detail: {
            actionField: { list: 'Tarifübersicht' },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Wdenergy',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage
              }
            ]
          }
        }
      });
    }
  }

  public productSelectedWdenergy(sessionState: SessionState, product: ProductData, addon: AddOnData | null): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'InitiateCheckout', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepPersonalData',
        event: 'form',
        tariff: product.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage
      });

      const products: [TrackerProductsItem] = [
        {
          name: product.productName,
          id: product.productCode,
          price: product.deposit ? product.deposit.brutto : 0,
          brand: 'Wdenergy',
          category: this.getCommodityTitle(sessionState.commodity ?? ''),
          variant: sessionState.usage,
          quantity: 1
        }
      ];

      if (addon) {
        products.push({
          name: addon.productName,
          id: addon.productCode,
          price: addon.prices[0].salesPriceBrutto,
          brand: 'Wdenergy',
          category: 'Addon',
          variant: addon.productType,
          quantity: 1
        });
      }

      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: products
          }
        }
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: null
            },
            products: products
          }
        }
      });
    }
  }

  public validationContractSuccessfulWdenergy(
    sessionState: SessionState,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: any,
    product: ProductData
  ): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'Lead', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepDelivery',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(data.dateOfBirth)
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
              option: null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Wdenergy',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationDeliverySuccessfulWdenergy(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    customerSpecification: { id: string }
  ): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepPayment',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        supplier: previousProvider.text,
        switch_type: customerSpecification.id === 'relocation_at' ? 'new' : 'switch'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 3,
              option: customerSpecification.id === 'relocation_at' ? 'new' : 'switch'
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Wdenergy',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationPaymentSuccessfulWdenergy(sessionState: SessionState, product: ProductData, iban: string): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'AddPaymentInfo', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepLegal',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        payment_type: iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 4,
              option: iban ? 'directDebit' : 'transfer'
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Wdenergy',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationRulesSuccessfulWdenergy(sessionState: SessionState, product: ProductData): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'CompleteRegistration', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'form send',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        payment_type: sessionState.iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 5,
              option: null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Wdenergy',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public orderCreatedWdenergy(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    orderId: number,
    revenue: number
  ): void {
    if (typeof window['gtag'] !== 'undefined') {
      if (sessionState.commodity === 'electricity') {
        window['gtag']('event', 'conversion', {
          send_to: 'AW-586343144/EF4kCI_Z_t4BEOjFy5cC'
        });
      } else if (sessionState.commodity === 'gas') {
        window['gtag']('event', 'conversion', {
          send_to: 'AW-586343144/hRqsCOKN_t4BEOjFy5cC'
        });
      }
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'success',
        event: 'mailConfirmSuccess',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        supplier: previousProvider.text,
        payment_type: sessionState.iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: orderId,
              affiliation: 'Wdenergy',
              revenue,
              shipping: '0',
              coupon: sessionState.uvpCode ? sessionState.uvpCode : sessionState.vpCode ? sessionState.vpCode : null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Wdenergy',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1,
                coupon: sessionState.uvpCode ? sessionState.uvpCode : sessionState.vpCode ? sessionState.vpCode : null
              }
            ]
          }
        }
      });
    }
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'Purchase', {
        content_category: this.getCommodityTitle(sessionState.commodity ?? ''),
        content_ids: product.productName
      });
    }
  }

  // ************************************************
  // * Client Specific methods - SachsenEnergie
  // ************************************************
  public productsLoadedSachsenEnergie(sessionState: SessionState, products: [Product]): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'ViewContent', {
        content_category: this.getCommodityTitle(sessionState.commodity ?? '')
      });
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepTariffs',
        event: 'form',
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage
      });

      // push available products
      const impressions: any[] = [];
      products.forEach((product) => {
        impressions.push({
          name: product.productName,
          id: product.productCode,
          price: product.deposit ? product.deposit.brutto : 0,
          brand: 'SachsenEnergie',
          category: this.getCommodityTitle(sessionState.commodity ?? ''),
          variant: sessionState.usage,
          list: 'Tarifübersicht',
          position: impressions.length + 1
        });
      });
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'EUR',
          impressions
        }
      });
    }
  }

  public productDetailsShowSachsenEnergie(sessionState: SessionState, product: ProductData): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        ecommerce: {
          detail: {
            actionField: { list: 'Tarifübersicht' },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'SachsenEnergie',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage
              }
            ]
          }
        }
      });
    }
  }

  public productSelectedSachsenEnergie(
    sessionState: SessionState,
    product: ProductData,
    addon: AddOnData | null
  ): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'InitiateCheckout', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepPersonalData',
        event: 'form',
        tariff: product.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage
      });

      const products: [TrackerProductsItem] = [
        {
          name: product.productName,
          id: product.productCode,
          price: product.deposit ? product.deposit.brutto : 0,
          brand: 'SachsenEnergie',
          category: this.getCommodityTitle(sessionState.commodity ?? ''),
          variant: sessionState.usage,
          quantity: 1
        }
      ];

      if (addon) {
        products.push({
          name: addon.productName,
          id: addon.productCode,
          price: addon.prices[0].salesPriceBrutto,
          brand: 'SachsenEnergie',
          category: 'Addon',
          variant: addon.productType,
          quantity: 1
        });
      }

      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: products
          }
        }
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: null
            },
            products: products
          }
        }
      });
    }
  }

  public validationContractSuccessfulSachsenEnergie(
    sessionState: SessionState,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: any,
    product: ProductData
  ): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'Lead', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepDelivery',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(data.dateOfBirth)
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
              option: null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'SachsenEnergie',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationDeliverySuccessfulSachsenEnergie(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    customerSpecification: { id: string }
  ): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepPayment',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        supplier: previousProvider.text,
        switch_type: customerSpecification.id === 'relocation_at' ? 'new' : 'switch'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 3,
              option: customerSpecification.id === 'relocation_at' ? 'new' : 'switch'
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'SachsenEnergie',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationPaymentSuccessfulSachsenEnergie(
    sessionState: SessionState,
    product: ProductData,
    iban: string
  ): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'AddPaymentInfo', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepLegal',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        payment_type: iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 4,
              option: iban ? 'directDebit' : 'transfer'
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'SachsenEnergie',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationRulesSuccessfulSachsenEnergie(sessionState: SessionState, product: ProductData): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'CompleteRegistration', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'form send',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        payment_type: sessionState.iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 5,
              option: null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'SachsenEnergie',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public orderCreatedSachsenEnergie(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    orderId: number,
    revenue: number
  ): void {
    if (typeof window['gtag'] !== 'undefined') {
      if (sessionState.commodity === 'electricity') {
        window['gtag']('event', 'conversion', {
          send_to: 'AW-586343144/EF4kCI_Z_t4BEOjFy5cC'
        });
      } else if (sessionState.commodity === 'gas') {
        window['gtag']('event', 'conversion', {
          send_to: 'AW-586343144/hRqsCOKN_t4BEOjFy5cC'
        });
      }
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'success',
        event: 'mailConfirmSuccess',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        supplier: previousProvider.text,
        payment_type: sessionState.iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: orderId,
              affiliation: 'SachsenEnergie',
              revenue,
              shipping: '0',
              coupon: sessionState.uvpCode ? sessionState.uvpCode : sessionState.vpCode ? sessionState.vpCode : null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'SachsenEnergie',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1,
                coupon: sessionState.uvpCode ? sessionState.uvpCode : sessionState.vpCode ? sessionState.vpCode : null
              }
            ]
          }
        }
      });
    }
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'Purchase', {
        content_category: this.getCommodityTitle(sessionState.commodity ?? ''),
        content_ids: product.productName
      });
    }
  }

  // ************************************************
  // * Client Specific methods - Elli
  // ************************************************
  public productsLoadedElli(): void {
    const lang = process.env.VUE_APP_I18N_LOCALE;
    let pagePath = location.protocol + '//' + location.host;
    if (lang == 'en') {
      pagePath += '/en/naturstrom-order/tariff';
    } else {
      pagePath += '/de/naturstrom-buchen/tarif';
    }
    window[this.dataLayerTag] = window[this.dataLayerTag] || [];
    window[this.dataLayerTag].push({
      event: 'virtualPageview',
      page_title: 'Naturstrom Tarifauswahl',
      page_location: pagePath
    });
  }

  public productSelectedElli(sessionState: SessionState, product: ProductData, discountValue): void {
    let item_variant = '';
    switch (product.productCode) {
      case 'PRODVolkswagenNaturstromClassic':
        item_variant = '24 Monate';
        break;
      case 'PRODVolkswagenNaturstromBlue':
      case 'PRODVolkswagenNaturstromConnect':
        item_variant = '12 Monate';
        break;
    }

    let price = 0;

    if (sessionState.discountCode && product.totalBasePriceBonusBrutto && discountValue) {
      price = product.totalBasePriceBonusBrutto - discountValue;
    } else {
      price = product.totalBasePriceBonusBrutto ?? 0;
    }

    if (typeof price !== 'number') {
      price = parseFloat(price);
    }

    window[this.dataLayerTag].push({ ecommerce: null });
    window[this.dataLayerTag] = window[this.dataLayerTag] || [];
    window[this.dataLayerTag].push({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'EUR',
        value: price.toFixed(2), // value = price (-) discount
        coupon: sessionState.discountCode,
        items: [
          {
            item_id: product.productCode, // “sku” oder “productID”
            item_name: product.productName,
            coupon: sessionState.discountCode,
            discount: discountValue,
            index: 0,
            item_brand: 'Volkswagen Naturstrom',
            item_category: this.getCommodityTitle(sessionState.commodity ?? ''),
            item_variant: item_variant,
            price: product.totalBasePriceBonusBrutto,
            quantity: 1
          }
        ]
      }
    });

    window[this.dataLayerTag] = window.dataLayer || [];
    window[this.dataLayerTag].push({
      event: 'submit_naturstrom_tariff',
      naturstrom_tariff: product.productName
    });

    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'AddToCart', {
        content_type: 'Volkswagen Naturstrom',
        contents: [product.productName],
        content_ids: [product.productCode],
        value: product.deposit ? product.deposit.brutto : 0 * product.initialDuration - product.bonusSavings,
        currency: 'EUR'
      });
    }
  }

  public productDeselectedElli(sessionState: SessionState, products: [Product]): void {
    if (sessionState.productCode !== null) {
      const productArray = products.filter((p) => {
        return p.productCode === sessionState.productCode;
      });
      if (productArray.length > 0) {
        const product = productArray[0];
        window[this.dataLayerTag] = window[this.dataLayerTag] || [];
        window[this.dataLayerTag].push({
          event: 'change_naturstrom_tariff',
          naturstrom_tariff: product.productName
        });
      }
    }
  }

  public contractScreenElli(): void {
    const lang = process.env.VUE_APP_I18N_LOCALE;
    let pagePath = location.protocol + '//' + location.host;
    if (lang == 'en') {
      pagePath += '/en/naturstrom/order/step1';
    } else {
      pagePath += '/de/volkswagen/naturstrom-buchen/step1';
    }
    window[this.dataLayerTag] = window[this.dataLayerTag] || [];
    window[this.dataLayerTag].push({
      event: 'virtualPageview',
      page_title: 'Naturstrom Buchungsstrecke: 01 - Persönliche Daten & Lieferanschrift',
      page_location: pagePath
    });
  }

  public connectTariffScreenElli(): void {
    const lang = process.env.VUE_APP_I18N_LOCALE;
    let pagePath = location.protocol + '//' + location.host;
    if (lang == 'en') {
      pagePath += '/en/naturstrom/order/step1a';
    } else {
      pagePath += '/de/naturstrom/buchung/step1a';
    }
    window[this.dataLayerTag] = window[this.dataLayerTag] || [];
    window[this.dataLayerTag].push({
      event: 'virtualPageview',
      page_title: 'Naturstrom Buchungsstrecke: 01a - KfZ-Schein',
      page_location: pagePath
    });
  }

  public validationContractSuccessfulElli(): void {
    const lang = process.env.VUE_APP_I18N_LOCALE;
    let pagePath = location.protocol + '//' + location.host;
    if (lang == 'en') {
      pagePath += '/en/naturstrom/order/step2';
    } else {
      pagePath += '/de/volkswagen/naturstrom-buchen/step2';
    }
    window[this.dataLayerTag] = window[this.dataLayerTag] || [];
    window[this.dataLayerTag].push({
      event: 'virtualPageview',
      page_title: 'Naturstrom Buchungsstrecke: 02 - Belieferungsdetails',
      page_location: pagePath
    });
  }

  public validationDeliverySuccessfulElli(): void {
    const lang = process.env.VUE_APP_I18N_LOCALE;
    let pagePath = location.protocol + '//' + location.host;
    if (lang == 'en') {
      pagePath += '/en/naturstrom/order/step3';
    } else {
      pagePath += '/de/volkswagen/naturstrom-buchen/step3';
    }
    window[this.dataLayerTag] = window[this.dataLayerTag] || [];
    window[this.dataLayerTag].push({
      event: 'virtualPageview',
      page_title: 'Naturstrom Buchungsstrecke: 03 - Zahlung',
      page_location: pagePath
    });
  }

  public validationPaymentSuccessfulElli(): void {
    const lang = process.env.VUE_APP_I18N_LOCALE;
    let pagePath = location.protocol + '//' + location.host;
    if (lang == 'en') {
      pagePath += '/en/naturstrom/order/step4';
    } else {
      pagePath += '/de/volkswagen/naturstrom-buchen/step4';
    }
    window[this.dataLayerTag] = window[this.dataLayerTag] || [];
    window[this.dataLayerTag].push({
      event: 'virtualPageview',
      page_title: 'Naturstrom Buchungsstrecke: 04 - Rechtliche Hinweise & Abschluss',
      page_location: pagePath
    });
  }

  public orderCreatedElli(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    orderId: number,
    revenue: number,
    discountValue: any
  ): void {
    let item_variant = '';
    switch (product.productCode) {
      case 'PRODVolkswagenNaturstromClassic':
        item_variant = '24 Monate';
        break;
      case 'PRODVolkswagenNaturstromBlue':
      case 'PRODVolkswagenNaturstromConnect':
        item_variant = '12 Monate';
        break;
    }

    window[this.dataLayerTag].push({
      event: 'purchase_naturstrom_tariff',
      naturstrom_tariff: product.productName
    });

    const lang = process.env.VUE_APP_I18N_LOCALE;
    let pagePath = location.protocol + '//' + location.host;
    if (lang == 'en') {
      pagePath += '/en/naturstrom/order/purchase';
    } else {
      pagePath += '/de/volkswagen/naturstrom-buchen/purchase';
    }

    window[this.dataLayerTag].push({
      event: 'virtualPageview',
      page_title: 'Naturstrom Buchungsstrecke: Abschluss',
      page_location: pagePath
    });

    let price = 0;
    if (sessionState.discountCode && product.totalBasePriceBonusBrutto && discountValue) {
      price = product.totalBasePriceBonusBrutto - discountValue;
    } else {
      price = product.totalBasePriceBonusBrutto ?? 0;
    }

    if (typeof price !== 'number') {
      price = parseFloat(price);
    }

    window[this.dataLayerTag] = window[this.dataLayerTag] || [];
    window[this.dataLayerTag].push({
      event: 'purchase',
      ecommerce: {
        transaction_id: orderId,
        value: price.toFixed(2),
        tax: 0,
        shipping: 0,
        currency: 'EUR',
        coupon: sessionState.discountCode,
        items: [
          {
            item_id: product.productCode, // "sku" oder "productID"
            item_name: product.productName,
            coupon: sessionState.discountCode,
            discount: sessionState.discountCode ? discountValue : 0,
            index: 0,
            item_brand: 'Volkswagen Naturstrom',
            item_category: this.getCommodityTitle(sessionState.commodity ?? ''),
            item_variant: item_variant,
            price: product.totalBasePriceBonusBrutto,
            quantity: 1
          }
        ]
      }
    });

    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'Purchase', { currency: 'EUR', value: revenue });
    }
  }

  // ************************************************
  // * Client Specific methods - Prokon
  // ************************************************
  public productsLoadedProkon(sessionState: SessionState, products: [Product]): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepTariffs',
        event: 'form',
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage
      });

      // push available products
      const impressions: any[] = [];
      products.forEach((product) => {
        impressions.push({
          name: product.productName,
          id: product.productCode,
          price: product.deposit ? product.deposit.brutto : 0,
          brand: 'Prokon',
          category: this.getCommodityTitle(sessionState.commodity ?? ''),
          variant: sessionState.usage,
          list: 'Tarifübersicht',
          position: impressions.length + 1
        });
      });
      window.dataLayer.push({
        ecommerce: {
          currencyCode: 'EUR',
          impressions
        }
      });
    }
  }

  public productDetailsShowProkon(sessionState: SessionState, product: ProductData): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        ecommerce: {
          detail: {
            actionField: { list: 'Tarifübersicht' },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Prokon',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage
              }
            ]
          }
        }
      });
    }
  }

  public productSelectedProkon(sessionState: SessionState, product: ProductData, addon: AddOnData | null): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepPersonalData',
        event: 'form',
        tariff: product.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage
      });

      const products: [TrackerProductsItem] = [
        {
          name: product.productName,
          id: product.productCode,
          price: product.deposit ? product.deposit.brutto : 0,
          brand: 'Prokon',
          category: this.getCommodityTitle(sessionState.commodity ?? ''),
          variant: sessionState.usage,
          quantity: 1
        }
      ];

      if (addon) {
        products.push({
          name: addon.productName,
          id: addon.productCode,
          price: addon.prices[0].salesPriceBrutto,
          brand: 'Prokon',
          category: 'Addon',
          variant: addon.productType,
          quantity: 1
        });
      }

      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'EUR',
          add: {
            products: products
          }
        }
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              option: null
            },
            products: products
          }
        }
      });
    }
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'InitiateCheckout', {});
      window['fbq']('track', 'AddToCart', {
        content_type: 'Prokon',
        contents: [product.productName],
        content_ids: [product.productCode],
        value: product.deposit ? product.deposit.brutto : 0 * product.initialDuration - product.bonusSavings,
        currency: 'EUR'
      });
    }
  }

  public validationContractSuccessfulProkon(
    sessionState: SessionState,
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: any,
    product: ProductData
  ): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'Lead', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepDelivery',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(data.dateOfBirth)
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2,
              option: null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Prokon',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationDeliverySuccessfulProkon(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    customerSpecification: { id: string }
  ): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepPayment',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        supplier: previousProvider.text,
        switch_type: customerSpecification.id === 'relocation_at' ? 'new' : 'switch'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 3,
              option: customerSpecification.id === 'relocation_at' ? 'new' : 'switch'
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Prokon',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationPaymentSuccessfulProkon(sessionState: SessionState, product: ProductData, iban: string): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'AddPaymentInfo', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'stepLegal',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        payment_type: iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 4,
              option: iban ? 'directDebit' : 'transfer'
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Prokon',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public validationRulesSuccessfulProkon(sessionState: SessionState, product: ProductData): void {
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'CompleteRegistration', {});
    }
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'form send',
        event: 'form',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        payment_type: sessionState.iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 5,
              option: null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Prokon',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1
              }
            ]
          }
        }
      });
    }
  }

  public orderCreatedProkon(
    sessionState: SessionState,
    product: ProductData,
    previousProvider: { text: string },
    orderId: number,
    revenue: number
  ): void {
    if (window.dataLayer) {
      window.dataLayer.push({
        step: 'success',
        event: 'mailConfirmSuccess',
        tariff: sessionState.productCode,
        zip: this.getFullZip(Number(sessionState.zip)),
        usage: sessionState.usage,
        age: this.getAge(sessionState.dateOfBirth ?? ''),
        switch_type: sessionState.customerSpecification === 'relocation_at' ? 'new' : 'switch',
        supplier: previousProvider.text,
        payment_type: sessionState.iban ? 'directDebit' : 'transfer'
      });

      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: orderId,
              affiliation: 'Prokon',
              revenue,
              shipping: '0',
              coupon: sessionState.uvpCode ? sessionState.uvpCode : sessionState.vpCode ? sessionState.vpCode : null
            },
            products: [
              {
                name: product.productName,
                id: product.productCode,
                price: product.deposit ? product.deposit.brutto : 0,
                brand: 'Prokon',
                category: this.getCommodityTitle(sessionState.commodity ?? ''),
                variant: sessionState.usage,
                quantity: 1,
                coupon: sessionState.uvpCode ? sessionState.uvpCode : sessionState.vpCode ? sessionState.vpCode : null
              }
            ]
          }
        }
      });
    }
    if (typeof window['fbq'] !== 'undefined') {
      window['fbq']('track', 'Purchase', {
        content_category: this.getCommodityTitle(sessionState.commodity ?? ''),
        content_ids: product.productName
      });
    }
  }

  //
  // THG tracking
  //

  public thgFirstStep(): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageview',
      page_title: 'THG Registrierung: Wunsch-Prämie',
      page_location: location.protocol + '//' + location.host + '/de/elli/thg/desired-premium'
    });
  }

  public thgSecondStep(): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageview',
      page_title: 'THG Registrierung: Fahrzeugdaten',
      page_location: location.protocol + '//' + location.host + '/de/elli/thg/vehicle-registration'
    });
  }

  public thgThirdStep(): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageview',
      page_title: 'THG Registrierung: Ihre Daten',
      page_location: location.protocol + '//' + location.host + '/de/elli/thg/address'
    });
  }

  public thgFourthStep(): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageview',
      page_title: 'THG Registrierung: Übersicht',
      page_location: location.protocol + '//' + location.host + '/de/elli/thg/overview'
    });
  }

  public thgFinalStep(product: ProductData, orderId: number): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageview',
      page_title: 'THG Registrierung: Abschluss',
      page_location: location.protocol + '//' + location.host + '/de/elli/thg/confirmation'
    });
    window.dataLayer.push({
      event: 'submit_ghg_registration',
      ghg_premium_type: product.name,
      ghg_registration_ID: orderId
    });
  }

  public thgProductSelected(product: ProductData, vehicleType: string, isBusiness: boolean): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'submit_ghg_premium',
      ghg_premium_type: product.name,
      ghg_customer_type: isBusiness ? 'Firmenkunde' : 'Privatkunde',
      ghg_vehicle_type: vehicleType
    });
  }
}
