




























































































































































































































import interact from 'interact.js';
import { default as Component } from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Api from '@/interfaces/Api';
import Format from '@/interfaces/Format';
import Tracking from '@/interfaces/Tracking';
import ProductInfoWidget from '@/components/widgets/ProductInfoWidget.vue';
import AddOnData from '@/interfaces/AddOnData';
import { CommodityProductData } from '@/interfaces/ProductData';

const ApiProvider = IocContainer.get<Api>(SERVICES.API);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

const ACCEPT_CARD = 'cardAccepted';
const REJECT_CARD = 'cardRejected';
const SKIP_CARD = 'cardSkipped';
const SKIP2_CARD = 'card2Skipped';

@Component({
  props: {
    defaultAddons: {}
  },
  filters: {
    date: formatProvider.date(),
    euro: formatProvider.euro(),
    euroFull: formatProvider.euroFull()
  }
})
export default class QcellsProductInfoWidget extends ProductInfoWidget {
  @Prop() public product!: CommodityProductData;
  @Prop({ default: false }) public rightSide!: boolean;
  @Prop({ default: false }) public isProductSelection!: boolean;
  @Prop() public index?: number;

  public $refs!: {
    interactElement: HTMLElement;
  };
  private selectProduct = false;
  private displayDetails = false;
  private descriptionArrowClass = 'down';
  private isMobile = false;

  private static: any = {
    interactMaxRotation: 15,
    interactOutOfSightXCoordinate: 1000,
    interactOutOfSightYCoordinate: 1200,
    interactYThreshold: 150,
    interactXThreshold: 100
  };

  private isCurrentAnimating = false;
  private isInteractAnimating = true;
  private isInteractDragged: boolean | null = null;
  private interactPosition = {
    x: 0,
    y: 0,
    rotation: 0
  };

  private defaultAddon: any | null = null;

  get formattedName(): string {
    return this.$props.product.productName.replace('Q.ENERGY', '');
  }

  get isRecommended(): boolean {
    return typeof this.$props.product.recommended !== 'undefined' && this.$props.product.recommended;
  }

  get isHasProperties(): boolean {
    return typeof this.$props.product.properties !== 'undefined' && this.$props.product.properties;
  }

  get productProperties(): string {
    return this.$props.product.properties.replace(/<\/?[^>]+(>|$)/g, '');
  }

  get allAddons(): AddOnData[] {
    return this.$props.defaultAddons;
  }

  get imageAddons(): string {
    if (typeof this.$props.product.allowAddons !== 'undefined' && this.$props.product.allowAddons && this.allAddons) {
      return this.allAddons.includes(this.$props.product.allowAddons)
        ? this.$props.product.allowAddons
        : Object.values(this.allAddons)[0];
    }
    return '';
  }

  get selectedAddon(): [] | null {
    if (this.sessionState.optionalAddOns) {
      const selected = JSON.parse(this.sessionState.optionalAddOns);
      return this.$props.product.addOns.find((addon) => {
        return selected.includes(addon.productCode);
      });
    }
    return null;
  }

  get addOnPostfix(): string {
    if (this.$props.rightSide) {
      if (this.$props.product.addOns.length > 1) {
        return this.$t('product.widget.info.multiple.addons').toString();
      }
    }
    return '';
  }

  get transformString(): string | null {
    if (!this.isInteractAnimating || this.isInteractDragged) {
      const { x, y, rotation } = this.interactPosition;
      return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
    }

    return null;
  }

  get cardPositionStyle(): string | { position: string; padding: string; top: string; 'z-index': number } {
    if (this.isMobile && this.$props.isProductSelection) {
      const priority = this.$parent.$data.orderedProducts.length - this.$props.index;
      const animationCorrection = this.$parent.$data.isAnyCardAnimating ? 1 : 0;
      return {
        position: 'absolute',
        padding: this.isCurrentAnimating
          ? `0 ${(this.$parent.$data.orderedProducts.length - animationCorrection) * 10}px`
          : `0 ${(this.$props.index - animationCorrection) * 10}px`,
        top: this.isCurrentAnimating ? '-40px' : `${(priority - 1) * 40}px`,
        'z-index': this.isCurrentAnimating ? 1 : priority + 1
      };
    }

    return '';
  }

  get productSvg(): string {
    if (!this.$props.product) {
      return '';
    }

    switch (this.$props.product.productCode) {
      case 'EcoFlex_Website':
        return 'wishproduct';
      case 'Business_Eco24_Website':
      case 'TESTPRODUKTSTROM': // Gamma-Test Product
        return 'shortruntime';
      case 'Eco12_Website':
      case 'Business_Eco12_Website':
        return 'wishproduct';
      // case 'EcoFlex_Website':
      case 'Business_EcoFlex_Website':
        return '';
      default:
        return '';
    }
  }

  get productClass(): string {
    if (!this.$props.product) {
      return '';
    }

    switch (this.$props.product.productCode) {
      case 'Eco24_Website':
      case 'Business_Eco24_Website':
      case 'Eco12Gas_Website':
      case 'Eco12Gas':
      case 'TESTPRODUKTSTROM': // Gamma-Test Product
        return 'yellow-product';
      case 'EcoFlex_Website':
      case 'Business_EcoFlex_Website':
      case 'EcoFlexGas_Website':
        return 'blue-product';
      case 'Eco12_Website':
      case 'Business_Eco12_Website':
      case 'Eco24Gas_Website':
      case 'Eco24Gas':
        return 'purple-product';
      default:
        return '';
    }
  }

  get productHeadText(): string {
    if (!this.$props.product) {
      return '';
    }

    switch (this.$props.product.productCode) {
      case 'EcoFlex_Website':
        return 'Wunschprodukt für ALLE!';
      default:
        return 'Heißester Tarif';
    }
  }

  get strommixTitle(): string {
    if (this.stromixItems.length > 1) {
      return this.$t('stromix.title.mix').toString();
    }
    if (this.stromixItems.length === 1) {
      switch (this.stromixItems[0].label) {
        case this.$t('solar').toString():
          return this.$t('stromix.title.solar').toString();
        case this.$t('water').toString():
          return this.$t('stromix.title.water').toString();
        case this.$t('wind').toString():
          return this.$t('stromix.title.wind').toString();
      }
    }

    return '';
  }

  get stromixItems(): { image: string; label: string }[] {
    let items: { image: string; label: string }[] = [];
    this.$props.product.additionalDetails.forEach((item) => {
      switch (item.technicalKey) {
        case 'SOLAR':
          if (item.value === '1') {
            items.push({
              image: 'Sonnenenergie_icon.svg',
              label: this.$t('solar').toString()
            });
          }
          break;
        case 'WATER':
          if (item.value === '1') {
            items.push({
              image: 'Wasserenergie_icon.svg',
              label: this.$t('water').toString()
            });
          }
          break;
        case 'WIND':
          if (item.value === '1') {
            items.push({
              image: 'Windenergie_icon.svg',
              label: this.$t('wind').toString()
            });
          }
          break;
      }
    });
    return items;
  }

  get transformProductDescriptionExternal(): string[] | string {
    if (!this.$props.product) {
      return '';
    }
    return this.$props.product.descriptionExternal.replace('<p>', '').replace('</p>', '').split('<br />');
  }

  get isIssetDefaultAddOn(): boolean {
    if (
      typeof this.$props.defaultAddons !== 'undefined' &&
      this.$props.defaultAddons.length > 0 &&
      typeof this.$props.product.addOns !== 'undefined' &&
      this.$props.product.addOns.length > 0
    ) {
      const findAddon = this.$props.product.addOns.filter((addon) => {
        return this.$props.defaultAddons.indexOf(addon.productCode) >= 0;
      });
      if (findAddon.length > 0) {
        this.defaultAddon = findAddon[0];
        return true;
      }
    }
    return false;
  }

  @Watch('displayDetails')
  public onDisplayDetailsChange(newVal: boolean): void {
    this.$nextTick(() => {
      this.onResize();
    });
    if (newVal) {
      trackingProvider.productDetailsShow(this.sessionState, this.$props.product);
    } else {
      trackingProvider.productDetailsHide();
    }
  }

  @Watch('selectProduct')
  public onSelectProduct(): void {
    const data = {
      productCode: this.$props.product.productCode
    };
    ApiProvider.sessionUpdate(data)
      .then(() => {
        this.store.dispatch('setSelectedProduct', data);
        this.$parent.$emit('productSubmit', data);
        trackingProvider.productSelected(this.sessionState, this.$props.product);
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  public mounted(): void {
    if (this.$props.isProductSelection) {
      this.$nextTick(() => {
        this.onResize();
        window.addEventListener('resize', this.onResize);
      });

      const element = this.$refs.interactElement;

      if (typeof element !== 'undefined') {
        interact(element).draggable({
          axis: 'x',

          onstart: () => {
            if (this.isMobile && this.$props.index === 0) {
              this.isInteractAnimating = false;
            }
          },

          onmove: (event) => {
            if (this.isMobile && this.$props.index === 0) {
              const { interactMaxRotation, interactXThreshold } = this.static;
              const x = this.interactPosition.x + event.dx;
              // const y = this.interactPosition.y + event.dy;
              const y = 0;

              let rotation = interactMaxRotation * (x / interactXThreshold);

              if (rotation > interactMaxRotation) {
                rotation = interactMaxRotation;
              } else if (rotation < -interactMaxRotation) {
                rotation = -interactMaxRotation;
              }

              this.interactSetPosition({ x, y, rotation });
            }
          },

          onend: () => {
            if (this.isMobile && this.$props.index === 0) {
              if (this.$props.index === 0) {
                const { x, y } = this.interactPosition;
                const { interactXThreshold, interactYThreshold } = this.static;
                this.isInteractAnimating = true;

                if (x > interactXThreshold) {
                  this.playCard(ACCEPT_CARD);
                } else if (x < -interactXThreshold) {
                  this.playCard(REJECT_CARD);
                } else if (y > interactYThreshold) {
                  this.playCard(SKIP_CARD);
                } else if (y < -interactYThreshold) {
                  this.playCard(SKIP2_CARD);
                } else {
                  this.resetCardPosition();
                }
              } else {
                this.isInteractAnimating = true;
                this.resetCardPosition();
              }
            }
          }
        });
      }
    }
  }

  public beforeDestroy(): void {
    if (this.$props.isProductSelection) {
      if (typeof this.$refs.interactElement !== 'undefined') {
        interact(this.$refs.interactElement).unset();
      }
    }
  }

  public getAddonImageUrl(value: string): string | null {
    if (value) {
      const regEx = /<p>(https?:\/\/[^ ]*)<\/p>/;
      const match = value.match(regEx);
      return match ? match[1] : '';
    }

    return '';
  }

  private onResize() {
    this.isMobile = window.innerWidth <= 991;
    if (typeof this.$refs.interactElement !== 'undefined') {
      this.$emit('heightChanged', {
        index: this.$props.index,
        height: this.$refs.interactElement.clientHeight,
        save: this.isMobile
      });
    }
  }

  private playCard(interaction) {
    this.displayDetails = false;
    this.$nextTick(() => {
      this.onResize();
    });

    const { interactOutOfSightXCoordinate, interactOutOfSightYCoordinate, interactMaxRotation } = this.static;

    this.isInteractDragged = true;
    this.$parent.$data.isAnyCardAnimating = true;

    switch (interaction) {
      case ACCEPT_CARD:
        this.interactSetPosition({
          x: interactOutOfSightXCoordinate,
          rotation: interactMaxRotation
        });
        break;
      case REJECT_CARD:
        this.interactSetPosition({
          x: -interactOutOfSightXCoordinate,
          rotation: -interactMaxRotation
        });
        break;
      case SKIP_CARD:
        this.interactSetPosition({
          y: interactOutOfSightYCoordinate
        });
        break;
      case SKIP2_CARD:
        this.interactSetPosition({
          y: -interactOutOfSightYCoordinate
        });
        break;
    }

    // Timeouts to finish css animation
    const that = this;
    setTimeout(() => {
      that.resetCardPosition();
      that.isCurrentAnimating = true;

      setTimeout(() => {
        that.$emit('sendToBottom', that.$props.product);
        that.isCurrentAnimating = false;
        that.$parent.$data.isAnyCardAnimating = false;
      }, 800);
    }, 300);
  }

  private interactSetPosition(coordinates) {
    const { x = 0, y = 0, rotation = 0 } = coordinates;
    this.interactPosition = { x, y, rotation };
  }

  private resetCardPosition() {
    this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
  }

  private displayDescriptionDetails() {
    this.displayDetails = !this.displayDetails;
    this.descriptionArrowClass = this.descriptionArrowClass === 'down' ? 'up' : 'down';
  }

  private submitSelectProduct() {
    this.selectProduct = true;
  }
}
