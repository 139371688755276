

































































import '@webcomponents/webcomponentsjs/webcomponents-bundle';
import CommodityPrototype from '@/main/CommodityPrototype.vue';
import { default as Component } from 'vue-class-component';

// Widgets
import PregoStepperWidget from '@/components/widgets/prego/PregoStepperWidget.vue';
import PregoFooterWidget from '@/components/widgets/prego/PregoFooterWidget.vue';
import PregoProductWidget from '@/components/widgets/prego/PregoProductWidget.vue';
import PregoContractWidget from '@/components/widgets/prego/PregoContractWidget.vue';
import PregoDeliveryAndPaymentWidget from '@/components/widgets/prego/PregoDeliveryAndPaymentWidget.vue';
import PregoReviewAndOrderWidget from '@/components/widgets/prego/PregoReviewAndOrderWidget.vue';
import PregoCongratulationsWidget from '@/components/widgets/prego/PregoCongratulationsWidget.vue';
import AlertWidget from '@/components/widgets/AlertWidget.vue';
import ModalWidget from '@/components/widgets/ModalWidget.vue';
import NotificationWidget from '@/components/widgets/NotificationWidget.vue';
import PregoAddonWidget from '@/components/widgets/prego/PregoAddonWidget.vue';

// @WithRender
@Component({
  components: {
    // External packages

    // Widgets
    'prego-stepper-widget': PregoStepperWidget,
    'prego-footer-widget': PregoFooterWidget,
    'prego-product-widget': PregoProductWidget,
    'prego-contract-widget': PregoContractWidget,
    'prego-delivery-and-payment-widget': PregoDeliveryAndPaymentWidget,
    'prego-review-and-order-widget': PregoReviewAndOrderWidget,
    'prego-congratulations-widget': PregoCongratulationsWidget,
    'alert-widget': AlertWidget,
    'modal-widget': ModalWidget,
    'notification-widget': NotificationWidget,
    'prego-addon-widget': PregoAddonWidget
  }
})
export default class WdSalesJourney extends CommodityPrototype {}
