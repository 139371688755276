
































































































































































































































































































































































































































































import ContractWidget from '@/components/widgets/ContractWidget.vue';
import DeliveryWidget from '@/components/widgets/DeliveryWidget.vue';
import PaymentWidget from '@/components/widgets/PaymentWidget.vue';
import { default as Component, mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Info from '@/components/icons/Info.vue';

interface DeliveryAndPaymentWidget extends ContractWidget, DeliveryWidget, PaymentWidget {}

@Component({
  components: {
    Info
  }
})
export default class PregoDeliveryAndPaymentWidget extends mixins<DeliveryAndPaymentWidget>(
  ContractWidget,
  DeliveryWidget,
  PaymentWidget
) {
  public isTerminatedCheckbox = false;
  public directDebitTooltipShown = false;
  public previousProviderBlacklistNotificationShown = false;

  get disabledDatesForDelivery(): { to: Date; from: Date } {
    const to = new Date();
    to.setDate(to.getDate() + 21);

    const from = new Date();
    from.setMonth(from.getMonth() + 6);

    return { to, from };
  }

  get disabledDatesForDeliveryMove(): { to: Date; from: Date } {
    const to = new Date();
    to.setDate(to.getDate() - 42);

    const from = new Date();
    from.setMonth(from.getMonth() + 6);

    return { to, from };
  }

  get deliveryDateLabel(): string {
    return this.customerSpecifications.filter((row) => {
      return row.id === this.deliveryData.customerSpecification.id;
    })[0].label;
  }

  get changeProvider(): boolean {
    return ['desired_date', 'terminated_in_person'].indexOf(this.deliveryData.customerSpecification.id) > -1;
  }

  set changeProvider(value: boolean) {
    this.changeCustomerSpecification(this.isTerminatedCheckbox);
  }

  @Watch('isTerminatedCheckbox')
  public onIsTerminatedCheckboxChange(newVal: boolean): void {
    this.changeCustomerSpecification(newVal);
  }

  @Watch('deliveryData.previousProvider.codeNumber')
  public onPreviousProviderChange(): void {
    /*
    if (this.currentClient === 'prego') {
      this.previousProviderBlacklistNotificationShown = String(newVal).localeCompare('9904386000000') === 0 || String(newVal).localeCompare('9800022800003') === 0;
    }
    */
  }

  public changeCustomerSpecification(val: boolean): void {
    if (val) {
      this.deliveryData.customerSpecification.id = 'terminated_in_person';
    } else {
      this.deliveryData.customerSpecification.id = 'desired_date';
    }
  }

  public deliveryAndPaymentSubmit(): void | boolean {
    if (!this.deliveryData.counterNumber) {
      this.setCounterNumberError();
      return false;
    }

    if (!this.previousProviderBlacklistNotificationShown) {
      if (this.data.billingAlternativeAddress) {
        const contractSubmitted = this.contractSubmit();
        if (contractSubmitted instanceof Promise) {
          contractSubmitted.then(() => {
            if (!this.errorsBag || this.errorsBag.length === 0) {
              const deliverySubmitted = this.deliverySubmit();
              if (deliverySubmitted instanceof Promise) {
                deliverySubmitted.then(() => {
                  if (!this.errorsBag || this.errorsBag.length === 0) {
                    this.paymentSubmit();
                  }
                });
              }
            }
          });
        }
      } else {
        const deliverySubmitted = this.deliverySubmit();
        if (deliverySubmitted instanceof Promise) {
          deliverySubmitted.then(() => {
            if (!this.errorsBag || Object.keys(this.errorsBag).length === 0) {
              this.paymentSubmit();
            }
          });
        }
      }
    }
  }

  protected setCounterNumberError(): void {
    this.setError(
      'counterNumber',
      'delivery.widget.delivery.counter.number.required',
      this.$t('delivery.widget.delivery.counter.number.required').toLocaleString()
    );
  }

  // private setErrorsBag(errorKey: string, localeKey: string, message: string) {
  //   if (typeof this.errorsBag === 'undefined' || this.errorsBag === null) {
  //     this.errorsBag = {
  //       [errorKey]: [{
  //         key: localeKey,
  //         message,
  //       }],
  //     };
  //   } else {
  //     this.errorsBag[errorKey] = [{
  //       key: localeKey,
  //       message,
  //     }];
  //   }
  // }
}
