import * as Sentry from '@sentry/vue';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';

const setUp = (Vue): void => {
  setUpSentry(Vue);
  setUpElastic(Vue);
};

const setUpSentry = (Vue): void => {
  if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'local') {
    Sentry.init({
      Vue,
      dsn:
        process.env.VUE_APP_SENTRY_DSN ?? 'https://b5dbf658ee5446758d43f9d79116218e@o364294.ingest.sentry.io/5172856',
      integrations: [],
      release: 'sales-frontend-journey@' + process.env.VUE_APP_VERSION,
      environment: process.env.VUE_APP_ENVIRONMENT + '.' + process.env.VUE_APP_ENVIRONMENT_STAGE,
      replaysOnErrorSampleRate: 0.9,
      ignoreErrors: [
        /.*the name "wd-sales-journey" has already been used.*/,
        /.*Cannot read properties of undefined \(reading 'pageX'\).*/,
        /.*Cannot read property 'pageX' of undefined.*/
      ],
      denyUrls: [/.*sales-demo.workdigital.de.*/],
      beforeSend(event, hint) {
        if (hint && hint.originalException && hint.originalException instanceof Error) {
          const error = String(hint.originalException.stack);
          if (error) {
            if (error.match(/wd-sales-journey.min.js/i)) {
              return event;
            }
          }
        }
        return null;
      }
    });
  }
};

const setUpElastic = (Vue): void => {
  if (process.env.VUE_APP_ENVIRONMENT_STAGE !== 'local') {
    Vue.use(ApmVuePlugin, {
      config: {
        serviceName: 'sales-frontend-journey',
        serverUrl: process.env.VUE_APP_ELASTIC_APM_URL,
        serviceVersion: process.env.VUE_APP_VERSION,
        environment: process.env.VUE_APP_ENVIRONMENT_STAGE + '-' + process.env.VUE_APP_ENVIRONMENT,
        logLevel: 'warn',
        distributedTracingOrigins: ['https://sales-dev.workdigital.de', 'https://sales.workdigital.de']
        // agent configuration
      }
    });
  }
};

export default setUp;
