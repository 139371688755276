


















































































































































































import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import ProductInfoWidget from './ProductInfoWidget.vue';
import InternationalProductInfoWidget from './international/InternationalProductInfoWidget.vue';
import QcellsProductInfoWidget from './qcells/QcellsProductInfoWidget.vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Api from '../../interfaces/Api';
import Tracking from '@/interfaces/Tracking';

const ApiProvider = IocContainer.get<Api>(SERVICES.API);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@Component({
  components: {
    'product-info': ProductInfoWidget,
    'international-info': InternationalProductInfoWidget,
    'qcells-product-info': QcellsProductInfoWidget
  },
  props: {
    // product: {},
    hiddenIban: {}
  }
})
export default class PaymentWidget extends Basic {
  public paymentData: {
    directDebit: boolean;
    directDebitConsent: boolean;
    alternativeAccountHolder: string;
    iban: string;
    bic: string;
    bank: string;
  } = {
    directDebit: false,
    directDebitConsent: false,
    alternativeAccountHolder: '',
    iban: '',
    bic: '',
    bank: ''
  };
  public bicBankLoading = false;
  public errorMessage = '';
  public errorsBag: any = null;
  public sessionMapped = false;
  public ibanChanged = false;

  protected bicAndBankTimeout: any = null;

  public mounted(): void {
    if (JSON.stringify(this.store.getters.getSession) !== '{}') {
      this.mapSessionToPaymentData();
    }
    ApiProvider.sessionRead()
      .then((response) => {
        this.store.commit('SET_SESSION', response.data);
        this.mapSessionToPaymentData();
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  get submitDisabled(): boolean {
    return this.bicBankLoading;
  }

  get bicBankPlaceholder(): string {
    if (this.bicBankLoading) {
      return this.$t('payment.widget.bic.placeholder1').toString();
    } else {
      return this.$t('payment.widget.bic.placeholder2').toString();
    }
  }

  public paymentMethodChanged(): void {
    this.errorMessage = '';
    this.paymentData.iban = '';
  }

  public paymentSubmit(): void {
    if (this.ibanChanged) {
      return;
    }
    this.resetErrorBag();
    // this.errorsBag = null;

    if (
      this.paymentData.iban === null ||
      this.paymentData.iban === '' ||
      (this.paymentData.iban !== '' && this.paymentData.bank !== '' && this.paymentData.bic !== '')
    ) {
      if (this.paymentData.directDebit === true && this.paymentData.iban === '') {
        this.$emit('scrollToTop');
        this.errorMessage = this.$t('payment.widget.sepa.direct.debit.error1').toLocaleString();
        this.setError(
          'iban',
          'payment.widget.sepa.direct.debit.error1',
          this.$t('payment.widget.sepa.direct.debit.error1').toLocaleString()
        );
      } else if (this.paymentData.directDebit === true && this.paymentData.directDebitConsent !== true) {
        this.$emit('scrollToTop');
        this.errorMessage = this.$t('payment.widget.sepa.direct.debit.error2').toLocaleString();
        this.setError(
          'directDebitConsent',
          'payment.widget.sepa.direct.debit.error2',
          this.$t('payment.widget.sepa.direct.debit.error2').toLocaleString()
        );
      } else {
        this.errorMessage = '';
        if (this.paymentData.directDebit === false) {
          this.paymentData.alternativeAccountHolder = '';
        }
        this.store.dispatch('setPaymentData', this.paymentData);
        ApiProvider.sessionUpdate(this.paymentData)
          .then(() => {
            ApiProvider.validatePayment()
              .then(() => {
                this.store.commit('SET_SESSION', this.paymentData);
                trackingProvider.validationPaymentSuccessful(
                  this.sessionState,
                  this.productGetter,
                  this.paymentData.iban
                );
                this.$emit('paymentSubmit', this.paymentData);
              })
              .catch((error) => {
                trackingProvider.validationPaymentFailed();
                this.$emit('scrollToTop');
                this.errorsBag = error.response.data.error.messages;
              });
          })
          .catch((error) => {
            if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
              localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
              window.location.href = document.referrer || window.location.origin;
            }
          });
      }
    } else {
      this.$emit('scrollToTop');
      this.setError(
        'iban',
        'payment.widget.sepa.direct.debit.error3',
        this.$t('payment.widget.sepa.direct.debit.error3').toLocaleString()
      );
      this.errorMessage = this.$t('payment.widget.sepa.direct.debit.error3').toLocaleString();
    }
  }

  protected onValidIban(): void {
    return;
  }

  protected onInvalidIban(): void {
    return;
  }

  public updateBicAndBank(): void {
    if (this.paymentData.iban.length >= 15) {
      this.bicBankLoading = true;
      this.bicAndBankMapped = false;
      this.errorMessage = '';
      this.errorsBag = null;
      clearTimeout(this.bicAndBankTimeout);
      this.bicAndBankTimeout = setTimeout(() => {
        ApiProvider.getBicAndBank(this.paymentData.iban)
          .then((response) => {
            if (response.data.success) {
              this.paymentData.bic = response.data.bic;
              this.paymentData.bank = response.data.bank;
              this.bicBankLoading = false;
              this.bicAndBankMapped = true;
              this.onValidIban();
              this.$emit('onValidIban');
            } else {
              this.bicBankLoading = false;
              this.setError(
                'iban',
                'payment.widget.sepa.direct.debit.error4',
                this.$t('payment.widget.sepa.direct.debit.error4').toLocaleString()
              );
              this.onInvalidIban();
              this.$emit('onInvalidIban');
              // this.errorMessage = this.$t('payment.widget.sepa.direct.debit.error4').toLocaleString();
            }
            this.ibanChanged = false;
          })
          .catch((error) => {
            this.ibanChanged = false;
            this.bicBankLoading = false;
            this.setError(
              'iban',
              'payment.widget.sepa.direct.debit.error4',
              this.$t('payment.widget.sepa.direct.debit.error4').toLocaleString()
            );
            this.onInvalidIban();
            this.$emit('onInvalidIban');
            // this.errorMessage = this.$t('payment.widget.sepa.direct.debit.error4').toLocaleString();
            console.error(error);
          });
      }, 2000);
    } else {
      this.paymentData.bic = '';
      this.paymentData.bank = '';
      this.bicBankLoading = false;
    }
  }

  get errorAlternativeAccountHolder(): string | null {
    return this.getError('alternativeAccountHolder');
  }
  get errorIban(): string | null {
    return this.getError('iban');
  }
  get errorBic(): string | null {
    return this.getError('bic');
  }
  get errorBank(): string | null {
    return this.getError('bank');
  }
  get errorDirectDebit(): string | null {
    return this.getError('directDebit');
  }

  @Watch('paymentData.iban')
  protected onIBANChange(newVal: string): void {
    if (newVal) {
      this.ibanChanged = true;
      const regexPattern = this.$props.hiddenIban ? /[^a-zA-Z0-9]/gi : /[^a-zA-Z0-9*]/gi;
      this.paymentData.iban = newVal.replace(regexPattern, '');
      this.paymentData.bank = '';
      this.paymentData.bic = '';
    }
  }

  private mapSessionToPaymentData() {
    this.sessionMapped = false;
    if (this.sessionState.alternativeAccountHolder) {
      this.paymentData.alternativeAccountHolder = this.sessionState.alternativeAccountHolder ?? '';
    } else {
      const sessionStateName: string = [this.sessionState.firstName ?? '', this.sessionState.surname ?? ''].join(' ');
      this.paymentData.alternativeAccountHolder = sessionStateName.length > 1 ? sessionStateName : '';
    }
    if (typeof this.sessionState.directDebit === 'undefined') {
      this.paymentData.directDebit = true;
    } else {
      this.paymentData.directDebit = this.sessionState.directDebit === 1;
    }
    this.paymentData.iban = this.sessionState.iban ? this.sessionState.iban : '';
    this.updateBicAndBank();
    this.sessionMapped = true;
  }

  // private setBaseErrorsBag(errorKey: string, localeKey: string, message: string) {
  //   if (typeof this.errorsBag === 'undefined' || this.errorsBag === null) {
  //     this.errorsBag = {
  //       [errorKey]: [{
  //         key: localeKey,
  //         message,
  //       }],
  //     };
  //   } else {
  //     this.errorsBag[errorKey] = [{
  //       key: localeKey,
  //       message,
  //     }];
  //   }
  // }
}
