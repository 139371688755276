


























































































































































import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';

import IocContainer from '@/container/IocContainer';
import Format from '@/interfaces/Format';
import SERVICES from '@/container/Services';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@Component({
  props: {
    product: {},
    sessionState: {},
    stepperState: Object
  },
  filters: {
    euro: formatProvider.euro(),
    euroFull: formatProvider.euroFull(),
    zip: formatProvider.zip()
  }
})
export default class StepperWidget extends Basic {
  public showMobileList = false;

  public goToWidget(widgetName: string): void {
    this.showMobileList = false;
    this.$emit('goToWidget', widgetName);
  }

  get sessionStateUsageNt(): number | null {
    return this.sessionState.usageNt ?? null;
  }

  get heatingUsage(): number {
    if (this.sessionStateUsageNt !== null && this.sessionStateUsageNt > 0) {
      return (this.sessionStateUsageNt ?? 0) + (this.sessionState.usage ?? 0);
    } else {
      return this.sessionState.usage ?? 0;
    }
  }
}
