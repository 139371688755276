


















import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class ModalWidget extends Basic {
  public closeModal(): void {
    this.store.dispatch('setDisplayModal', false);
  }
  get getSuccessModal(): boolean {
    return this.store.getters.getSuccessModal;
  }
  get getModalMessages(): string {
    return this.store.getters.getModalMessages;
  }
  get getDisplayModal(): boolean {
    return this.store.getters.getDisplayModal;
  }

  @Watch('store.state.displayModal')
  protected setTimer(): void {
    const shouldDisappear = this.store.getters.getSuccessModal;
    const isOpen = this.store.getters.getDisplayModal;
    if (shouldDisappear && isOpen) {
      setTimeout(() => {
        this.store.dispatch('setDisplayModal', false);
      }, 3000);
    }
  }
}
