import { injectable } from 'inversify';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Api from '../interfaces/Api';

@injectable()
export default class ApiProvider implements Api {
  public http: AxiosInstance;

  public sessionToken = '';

  constructor() {
    this.http = axios.create({
      baseURL: process.env.VUE_APP_API_URL + '/v1/',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: process.env.VUE_APP_API_AUTH,
        'WD-OVERWRITE-POWERCLOUD-APIS': process.env.VUE_APP_OVERWRITE_POWERCLOUD_APIS === 'true'
      }
    });
  }

  public async uploadFileViaS3(data: any): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/upload/file', {
      sessionHash: this.sessionToken,
      ...data
    });
  }

  public async checkFileExists(data: any): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/file/check', {
      sessionHash: this.sessionToken,
      ...data
    });
  }

  public async getGenericData(key: string): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    const data: any = {
      sessionHash: this.sessionToken,
      key: key
    };
    return await this.http.post('/session/generic-storage', data);
  }

  public async updateGenericData(data: FormData): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    data.append('sessionHash', this.sessionToken);
    return await this.http.post('/session/update/generic-storage', data);
  }

  public async createSession(data): Promise<AxiosResponse<any>> {
    return await this.http.post('/session/create', {
      commodity: 'non_commodity',
      ...data
    });
  }

  public async sessionRead(injectedSession = ''): Promise<AxiosResponse<any>> {
    if (injectedSession) {
      return await this.http.post('/session', { sessionHash: injectedSession });
    }
    await this.setSessionToken();
    return await this.http.post('/session', { sessionHash: this.sessionToken });
  }

  //eslint-disable-next-line
  public async sessionDuplicate(data): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    data.sessionHash = this.sessionToken;
    return await this.http.post('/session/duplicate', data);
  }

  public async sessionUpdate(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: any,
    street: string | null,
    houseNumber: string | null
  ): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    data.sessionHash = this.sessionToken;

    // Before sending data to the server, extendedData is transformed into JSON, in case an object, instead of string is stored
    if (typeof data.extendedData === 'object') {
      data.extendedData = JSON.stringify(data.extendedData);
    }
    if (street) {
      data.street = street;
    }
    if (houseNumber) {
      data.houseNumber = houseNumber;
    }
    return await this.http.post('/session/update', data);
  }

  public async getProducts(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return this.http.post('/products', {
      sessionHash: this.sessionToken
    });
  }

  public async getProductDetails(productCode: string): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/products/details', {
      sessionHash: this.sessionToken,
      productCode
    });
  }

  public async getFlexBonus(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/flex-bonus', {
      sessionHash: this.sessionToken
    });
  }

  public async getSessionProductCode(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/session/product-code', { sessionHash: this.sessionToken });
  }

  public async getAddons(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/addons', { sessionHash: this.sessionToken });
  }

  public async getProviders(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/providers', {
      sessionHash: this.sessionToken
    });
  }

  public async getCities(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.get('/cities');
  }

  public async getStreets(customZip = null): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    const data = customZip
      ? {
          sessionHash: this.sessionToken,
          zipCode: customZip
        }
      : {
          sessionHash: this.sessionToken
        };
    return await this.http.post('/streets', data);
  }

  public async getBicAndBank(iban: string): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/payment/bicAndBank', {
      iban,
      sessionHash: this.sessionToken
    });
  }

  public async isUsernameAvailable(username: string): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/username/available', {
      sessionHash: this.sessionToken,
      username
    });
  }

  public async login(username: string, password: string): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/login', {
      sessionHash: this.sessionToken,
      username,
      password
    });
  }

  public async getSettings(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    return await this.http.post('/settings', {
      sessionHash: this.sessionToken
    });
  }

  public async validateContract(): Promise<AxiosResponse<any>> {
    return await this.http.post('/order/validate/contract', {
      sessionHash: this.sessionToken
    });
  }

  public async validateDelivery(): Promise<AxiosResponse<any>> {
    return await this.http.post('/order/validate/delivery', {
      sessionHash: this.sessionToken
    });
  }

  public async validatePayment(): Promise<AxiosResponse<any>> {
    return await this.http.post('/order/validate/payment', {
      sessionHash: this.sessionToken
    });
  }

  public async validateRules(): Promise<AxiosResponse<any>> {
    return await this.http.post('/order/validate/terms', {
      sessionHash: this.sessionToken
    });
  }

  public async validate(): Promise<AxiosResponse<any>> {
    return await this.http.post('/order/validate', {
      sessionHash: this.sessionToken
    });
  }

  public async validateDiscount(discount: string): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    const data = discount
      ? {
          sessionHash: this.sessionToken,
          discount
        }
      : {
          sessionHash: this.sessionToken
        };
    return await this.http.post('/discount/validate', data);
  }

  public async invalidateDiscount(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    const data = {
      sessionHash: this.sessionToken
    };
    return await this.http.post('/discount/invalidate', data);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public mergeExtendedData(expected, loaded): Promise<AxiosResponse<any>> {
    const combined = expected;

    for (const category in expected) {
      // eslint-disable-next-line no-prototype-builtins
      if (loaded.hasOwnProperty(category)) {
        for (const field in expected[category]) {
          // eslint-disable-next-line no-prototype-builtins
          if (loaded[category].hasOwnProperty(field)) {
            combined[category][field] = loaded[category][field];
          }
        }
      }
    }
    return combined;
  }

  public async create(): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    const sessionHash = this.sessionToken;
    return await this.http.post('/order/create', {
      sessionHash
    });
  }

  public async activateSessionReminder(sessionURL: string, email: string): Promise<AxiosResponse<any>> {
    this.setSessionToken();
    const sessionHash = this.sessionToken;
    return await this.http.post('/session/notify', {
      sessionHash,
      sessionURL,
      email
    });
  }

  private setSessionToken(): void {
    const newSessionToken = localStorage.getItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
    if (newSessionToken) {
      this.sessionToken = newSessionToken;
    }
  }
}
