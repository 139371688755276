















import vue from 'vue';
import { default as Component } from 'vue-class-component';

@Component({})
export default class PregoCongratulationsWidget extends vue {}
