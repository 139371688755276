import { render, staticRenderFns } from "./RulesWidget.vue?vue&type=template&id=09a9b0c2&"
import script from "./RulesWidget.vue?vue&type=script&lang=ts&"
export * from "./RulesWidget.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  ,true
)

export default component.exports