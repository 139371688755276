
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import BaseMixin from '@/mixins/base';
import { store } from '@/store';
import SessionState from '@/interfaces/SessionState';
import ProductData from '@/interfaces/ProductData';
import VueI18n from 'vue-i18n';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class Basic extends mixins(Vue, BaseMixin) {
  public store = store;

  public sessionMapped = false;
  public bicAndBankMapped = false;
  public isProduction = process.env.VUE_APP_ENVIRONMENT_STAGE === 'prod';

  public errorsBag: any = {};
  public errorObject = {};

  protected fieldIdentifiers: string[] = [];
  protected fieldIdentifiersMandatory: string[] = [];

  get tAndCLink(): VueI18n.TranslateResult {
    return this.$tdc('terms.and.conditions');
  }

  get privacyLink(): VueI18n.TranslateResult {
    return this.$tdc('privacy.link');
  }

  get revocationLink(): VueI18n.TranslateResult {
    return this.$tdc('revocation.link');
  }

  get basicSupplyContractLink(): VueI18n.TranslateResult {
    return this.$tdc('basic.supply.contract.link');
  }

  get attorneyLink(): VueI18n.TranslateResult {
    return this.$tdc('attorney.link');
  }

  get homepageLink(): VueI18n.TranslateResult {
    return this.isProduction ? this.$tdc('home.link') : this.$tdc('home.link.dev');
  }

  mounted(): void {
    this.initFieldDisplay();
  }

  protected getExtendedFieldStatusClass(
    classes: string[],
    fieldId: string,
    fieldValue: string,
    ignoreInvalidAndEmpty: boolean = false,
    isRecursive: boolean = false
  ): string[] {
    return classes;
  }

  protected getFieldClassList(): string[] {
    return ['field-empty', 'field-not-empty', 'field-mandatory-empty', 'field-input-invalid'];
  }

  @Watch('sessionState')
  protected initFieldDisplay(): void {
    for (let i = 0; i < this.fieldIdentifiers.length; ++i) {
      if (!this.fieldIdentifiers[i] || this.fieldIdentifiers[i].length == 0) {
        continue;
      }
      const fieldElement: HTMLElement | null = this.$el.querySelector('#' + this.fieldIdentifiers[i]);
      const that = this;
      if (fieldElement) {
        // @ts-ignore
        that.updateFieldDisplayForElement(fieldElement, true);
      }
    }
  }

  protected getFieldStatusClass(
    fieldId: string,
    fieldValue: string,
    ignoreInvalidAndEmpty: boolean = false,
    isRecursive: boolean = false
  ): string {
    let classes: string[] = [];
    if (!fieldValue || fieldValue.length == 0) {
      if (!ignoreInvalidAndEmpty) {
        classes.push('field-empty');
      }
    } else {
      classes.push('field-not-empty');
    }
    if (this.fieldIdentifiersMandatory.indexOf(fieldId) >= 0) {
      if (!fieldValue || fieldValue.length == 0) {
        if (!ignoreInvalidAndEmpty) {
          classes.push('field-mandatory-empty');
        }
      }
    }
    classes = this.getExtendedFieldStatusClass(classes, fieldId, fieldValue, ignoreInvalidAndEmpty, isRecursive);
    return classes.join(' ');
  }

  public handleFieldDisplay(e: UIEvent): void {
    // @ts-ignore
    this.updateFieldDisplay(e && e.target ? e.target.id : '', e && e.target ? e.target.value : '');
  }

  protected updateFieldDisplayForElement(
    fieldElement: HTMLElement | null,
    ignoreInvalidAndEmpty: boolean = false,
    isDelayed: boolean = false,
    isRecursive: boolean = false
  ): void {
    if (fieldElement) {
      // @ts-ignore
      this.updateFieldDisplay(fieldElement.id, fieldElement.value, ignoreInvalidAndEmpty, isDelayed, isRecursive);
    }
  }

  protected updateFieldDisplay(
    fieldId: string,
    fieldValue: string,
    ignoreInvalidAndEmpty: boolean = false,
    isDelayed: boolean = false,
    isRecursive: boolean = false
  ): void {
    let fieldElement: HTMLElement | null = this.$el.querySelector('#' + fieldId);
    const fieldlabel: HTMLElement | null = this.$el.querySelector('#' + fieldId + '-label');
    if (fieldElement) {
      const classList: string[] = this.getFieldClassList();
      if (fieldElement.className.indexOf('v-select') >= 0) {
        if (!isDelayed) {
          const that = this;
          setTimeout(function () {
            that.updateFieldDisplay(fieldId, fieldValue, ignoreInvalidAndEmpty, true);
          }, 1);
          return;
        }
        fieldElement = this.$el.querySelector('#' + fieldId + ' input');
        const selectedContainer: HTMLElement | null = this.$el.querySelector('#' + fieldId + ' .vs__selected');
        fieldValue = selectedContainer ? selectedContainer.innerHTML.replace('<!---->', '').trim() : '';
      }
      if (fieldElement) {
        for (let i = 0; i < classList.length; ++i) {
          fieldElement.className = fieldElement.className.replace(' ' + classList[i], '');
        }
        fieldElement.className +=
          ' ' + this.getFieldStatusClass(fieldId, fieldValue, ignoreInvalidAndEmpty, isRecursive);
      }
    }
    if (fieldlabel) {
      if (fieldValue && fieldValue.length > 0) {
        if (fieldlabel.className.indexOf(' label-shown') < 0) {
          fieldlabel.className += ' label-shown';
        }
      } else {
        fieldlabel.className = fieldlabel.className.replace(' label-shown', '');
      }
    }
  }

  errorHandler(error: Record<any, any> | null, status = 0): void {
    if (error) {
      if (typeof error.response !== 'undefined') {
        const messageOutdated: string = 'session outdated';
        if (
          (error.response.data.message && error.response.data.message == messageOutdated) ||
          (error.response.data.error && error.response.data.error.message == messageOutdated)
        ) {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      }
      if (status === 0 || status === error.response.status) {
        this.dispatchErrors(error);
      }
    }
  }

  dispatchErrors(error: Record<any, any> | null): void {
    this.store.dispatch('setErrors', error ? error.response.data.errors : null);
  }

  public setEmail(event): void {
    if (event.path) {
      if (event.path[0]) {
        this.store.dispatch('setEmail', event.path[0].value);
      }
    }
  }

  public setInputValue(name: string, value: string | number | null): void {
    this.store.dispatch('setFieldValue', { field: name, value });
    this.onInputValueSet(name, value);
  }

  protected onInputValueSet(name: string, value: string | number | null): void {
    return;
  }

  public get sessionState(): SessionState {
    return this.store.getters.getSession;
  }

  get productGetter(): ProductData {
    return this.store.getters.getSelectedProduct;
  }

  public goToWidget(widgetName: string): void {
    this.$emit('goToWidget', widgetName);
  }

  get isInternational(): boolean {
    const settings = this.store.getters.getSettings;
    const countryValue = settings['country'] ?? null;
    return countryValue !== null && countryValue !== 'DE';
  }

  public getError(fieldName: string, fullObject = false): string | null {
    if (!this.errorsBag || !this.errorsBag[fieldName]) {
      return null;
    }

    const errorsArray = this.errorsBag[fieldName];

    if (fullObject) {
      return typeof errorsArray === 'object' ? errorsArray : null;
    } else {
      const result: string[] = [];
      errorsArray.forEach((errorObject) => {
        if (typeof errorObject === 'object') {
          if (this.$te(errorObject.key)) {
            result.push(this.$t(errorObject.key).toLocaleString());
          } else {
            result.push(errorObject.message);
          }
        } else if (typeof errorObject === 'string') {
          result.push(errorObject);
        }
      });
      return result.length > 0 ? result.join(' ') : null;
    }
  }

  public setError(errorKey: string, localeKey: string | boolean, message: string): void {
    if (typeof this.errorsBag === 'undefined' || this.errorsBag === null) {
      this.errorsBag = {
        [errorKey]: [
          {
            key: localeKey,
            message
          }
        ]
      };
    } else {
      const newError = {};
      newError[errorKey] = [
        {
          key: localeKey,
          message
        }
      ];
      this.errorsBag = Object.assign({}, this.errorsBag, newError);
    }
  }

  //eslint-disable-next-line
  get alertSettings() {
    return this.store.state.settings.alertSettings;
  }

  public resetErrorBag(): void {
    this.errorsBag = {};
  }
}
