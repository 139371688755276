<template>
  <div :class="{ 'input-group': bootstrapStyling }">
    <!-- Calendar Button -->
    <span
      v-if="calendarButton"
      class="vdp-datepicker__calendar-button"
      :class="{ 'input-group-prepend': bootstrapStyling }"
      @click="showCalendar"
      v-bind:style="{ 'cursor:not-allowed;': disabled }"
    >
      <span :class="{ 'input-group-text': bootstrapStyling }">
        <i :class="calendarButtonIcon">
          {{ calendarButtonIconContent }}
          <span v-if="!calendarButtonIcon">&hellip;</span>
        </i>
      </span>
    </span>
    <!-- Input -->
    <input
      :data-test="id"
      class="form-control"
      :type="inline ? 'hidden' : 'text'"
      :class="computedInputClass"
      :name="name"
      :ref="refName"
      :id="id"
      :value="formattedValue"
      :open-date="openDate"
      :placeholder="placeholder"
      :clear-button="clearButton"
      :disabled="disabled"
      :required="required"
      :readonly="!typeable"
      @click="showCalendar"
      @keyup="parseTypedDate"
      @blur="inputBlurred"
      autocomplete="off"
    />
    <!-- Clear Button -->
    <span
      v-if="clearButton && selectedDate"
      class="vdp-datepicker__clear-button"
      :class="{ 'input-group-append': bootstrapStyling }"
      @click="clearDate()"
    >
      <span :class="{ 'input-group-text': bootstrapStyling }">
        <i :class="clearButtonIcon">
          <span v-if="!clearButtonIcon">&times;</span>
        </i>
      </span>
    </span>
    <slot name="afterDateInput"></slot>
  </div>
</template>
<script>
import { makeDateUtils } from '../utils/DateUtils';
import { parse, format, compareAsc, compareDesc, add } from 'date-fns';

export default {
  props: {
    selectedDate: Date,
    resetTypedDate: [Date],
    format: [String, Function],
    translation: Object,
    inline: Boolean,
    id: String,
    name: String,
    refName: String,
    openDate: Date,
    placeholder: String,
    inputClass: [String, Object, Array],
    clearButton: Boolean,
    clearButtonIcon: String,
    calendarButton: Boolean,
    calendarButtonIcon: String,
    calendarButtonIconContent: String,
    disabledDates: Object,
    disabled: Boolean,
    required: Boolean,
    typeable: Boolean,
    bootstrapStyling: Boolean,
    useUtc: Boolean
  },
  data() {
    const constructedDateUtils = makeDateUtils(this.useUtc);
    return {
      input: null,
      typedDate: false,
      utils: constructedDateUtils
    };
  },
  computed: {
    formattedValue() {
      if (!this.selectedDate) {
        return null;
      }
      if (this.typedDate) {
        return this.typedDate;
      }
      return typeof this.format === 'function'
        ? this.format(this.selectedDate)
        : this.utils.formatDate(new Date(this.selectedDate), this.format, this.translation);
    },

    computedInputClass() {
      if (this.bootstrapStyling) {
        if (typeof this.inputClass === 'string') {
          return [this.inputClass, 'form-control'].join(' ');
        }
        return _objectSpread(
          {
            'form-control': true
          },
          this.inputClass
        );
      }
      return this.inputClass;
    }
  },
  watch: {
    resetTypedDate() {
      this.typedDate = false;
    }
  },
  methods: {
    showCalendar() {
      this.$emit('showCalendar');
    },
    /**
     * Attempt to parse a typed date
     * @param {Event} event
     */
    parseTypedDate(event) {
      // close calendar if escape or enter are pressed
      if (
        [
          27, // escape
          13 // enter
        ].indexOf(event.keyCode) !== -1
      ) {
        this.input.blur();
      }

      if (
        [
          37, // left
          39 // right
        ].indexOf(event.keyCode) !== -1
      ) {
        return;
      }

      if (this.typeable) {
        const regexp = /^\s*(3[01]|[12][0-9]|0?[1-9])[\.\/](1[012]|0?[1-9])[\.\/](\d{4})\s*$/;
        if (this.input.value.match(regexp) !== null) {
          let typedDate = parse(this.input.value, this.format, new Date());
          if (!isNaN(typedDate)) {
            if (compareAsc(typedDate, this.disabledDates.from) === 1) {
              typedDate = this.disabledDates.from;
            } else if (compareDesc(typedDate, this.disabledDates.to) === 1) {
              typedDate = this.disabledDates.to;
            }
            this.typedDate = format(typedDate, this.format); //this.input.value;
            this.$emit('typedDate', typedDate);
          }
        }
      }
    },
    /**
     * nullify the typed date to defer to regular formatting
     * called once the input is blurred
     */
    inputBlurred() {
      let format = null;
      if (typeof this.format === 'string') {
        format = this.format;
      } else {
        format = 'L';
      }
      if (this.typeable && isNaN(parse(this.input.value, format, new Date()))) {
        this.clearDate();
        this.input.value = null;
        this.typedDate = null;
      }

      this.$emit('closeCalendar');
    },
    /**
     * emit a clearDate event
     */
    clearDate() {
      this.$emit('clearDate');
    }
  },
  mounted() {
    this.input = this.$el.querySelector('input');
  }
};
// eslint-disable-next-line
</script>
