

























import vue from 'vue';
import { default as Component } from 'vue-class-component';
import PregoProductInfoWidget from './PregoProductInfoWidget.vue';

import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Format from '@/interfaces/Format';
import ProductWidget from '../ProductWidget.vue';

const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);

@Component({
  components: {
    'prego-product-info': PregoProductInfoWidget
  },
  filters: {
    date: formatProvider.date(),
    euro: formatProvider.euro(),
    euroFull: formatProvider.euroFull(),
    zip: formatProvider.zip()
  },
  props: {
    availableProducts: {},
    loadingProducts: {},
    sessionState: {}
  }
})
export default class PregoProductWidget extends ProductWidget {}
