


















































































































import Basic from '@/components/widgets/Basic.vue';
import { default as Component } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import IocContainer from '@/container/IocContainer';
import SERVICES from '@/container/Services';

import Api from '@/interfaces/Api';
import Format from '@/interfaces/Format';
import Tracking from '@/interfaces/Tracking';
import ProductInfoWidget from '../ProductInfoWidget.vue';

const ApiProvider = IocContainer.get<Api>(SERVICES.API);
const formatProvider = IocContainer.get<Format>(SERVICES.FORMAT);
const trackingProvider = IocContainer.get<Tracking>(SERVICES.TRACKING);

@Component({
  props: {
    product: {},
    sessionState: {},
    isProductSelection: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    date: formatProvider.date(),
    euro: formatProvider.euro(),
    euroFull: formatProvider.euroFull()
  }
})
export default class PregoProductInfoWidget extends ProductInfoWidget {
  private selectProduct = false;

  get countProductsWithBonus(): number {
    return this.$parent.$props.availableProducts.filter((product) => {
      return product.bonusSavings !== 0;
    }).length;
  }

  @Watch('selectProduct')
  public onSelectProduct(): void {
    const data = {
      productCode: this.$props.product.productCode
    };
    ApiProvider.sessionUpdate(data)
      .then(() => {
        this.store.commit('SET_SESSION', data);
        this.$parent.$emit('productSubmit', data);
        trackingProvider.productSelected(this.sessionState, this.$props.product);
      })
      .catch((error) => {
        if (typeof error.response !== 'undefined' && error.response.data.error.message === 'session outdated') {
          localStorage.removeItem(process.env.VUE_APP_SESSION_STORAGE_KEY);
          window.location.href = document.referrer || window.location.origin;
        }
      });
  }

  public getLink(string: string): string {
    const regex = /(<([^>]+)>)/gi;
    return string.replace(regex, '');
  }
}
